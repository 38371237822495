import marked from "marked";
import * as React from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  content: string | null | string[];
}

export const MarkdownRenderer: React.FC<Props> = (props: Props): JSX.Element => {
  const { content, ...rest } = props;
  const typeOfContent = typeof content;
  let parsedContent: string;
  if (content === null || typeOfContent === "undefined") {
    return <div {...props}></div>;
  }

  if (Array.isArray(content)) {
    const arr: string[] = [];
    content.map((item) => {
      return arr.push(marked(item));
    });
    parsedContent = arr.join("");
  } else {
    parsedContent = marked(content);
  }
  return <div {...rest} dangerouslySetInnerHTML={{ __html: parsedContent }}></div>;
};
