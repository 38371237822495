import { ActionType } from "../interfaces/api/actionType";

export const EditApplicationClientStatus: ActionType = {
  FAILURE: "EDIT_APPLICATION_CLIENT_STATUS_FAILURE",
  REQUEST: "EDIT_APPLICATION_CLIENT_STATUS_REQUEST",
  SUCCESS: "EDIT_APPLICATION_CLIENT_STATUS_SUCCESS",
};
export const CreateApplication: ActionType = {
  FAILURE: "CREATE_APPLICATION_FAILURE",
  REQUEST: "CREATE_APPLICATION_REQUEST",
  SUCCESS: "CREATE_APPLICATION_SUCCESS",
};
export const EditOffer: ActionType = {
  FAILURE: "EDIT_OFFER_FAILURE",
  REQUEST: "EDIT_OFFER_REQUEST",
  SUCCESS: "EDIT_OFFER_SUCCESS",
};
export const PostClientRequestOfferChange: ActionType = {
  FAILURE: "POST_CLIENT_REQUEST_OFFER_CHANGE_FAILURE",
  REQUEST: "POST_CLIENT_REQUEST_OFFER_CHANGE_REQUEST",
  SUCCESS: "POST_CLIENT_REQUEST_OFFER_CHANGE_SUCCESS",
};
export const PutOfferClientRequest: ActionType = {
  FAILURE: "PUT_OFFER_CLIENT_REQUEST_FAILURE",
  REQUEST: "PUT_OFFER_CLIENT_REQUEST_REQUEST",
  SUCCESS: "PUT_OFFER_CLIENT_REQUEST_SUCCESS",
};

export const PostApplicationMessage: ActionType = {
  FAILURE: "POST_APPLICATION_MESSAGE_FAILURE",
  REQUEST: "POST_APPLICATION_MESSAGE_REQUEST",
  SUCCESS: "POST_APPLICATION_MESSAGE_SUCCESS",
};

export const PutApplicationMessage: ActionType = {
  FAILURE: "PUT_APPLICATION_MESSAGE_FAILURE",
  REQUEST: "PUT_APPLICATION_MESSAGE_REQUEST",
  SUCCESS: "PUT_APPLICATION_MESSAGE_SUCCESS",
};
