import { t } from "@lingui/macro";
import cx from "classnames";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { FC } from "react";

import { Highlight, useInstantSearch } from "react-instantsearch-hooks-web";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import rehypeRaw from "rehype-raw";
import { algoliaAttributes } from "..";
import { APPLICATION_STATUS_MARKETPLACE } from "../../../../helpers/constant";
import { getUserImage } from "../../../../helpers/images";
import { applicationStatuses } from "../../../../interfaces/resources/application";
import { StateProps } from "../../../../interfaces/state";
import { generateIriFromId } from "../../../../services/api/helpers";
import { editOfferAction, onCreateApplicationAction } from "../../../../services/api/rest/actions";
import globalStyles from "../../../../styles/global.module.scss";
import transApplication from "../../../../translations/constants/application";
import styles from "./index.module.scss";
interface Props {
  hitTalent: any;
  sendEvent?: any;
  className?: string;
  mode?: "complete" | "minimal";
  offer?: any;
}

type InfosState = false | "internal" | "linkedin";

export const AlgoliaTalentCard: FC<Props> = (props: Props) => {
  const { hitTalent, className = "", sendEvent, mode = "complete", offer } = props;
  const hitTp = hitTalent.talentProfile;
  const hitLr = hitTalent.linkedinResume;
  const { refresh } = useInstantSearch();
  const dispatch = useDispatch();
  const talentIri = generateIriFromId("talent", hitTalent.id);
  const currentUser = useSelector((state: StateProps) => state.currentUser);
  const [clicked, setClicked] = React.useState(false);
  const [showInfos, setShowInfos] = React.useState<InfosState>(false);
  const [disabledState] = React.useState<"application" | "discarded" | undefined>(undefined);

  const expRem = hitTp.lastQualification?.expectedRemuneration;
  const varExpRem = !!expRem ? expRem.fullPackage - expRem.fixedSalary : undefined;
  // const actRem = hitTp.lastQualification?.actualRemuneration;
  // const varActRem = !!actRem ? actRem.fullPackage - actRem.fixedSalary : undefined;
  const [, setExcerpt] = React.useState<string | null>(null);
  const highConclu = hitTalent._highlightResult?.talentProfile?.lastQualification?.conclusion;
  const highContext = hitTalent._highlightResult?.talentProfile?.lastQualification?.context;
  const highStorytelling = hitTalent._highlightResult?.talentProfile?.lastQualification?.storytelling;
  const highIdealJob = hitTalent._highlightResult?.talentProfile?.lastQualification?.idealJob;
  // Le tableau des highlights des experiences LinkedIn
  const highExps = hitTalent._highlightResult?.linkedinResume?.experiences;
  const highSummary = hitTalent._highlightResult?.linkedinResume?.summary;

  const [, setInternalMatch] = React.useState(0);
  const [linkedinMatch, setLinkedinMatch] = React.useState(0);

  // const editApplication = (iri: string, req: Partial<CardApplicationFragmentProps>): void => {
  //   dispatch(editApplicationAction(iri, req));
  // };
  const createApplication = (): void => {
    if (!offer || !currentUser) {
      return;
    }
    dispatch(
      onCreateApplicationAction({
        offerIri: offer.id,
        talentIri,
        clientIri: currentUser["@id"],
        status: applicationStatuses.applied,
        clientStatus: APPLICATION_STATUS_MARKETPLACE,
      }),
    );
  };

  const editOffer = (req: any): void => {
    dispatch(editOfferAction(req));
  };

  const addToDiscardedList = () => {
    if (offer) {
      editOffer({
        offerId: offer.id,
        discardedTalents: [...offer.discardedTalents, talentIri],
      });
    }
  };

  const handleHuntTalent = (): void => {
    if (!hitTalent || !offer) {
      return;
    }
    createApplication();
  };

  const onClickShowMore = (state: InfosState) => {
    setShowInfos(state);
  };

  React.useEffect(() => {
    const arr = [highConclu, highContext, highStorytelling, highIdealJob];
    let count = 0;

    arr?.forEach((exp: any) => {
      if (!!exp?.matchLevel && exp?.matchLevel !== "none") {
        count++;
      }
    });

    setInternalMatch(count);
  }, [highConclu, highContext, highStorytelling, highIdealJob]);

  React.useEffect(() => {
    let count = 0;

    highExps?.forEach((exp: any) => {
      if (exp?.description && !!exp?.description?.matchLevel && exp?.description?.matchLevel !== "none") {
        count++;
      }
      if (!!exp?.companyName?.matchLevel && exp?.companyName?.matchLevel !== "none") {
        count++;
      }
      if (!!exp?.jobTitle?.matchLevel && exp?.jobTitle?.matchLevel !== "none") {
        count++;
      }
    });
    if (!!highSummary?.matchLevel && highSummary.matchLevel !== "none") {
      count++;
    }

    setLinkedinMatch(count);
  }, [highExps, highSummary]);

  React.useEffect(() => {
    let conclu = hitTp?.lastQualification?.conclusion;
    if (!!!conclu) {
      return;
    }
    if (conclu.split(" ").length > 10) {
      conclu = `${conclu.split(" ").slice(0, 9).join(" ")}... `;
    }

    setExcerpt(conclu);
  }, [hitTp]);

  return (
    <div className={cx(styles.card, { [className]: !!className })}>
      <div className={styles.headerCard}>
        {hitTp.coreResponsibility?.value ? (
          <div className={cx(styles.headerItem, styles.bold)}>{hitTp.coreResponsibility.value}</div>
        ) : null}
        <div className={cx(styles.headerItem, styles.expRemItem)}>
          {hitTp.experience ? <div>{`Années d'experience : ${hitTp.experience} ans`}</div> : null}
          {!!expRem ? (
            <div> Salaire indicatif : {`${expRem.fixedSalary}k+${varExpRem}k (${expRem.fullPackage}k)`}</div>
          ) : null}
        </div>
        {mode === "complete" ? (
          <div className={cx(styles.headerItem, styles.lastLoginItem, styles.itemText)}>
            <span>dernière connexion : {format(new Date(hitTalent.lastLogin), "dd/MM/yyyy")}</span>
          </div>
        ) : null}
      </div>
      <div className={styles.bodyCard}>
        <div className={styles.talentShowContainer}>
          <div className={styles.talentPartContainer}>
            <div className={styles.talentPhotoContainer}>
              <div className={globalStyles.imgProfileContainer}>
                <div className={cx(globalStyles.imgContent, styles.imgContent)}>
                  <img alt={`${hitTalent.firstname} ${hitTalent.lastname}`} {...getUserImage(hitTalent.image)} />
                </div>
              </div>
            </div>
            <div className={styles.talentInfosContainer}>
              <div className={styles.nameGoldenContainer}>
                <div>
                  <h3 className={styles.name}>{`${hitTalent.firstname} ${hitTalent.lastname}`}</h3>
                </div>
              </div>
              {hitTp?.currentJob || hitTp?.currentCompany ? (
                <div className={cx(styles.text)}>
                  {hitTp?.currentJob} @ {hitTp?.currentCompany}
                </div>
              ) : null}
              {!!hitTalent.linkedInProfile ? (
                <div className={styles.infoLinkedInContainer}>
                  <a
                    className={cx(styles.linkedInLink, styles.infosSupp)}
                    href={hitTalent.linkedInProfile.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      if (typeof sendEvent === "function") {
                        sendEvent("click", hitTalent, "goToLinkedIn");
                      }
                    }}
                  >
                    {t`TalentInfo.goTolinkedInProfile`}
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.infosTabContainer}>
          {!!hitLr ? (
            <div>
              <button
                type="button"
                className={cx(styles.tabCta, globalStyles.cta, globalStyles.inverted, {
                  [styles.active]: showInfos === "linkedin",
                })}
                onClick={() => {
                  if (typeof sendEvent === "function" && !(showInfos === "linkedin")) {
                    sendEvent("click", hitTalent, "seeLinkedinInfos");
                  }
                  onClickShowMore(showInfos === "linkedin" ? false : "linkedin");
                }}
              >
                <span>Parcours</span>{" "}
                {linkedinMatch ? <span className={styles.matchBadge}>{linkedinMatch}</span> : null}
              </button>
            </div>
          ) : null}
        </div>
        {showInfos === "linkedin" ? (
          <div className={styles.fullInfosContainer} key={`${hitTalent.id}-full-info-linkedIn`}>
            <div className={styles.searchTextContainer}>
              <div className={styles.searchTextTitle}>Résumé :</div>
              <div>
                {!!hitLr?.summary ? (
                  <Highlight attribute={algoliaAttributes.linkedinSummary} hit={hitTalent} />
                ) : (
                  "aucun résumé"
                )}
              </div>
            </div>
            <div className={styles.searchTextContainer}>
              <div className={styles.searchTextTitle}>Experiences :</div>
              <div>
                {hitTalent.linkedinResume?.experiences?.map((exp: any, i: number) => {
                  const description = highExps?.[i]?.description?.value || "";
                  const jobTitle = highExps?.[i]?.jobTitle?.value || "";
                  const companyName = highExps?.[i]?.companyName?.value || "";

                  return (
                    <div key={`exp-${i}-${hitTalent.id}`} className={styles.searchTextContainer}>
                      <div className={cx(styles.searchTextTitle, styles.linkedinExpTitle)}>
                        <ReactMarkdown
                          components={{
                            p: React.Fragment,
                          }}
                          rehypePlugins={[rehypeRaw] as any}
                        >
                          {`${companyName} - ${jobTitle}`}
                        </ReactMarkdown>
                      </div>
                      {exp.startDate && exp.endDate ? (
                        <div className={styles.additionalInfosPart}>
                          ({format(parseISO(exp.startDate), "MM/yyyy")} - {format(parseISO(exp.endDate), "MM/yyyy")})
                        </div>
                      ) : null}
                      <div>
                        {!!description ? (
                          <ReactMarkdown
                            components={{
                              p: React.Fragment,
                            }}
                            rehypePlugins={[rehypeRaw] as any}
                          >
                            {description.replace(/^-/, "")}
                          </ReactMarkdown>
                        ) : (
                          "aucune description"
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {!!hitLr?.languages?.length ? (
              <div className={styles.searchTextContainer}>
                <div className={styles.searchTextTitle}>Languages :</div>
                <div>{hitLr.languages.map((l: any) => l?.name).join(", ")}</div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className={styles.actionCtasContainer}>
        <div className={cx(styles.actionItem, styles.leftAutoCta)}>
          <button
            onClick={(): void => {
              if (typeof sendEvent === "function") {
                sendEvent("click", hitTalent, "toContact");
              }
              setClicked(true);
              handleHuntTalent();
            }}
            className={cx(styles.actionCta, globalStyles.cta, globalStyles.inverted, globalStyles.primaryCta)}
            disabled={!!!offer || clicked}
            title={!!!offer ? "aucune offre sélectionnée" : undefined}
          >
            {t({ id: transApplication.status.applied })}
          </button>
        </div>
        <div className={cx(styles.actionItem)}>
          <button
            onClick={(): void => {
              if (typeof sendEvent === "function") {
                sendEvent("click", hitTalent, "notInteresting");
              }
              setClicked(true);
              addToDiscardedList();
            }}
            className={cx(styles.actionCta, globalStyles.cta, globalStyles.inverted, globalStyles.alertCta)}
            disabled={!!!offer || clicked}
            title={!!!offer ? "aucune offre sélectionnée" : undefined}
          >
            {t`HuntTalent.notInteresting`}
          </button>
        </div>
      </div>
      {!!disabledState ? (
        <div className={styles.disabledState}>
          <div>
            {disabledState === "application"
              ? "La candidature a bien été crée."
              : "Le talent a bien été mis en “pas intéressant”"}
          </div>
          <div>
            Si je ne disparait pas refresh{" "}
            <button
              className={cx(globalStyles.cta, globalStyles.aquaCta, globalStyles.inverted)}
              type="button"
              onClick={() => {
                refresh();
              }}
            >
              <svg width="1rem" height="1rem" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <title>Refresh the search results</title>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
            </button>{" "}
            la liste
          </div>
        </div>
      ) : null}
    </div>
  );
};
