import cx from "classnames";
import { FieldProps } from "formik";
import React, { FC, ReactNode } from "react";
import formStyles from "../../../styles/shared/form.module.scss";
import { ErrorMessage } from "../ErrorMessage";
import styles from "./index.module.scss";

interface Props extends FieldProps {
  id: string;
  label: ReactNode;
  placeholder?: string;
  defaultValue?: string;
  rows?: number;
  cols?: number;
  required?: boolean;
  showErrorMessage?: boolean;
  className?: string;
}

export const Textarea: FC<Props> = (props: Props) => {
  const {
    field,
    id,
    defaultValue,
    label,
    placeholder,
    rows = 10,
    cols = 20,
    required = false,
    showErrorMessage = true,
    className = "",
  } = props;
  const onBlurInput = (e: React.FocusEvent) => {
    field.onBlur(e);
  };
  const onChangeInput = (e: React.SyntheticEvent) => {
    field.onChange(e);
  };
  const fieldId = id ? id : field.name;
  return (
    <>
      {!!label ? (
        <label className={formStyles.label} htmlFor={fieldId}>
          {label}
        </label>
      ) : null}
      <div className={formStyles.inputContainer}>
        <textarea
          id={fieldId}
          name={field.name}
          rows={rows}
          cols={cols}
          placeholder={placeholder}
          className={cx(styles.textarea, { [className]: !!className })}
          defaultValue={defaultValue}
          onChange={onChangeInput}
          onBlur={onBlurInput}
          required={required}
          value={field.value}
        ></textarea>
      </div>
      {showErrorMessage && <ErrorMessage name={field.name} />}
    </>
  );
};
