import cx from "classnames";
import React, { useState, PropsWithChildren, useContext, createContext, Dispatch, SetStateAction } from "react";

const context = createContext<ContextValue | null>(null);
const ContextProvider = context.Provider;

export interface TabDefaultProps {
  slug: string;
  tabName: string;
  component: JSX.Element;
}

interface ContextValue {
  activePanel: string;
  setActivePanel: Dispatch<SetStateAction<string>>;
  defaultActiveClass: string;
  defaultDisabledClass: string;
}
interface PageWithTabs {
  defaultActivePanel: string;
  defaultActiveClass?: string;
  defaultDisabledClass?: string;
}

interface TabTrigger {
  activeClass?: string;
  disabledClass?: string;
  addWrapper?: boolean;
  classNames?: string;
  tabKey?: string;
  disabled?: boolean;
}
interface TabPanel {
  classNames?: string;
  panelKey?: string;
}

export const PageWithTabs: React.FC<PropsWithChildren<PageWithTabs>> = (props: PropsWithChildren<PageWithTabs>) => {
  const { children, defaultActivePanel = "", defaultActiveClass = "", defaultDisabledClass = "" } = props;
  const [activePanel, setActivePanel] = useState(defaultActivePanel);
  const contextValue = {
    activePanel,
    setActivePanel,
    defaultActiveClass,
    defaultDisabledClass,
  };
  return (
    <>
      <ContextProvider value={contextValue}>{children}</ContextProvider>
    </>
  );
};

export const TabTrigger: React.FC<PropsWithChildren<TabTrigger>> = (props: PropsWithChildren<TabTrigger>) => {
  const c = useContext(context);
  if (c === null) return null;

  const { activeClass = "", disabledClass = "", addWrapper = true, children, disabled = false, tabKey = "" } = props;
  const isActive = tabKey === c.activePanel;
  const onClickTabTrigger = (): void => {
    if (disabled) return;
    window.scrollTo(0, 0);
    c.setActivePanel(tabKey);
  };
  const activeClassName = activeClass ? activeClass : c.defaultActiveClass;
  const disabledClassName = disabledClass ? disabledClass : c.defaultDisabledClass;

  if (addWrapper) {
    return (
      <>
        <div
          onClick={onClickTabTrigger}
          className={cx({
            [activeClassName]: isActive,
            [disabledClassName]: disabled,
          })}
        >
          {children}
        </div>
      </>
    );
  }

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          const className = cx(child.props.classNames, {
            [activeClass]: isActive,
          });
          const newProps = {
            onClick: onClickTabTrigger,
            className,
          };
          return React.cloneElement(child, newProps, child.props.children);
        }
      })}
    </>
  );
};

export const TabPanel: React.FC<PropsWithChildren<TabPanel>> = (props: PropsWithChildren<TabPanel>) => {
  const { children, panelKey } = props;
  const c = useContext(context);
  if (c === null) return null;
  return c.activePanel === panelKey ? <>{children}</> : null;
};
