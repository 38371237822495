import { t } from "@lingui/macro";

interface Const {
  status: { [key: string]: string };
}

const transApplication: Const = {
  status: {
    applied: t`Constant.application.status.applied`,
  },
};

export default transApplication;
