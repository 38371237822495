import * as React from "react";
import { CardOffer } from "../../../../../components/CardOffer";
import { getOffersSortedByResponsibility } from "../../../../../helpers/offer";
import { GetOffers_clientContact_contact_company_offers_edges_node } from "../../../../../services/api/graphql/types";

import tab from "../tab.module.scss";

interface Props {
  offers: GetOffers_clientContact_contact_company_offers_edges_node[];
  loading?: boolean;
}

export const PanelInactiveOffers: React.FC<Props> = (props: Props) => {
  const { offers, loading = false } = props;
  const sortedOffers = getOffersSortedByResponsibility(offers);

  if (loading) {
    return <>Loading...</>;
  }
  if (sortedOffers.length === 0) {
    return <>Aucune offre...</>;
  }

  return (
    <>
      <ul>
        {sortedOffers.map((family, i) => {
          return (
            <li key={`${family.label}-${i}`} className={tab.familyItem}>
              <h3 className={tab.familyTitle}>{family.label}</h3>
              <ul>
                {family.offers.map((offer, ii) => {
                  return (
                    <li
                      key={`CardOffer-${offer.slug}-${ii}`}
                      className={tab.CardOfferItem}
                      data-testid="offers-card-item"
                    >
                      <CardOffer offer={offer} />
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </>
  );
};
