import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { Droppable } from "react-beautiful-dnd";
import { CardApplicationFragmentProps } from "../../../../services/api/graphql/types";
import globalStyles from "../../../../styles/global.module.scss";
import { DraggableCard } from "../DraggableCard";
import styles from "../index.module.scss";
import { ColumnDndProps } from "../setupDndData";

interface Props {
  applications: {
    [key: string]: CardApplicationFragmentProps;
  };
  applicationsIds: string[];
  column: ColumnDndProps;
  id: string;
  isDropDisabled?: boolean;
  isDragging?: boolean;
  loading?: boolean;
  isDragDisabled?: boolean;
  onScrollList?: (e: React.UIEvent<HTMLDivElement>) => void;
}

export const Column: React.FC<Props> = (props: Props) => {
  const {
    applications = {},
    applicationsIds = [],
    column,
    id,
    isDropDisabled = false,
    loading = false,
    isDragDisabled,
    onScrollList,
  } = props;

  return (
    <>
      <div className={styles.column}>
        <h3 className={styles.title}>{t({ id: column.title })}</h3>
        {loading ? (
          <div key={`loading-col-${id}`} className={styles.loadingCol}>
            Récupérations des canditatures...
          </div>
        ) : (
          <Droppable key={`loaded-col-${id}`} droppableId={id} isDropDisabled={isDropDisabled}>
            {(provided) => (
              <>
                <div
                  ref={provided.innerRef}
                  onScroll={onScrollList}
                  {...provided.droppableProps}
                  className={cx(styles.applicationsContainer, globalStyles.hideScrollBar, {
                    [styles.dragDisabled]: isDragDisabled,
                  })}
                >
                  {applicationsIds.map((ap, i) => (
                    <DraggableCard
                      key={ap}
                      id={ap}
                      index={i}
                      application={applications[ap]}
                      isDragDisabled={isDragDisabled}
                    />
                  ))}
                </div>
                <div
                  className={cx(styles.placeHolderContainer, {
                    [styles.hidden]: isDropDisabled,
                  })}
                >
                  {provided.placeholder}
                </div>
              </>
            )}
          </Droppable>
        )}
      </div>
    </>
  );
};
