import { AnyAction } from "redux";

import {
  ApiAuthenticationLogin,
  ApiAuthenticationLogout,
  ApiAuthenticationRefresh,
  CleanApiState,
  GoogleAuthenticationLogin,
  LinkedInAuthenticationLogin,
  ElinoiAuthenticationLogin,
  SwitchOff,
  SwitchOn,
  ElinoiForgotPassword,
  ElinoiChangePassword,
} from "../../../../actionTypes/authentication";
import { GetCurrentUser } from "../../../../actionTypes/clientContact";
import { ActionType } from "../../../../interfaces/api/actionType";
import { ClientContactProps } from "../../../../interfaces/resources/clientContact";
import { removeTokens } from "./helpers";

export function getCurrentUserAction(): AnyAction {
  return {
    type: GetCurrentUser.REQUEST,
  };
}

export function getCurrentUserSuccessAction(clientContact: ClientContactProps): AnyAction {
  return {
    clientContact,
    type: GetCurrentUser.SUCCESS,
  };
}

export interface LoginActionProps {
  authType: string;
  token: string;
  regToken?: string;
  type: string;
}

export interface LoginLinkedInActionProps {
  authType: string;
  token: string;
  regToken?: string;
  redirectUri: string;
  type: string;
}

export interface ForgotPasswordActionProps {
  email: string;
  type: string;
}
export interface ChangePasswordActionProps {
  forgot_token: string;
  password: string;
  type: string;
}
export interface LoginElinoiActionProps {
  authType: "elinoi";
  email: string;
  password: string;
  regToken?: string;
  type: string;
}

export const loginLocationAction = (locationSearch: string): AnyAction => ({
  locationSearch,
  type: ApiAuthenticationLogin.SUCCESS,
});

export const refreshAction = (refreshToken: string): AnyAction => ({
  refreshToken,
  type: ApiAuthenticationRefresh.REQUEST,
});

export const logoutAction = (): AnyAction => ({
  type: ApiAuthenticationLogout.REQUEST,
});

export const onGoogleLoginAction = (response: any, params: { regToken?: string }): LoginActionProps | null => {
  // The ID token you need to pass to your backend:
  return {
    authType: "google",
    token: response.credential,
    regToken: params.regToken,
    type: GoogleAuthenticationLogin.REQUEST,
  };
};

export const onGoogleFailureAction = (): AnyAction => {
  return {
    type: GoogleAuthenticationLogin.FAILURE,
  };
};

export const onLinkedInSuccessAction = (
  token: string,
  redirectUri: string,
  params: { regToken?: string },
): LoginLinkedInActionProps => {
  return {
    authType: "linkedin",
    token,
    regToken: params.regToken,
    redirectUri,
    type: LinkedInAuthenticationLogin.REQUEST,
  };
};

export const onLinkedInFailureAction = (): AnyAction => {
  return {
    type: LinkedInAuthenticationLogin.FAILURE,
  };
};

export const onElinoiLoginAction = (
  email: string,
  password: string,
  params: { regToken?: string },
): LoginElinoiActionProps => {
  return {
    authType: "elinoi",
    email,
    password,
    regToken: params.regToken,
    type: ElinoiAuthenticationLogin.REQUEST,
  };
};

export const onElinoiFailureAction = (): AnyAction => {
  return {
    type: ElinoiAuthenticationLogin.FAILURE,
  };
};

export const cleanApiStateAction = (type: keyof ActionType, filter?: string | string[]): AnyAction => {
  return {
    filter,
    type: CleanApiState[type],
  };
};

export function switchOnAction(iri: string): AnyAction {
  return {
    iri,
    type: SwitchOn.REQUEST,
  };
}

export function switchOffAction(): AnyAction {
  removeTokens("switch");
  return {
    type: SwitchOff.DEFAULT,
  };
}

export const onForgotPasswordAction = (email: string): ForgotPasswordActionProps => {
  return {
    email,
    type: ElinoiForgotPassword.REQUEST,
  };
};
export const onChangePasswordAction = (values: {
  forgot_token: string;
  password: string;
}): ChangePasswordActionProps => {
  return {
    forgot_token: values.forgot_token,
    password: values.password,
    type: ElinoiChangePassword.REQUEST,
  };
};
