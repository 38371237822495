import cx from "classnames";
import { ErrorMessage as ErrorMessageFormik } from "formik";
import React from "react";

import styles from "./index.module.scss";

interface ErrorMessage {
  name: string;
  message?: string;
  className?: string;
}

interface ErrorWording {
  message?: string;
  className?: string;
}

export const ErrorWording = ({ message, className }: ErrorWording) => (
  <div className={cx(className, styles.formError)}>{message}</div>
);

export const ErrorMessage: React.FC<ErrorMessage> = ({ name, message, className }: ErrorMessage) => {
  return (
    <>
      <ErrorMessageFormik name={name} render={(msg) => <ErrorWording message={msg} className={className} />} />
      {message ? <ErrorWording message={message} /> : undefined}
    </>
  );
};
