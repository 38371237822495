import cx from "classnames";
import * as React from "react";
import { Draggable } from "react-beautiful-dnd";
import { CardApplication } from "../../../../components/CardApplication";
import { CardApplicationFragmentProps } from "../../../../services/api/graphql/types";
import styles from "../index.module.scss";

interface Props {
  index: number;
  id: string;
  application: CardApplicationFragmentProps;
  isDragDisabled?: boolean;
}

export const DraggableCard: React.FC<Props> = (props: Props) => {
  const { index, id, application, isDragDisabled } = props;
  return (
    <>
      <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
        {(provided, snapshot) => (
          <>
            <div
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
              className={styles.cardContainer}
            >
              <CardApplication
                application={application}
                showOfferName={false}
                showStatusSwitch={false}
                deactivated={isDragDisabled}
              />
            </div>
            {snapshot.isDragging && (
              <div className={cx(styles.cardContainer, styles.cardCloneContainer)}>
                <CardApplication
                  application={application}
                  showOfferName={false}
                  showStatusSwitch={false}
                  deactivated={isDragDisabled}
                />
              </div>
            )}
          </>
        )}
      </Draggable>
    </>
  );
};
