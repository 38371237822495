import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
import { getAuthorizationHeader } from "../rest/authentication/helpers";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPH_QL_URL,
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...getAuthorizationHeader(),
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Offer: {
        fields: {
          applications: relayStylePagination(["clientStatus_list"]),
        },
      },
    },
  }),
});
