import cx from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { GetCurrentUser } from "../../actionTypes/clientContact";
import { StateProps } from "../../interfaces/state";

import { routes } from "../../routes";

import { getCurrentUserAction } from "../../services/api/rest/authentication/actions";
import globalStyles from "../../styles/global.module.scss";
import { Loader } from "../Loader";

export const RequireAuth: React.FC<React.PropsWithChildren> = (props: React.PropsWithChildren) => {
  const { children } = props;
  const location = useLocation();

  const dispatch = useDispatch();

  const currentUser = useSelector((state: StateProps) => state.currentUser);
  const auth = useSelector((state: StateProps) => state.auth);
  const pendings = useSelector((state: StateProps) => state.apiPendingRequests);

  React.useEffect(() => {
    const getCurrentUser = () => dispatch(getCurrentUserAction());
    const isPending = pendings.some((e) => [GetCurrentUser.REQUEST].includes(e.type));
    if (currentUser === null && auth.isAuthed && !isPending) {
      getCurrentUser();
    }
  }, [currentUser, auth.isAuthed, pendings, dispatch]);

  const next = location ? `?next=${encodeURI(location.pathname)}` : "";
  return (
    <>
      {auth.isAuthed ? (
        currentUser ? (
          children
        ) : (
          <div className={cx(globalStyles.pageWrapper, globalStyles.loaderPageWrapper)}>
            <Loader />
          </div>
        )
      ) : (
        <Navigate
          to={{
            pathname: routes.auth.login,
            search: next,
          }}
          replace
        />
      )}
    </>
  );
};
