import { gql, useQuery } from "@apollo/client";
import { t } from "@lingui/macro";
import cx from "classnames";
import { MiniSignalBinding } from "mini-signals";
import * as React from "react";
import { useSelector } from "react-redux";
import { CardOfferFragment } from "../../../components/CardOffer";
import { GraphqlError } from "../../../components/GraphqlError";
import { TabPanel, PageWithTabs, TabTrigger, TabDefaultProps } from "../../../components/Tabs";
import { EVENT } from "../../../helpers/constant";
import { eventDispatcher } from "../../../helpers/eventDispatcher";
import { OFFER_STATUS_ACTIVE, OFFER_STATUS_STANDBY } from "../../../interfaces/resources/offer";

import { StateProps } from "../../../interfaces/state";
import {
  CardOfferFragmentProps,
  GetOffers,
  GetOffersVariables,
  OffersPageFragmentProps,
} from "../../../services/api/graphql/types";
import global from "../../../styles/global.module.scss";
import styles from "./index.module.scss";
import { PanelActiveOffers } from "./panels/PanelActiveOffers";
import { PanelInactiveOffers } from "./panels/PanelInactiveOffers";
import tabStyles from "./panels/tab.module.scss";

export const OFFERS_PAGE_QUERY = gql`
  query GetOffers($userId: ID!) {
    clientContact(id: $userId) {
      id
      contact {
        company {
          offers {
            edges {
              node {
                ...OffersPageFragmentProps
              }
            }
          }
        }
      }
    }
  }
  fragment OffersPageFragmentProps on Offer {
    id
    coreResponsibility {
      id
      responsibilityType {
        value
      }
    }
    ...CardOfferFragmentProps
  }
  ${CardOfferFragment}
`;

export const OffersPage: React.FC = () => {
  const currentUser = useSelector((state: StateProps) => state.currentUser);
  const requestSubmittedRef = React.useRef<MiniSignalBinding>();
  const [offers, setOffers] = React.useState<OffersPageFragmentProps[] | undefined>(undefined);
  const { loading, error, data } = useQuery<GetOffers, GetOffersVariables>(OFFERS_PAGE_QUERY, {
    variables: {
      userId: currentUser ? currentUser["@id"] : "",
    },
  });
  const [tabs, setTabs] = React.useState<TabDefaultProps[]>([]);
  const onRequestFormSubmitted = React.useCallback(
    (params: { offer: CardOfferFragmentProps | undefined }) => {
      const allOffers: OffersPageFragmentProps[] = [];
      if (!!offers) {
        offers.forEach((o) => {
          if (o.id === params.offer?.id) {
            o = { ...o, clientRequest: true };
          }
          allOffers.push(o);
        });
      }
      setOffers(allOffers);
    },
    [offers],
  );

  React.useEffect(() => {
    requestSubmittedRef.current = eventDispatcher.on(EVENT.RequestFormSubmitted, onRequestFormSubmitted);

    return () => {
      if (requestSubmittedRef.current) {
        eventDispatcher.off(EVENT.RequestFormSubmitted, requestSubmittedRef.current);
      }
    };
  }, [onRequestFormSubmitted]);

  React.useEffect(() => {
    const activeO: OffersPageFragmentProps[] = [];
    const inactiveO: OffersPageFragmentProps[] = [];
    if (offers) {
      offers.forEach((o) => {
        const activeStatus = [OFFER_STATUS_ACTIVE, OFFER_STATUS_STANDBY];
        const isActive = o.status && activeStatus.includes(o.status);
        isActive ? activeO.push(o) : inactiveO.push(o);
      });
    }
    setTabs([
      {
        slug: "active-offers",
        tabName: t`OffersPage.tab.active.offers.title`,
        component: <PanelActiveOffers offers={activeO} loading={loading} />,
      },
      {
        slug: "inactive-offers",
        tabName: t`OffersPage.tab.inactive.offers.title`,
        component: <PanelInactiveOffers offers={inactiveO} loading={loading} />,
      },
    ]);
  }, [setTabs, offers, loading]);

  React.useEffect(() => {
    const allOffers: OffersPageFragmentProps[] = [];
    if (data) {
      const ofrs = data.clientContact?.contact?.company?.offers?.edges || [];
      ofrs.forEach((v) => {
        if (v !== null && v.node !== null) {
          const { node } = v;
          allOffers.push(node);
        }
      });
    }
    setOffers(allOffers);
  }, [data]);

  if (error) {
    return (
      <>
        <div className={global.pageWrapper} data-testid="offers-page-error">
          <GraphqlError error={error} />
        </div>
      </>
    );
  }
  return (
    <>
      <PageWithTabs
        defaultActivePanel="active-offers"
        defaultActiveClass={styles.tabActive}
        defaultDisabledClass={styles.tabDisabled}
      >
        <div className={global.pageWrapper} data-testid="offers-page">
          <ul className={styles.tabsList} data-testid="offers-tab-trigger">
            {tabs.map((tab) => {
              return (
                <li key={`page-offer-tab--${tab.slug}`}>
                  <TabTrigger tabKey={tab.slug}>
                    <div className={cx(styles.tabLink)}>{tab.tabName}</div>
                  </TabTrigger>
                </li>
              );
            })}
          </ul>
          <div className={styles.panelsWrapper} data-testid="offers-tab-panels">
            {tabs.map((tab) => {
              return (
                <TabPanel key={`page-offer-panel--${tab.slug}`} panelKey={tab.slug}>
                  <div className={styles.tabPanelWrapper}>{tab.component}</div>
                </TabPanel>
              );
            })}
          </div>
          <div className={cx(styles.panelsWrapper, loading ? styles.loading : null)}>
            <div className={styles.tabPanelWrapper}>
              <div className={tabStyles.familyItem}>
                <div className={styles.cardBannerWrapper}>
                  <div className={styles.cardBannerContent}>
                    <h2 className={styles.title}>
                      {t`OffersPage.banner.title`}
                      <br />
                      {t`OffersPage.banner.subTitle`}
                    </h2>
                    <span className={styles.text}>{t`OffersPage.banner.text`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWithTabs>
    </>
  );
};
