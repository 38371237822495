import { gql } from "@apollo/client";
import { t } from "@lingui/macro";
import cx from "classnames";
import { format, formatDistance } from "date-fns";
import { fr } from "date-fns/locale";
import { MiniSignalBinding } from "mini-signals";
import * as React from "react";
import { FiAlertOctagon, FiCheck, FiLoader } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { EditApplicationClientStatus } from "../../actionTypes/application";
import {
  ApplicationStatusKeys,
  APPLICATION_STATUS,
  EVENT,
  APPLICATION_STATUS_MARKETPLACE,
} from "../../helpers/constant";
import { truncate } from "../../helpers/dom";
import { eventDispatcher } from "../../helpers/eventDispatcher";
import { getUserImage } from "../../helpers/images";
import { transApplicationStatus } from "../../helpers/trans";
import { StateProps } from "../../interfaces/state";
import { CardApplicationFragmentProps } from "../../services/api/graphql/types";
import { onEditApplicationClientStatusAction } from "../../services/api/rest/actions";
import { cleanApiStateAction } from "../../services/api/rest/authentication/actions";
import globalStyles from "../../styles/global.module.scss";
import { TalentCardFragment } from "../ModalWrapper/TalentCardModal";
import styles from "./index.module.scss";

type CardStateProps = "initial" | "submitting" | "success" | "error";
interface Props {
  showOfferName?: boolean;
  showStatusSwitch?: boolean;
  disableMaxWidth?: boolean;
  application: CardApplicationFragmentProps;
  changeCardState?: CardStateProps;
  deactivated?: boolean;
}

export const CardApplicationFragment = gql`
  fragment CardApplicationFragmentProps on Application {
    id
    clientStatus
    status
    createdAt
    offer {
      id
      title
      ...OfferInfosTalentCardFragmentProps
    }
    applicationStatusEvents {
      edges {
        node {
          status
          createdAt
        }
      }
    }
    talent {
      firstname
      lastname
      image
      linkedInProfile {
        url
        headline
      }
      talentProfile {
        currentJob
        currentCompany
      }
      ...TalentInfosTalentCardFragmentProps
    }
    talentCard {
      ...InfosTalentCardFragmentProps
    }
    applicationMessages {
      edges {
        node {
          clientRead
          clientEnabled
          clientContact {
            id
          }
        }
      }
    }
    ...ApplicationInfosTalentCardFragmentProps
  }
  ${TalentCardFragment}
`;

export const CardApplication: React.FC<Props> = (props: Props) => {
  const {
    changeCardState = "initial",
    disableMaxWidth = false,
    showOfferName = true,
    showStatusSwitch = true,
    application,
    deactivated,
  } = props;
  const dispatch = useDispatch();
  const apiErrors = useSelector((state: StateProps) => state.apiErrors);
  const apiSuccess = useSelector((state: StateProps) => state.apiSuccess);
  const apiRequests = useSelector((state: StateProps) => state.apiPendingRequests);
  const CurrentStatusChangeRef = React.useRef<MiniSignalBinding>();
  const CardStateTimeoutRef = React.useRef<NodeJS.Timer | null>(null);
  const [sentTime, setSentTime] = React.useState<string | null>(null);
  const [formattedSendDate, setFormattedSendDate] = React.useState<string | null>(null);
  const [statusOptions, setStatusOptions] = React.useState<JSX.Element[]>([]);
  const [selectedStatus, setSelectedStatus] = React.useState(application.clientStatus || "");
  const user = useSelector((state: StateProps) => state.currentUser);
  const [hasUnreadMessage, setHasUnreadMessage] = React.useState<boolean>(false);
  const [messageCount, setMessageCount] = React.useState<number>(0);

  const [cardState, setCardState] = React.useState<CardStateProps>(changeCardState);
  const [openStatusDropdown, setOpenStatusDropdown] = React.useState<boolean>(false);
  const firstname = application.talent.firstname.toLowerCase();
  const lastname = application.talent.lastname.toLowerCase();
  const currentJob = application.talent.talentProfile?.currentJob;
  const currentJCompany = application.talent.talentProfile?.currentCompany;
  const headline =
    application.talent.linkedInProfile && application.talent.linkedInProfile.headline
      ? truncate(application.talent.linkedInProfile.headline, 6)
      : null;

  const onClickTriggerStatusDropdown = () => {
    setOpenStatusDropdown(!openStatusDropdown);
  };

  const onClickDropDownCta = React.useRef((cstat: string) => {
    setOpenStatusDropdown(false);
    eventDispatcher.trigger(EVENT.CurrentStatusChange, {
      appId: application.id,
      newStatus: cstat,
    });
    dispatch(
      onEditApplicationClientStatusAction({
        applicationIri: application.id,
        clientStatus: cstat,
      }),
    );
  }).current;

  const showTalentCard = () => {
    const params = {
      type: "TalentCardModal",
      data: {
        talent: application.talent,
        talentCard: application.talentCard,
        offer: application.offer,
        application,
        formattedSendDate,
        currentAppStatus: selectedStatus,
      },
    };
    eventDispatcher.trigger(EVENT.ModalOpen, params);
  };

  const onCurrentStatusChange = React.useCallback(
    (app: CardApplicationFragmentProps, params: { appId: string; newStatus: string }) => {
      if (app.id === params.appId) {
        setSelectedStatus(params.newStatus);
      }
    },
    [],
  );
  const onCardStateSuccess = React.useCallback(() => {
    setCardState("initial");
    dispatch(cleanApiStateAction("SUCCESS", EditApplicationClientStatus.SUCCESS));
  }, [dispatch]);

  React.useEffect(() => {
    if (application.applicationMessages?.edges) {
      let count = 0;
      setMessageCount(application.applicationMessages.edges.length);
      application.applicationMessages?.edges.forEach((apM) => {
        if (apM && apM.node && apM.node.clientEnabled === true) {
          count++;
        }
        setMessageCount(count);
        if (apM && apM.node && !!!apM.node.clientContact && !apM.node.clientRead?.includes(user?.["@id"] || "")) {
          setHasUnreadMessage(true);
        }
      });
    }
  }, [application]);

  React.useEffect(() => {
    const arr: JSX.Element[] = [];
    Object.keys(APPLICATION_STATUS).forEach((s, i) => {
      const clientStatus = APPLICATION_STATUS[s as ApplicationStatusKeys];
      arr.push(
        <li key={`dp-option-${clientStatus}-${i}`}>
          <div
            onClick={() => {
              onClickDropDownCta(clientStatus);
            }}
            className={cx(styles.dropdownCta, globalStyles.dropdownCta, {
              [styles.isCurrent]: selectedStatus === clientStatus,
            })}
          >
            <span className={styles.radioBtn}></span>
            {t({ id: transApplicationStatus[clientStatus] })}
          </div>
        </li>,
      );
    });
    setStatusOptions(arr);
  }, [selectedStatus, onClickDropDownCta]);

  React.useEffect(() => {
    CurrentStatusChangeRef.current = eventDispatcher.on(
      EVENT.CurrentStatusChange,
      (params: { appId: string; newStatus: string }) => {
        onCurrentStatusChange(application, params);
      },
    );

    return (): void => {
      if (CurrentStatusChangeRef.current) {
        eventDispatcher.off(EVENT.CurrentStatusChange, CurrentStatusChangeRef.current);
      }
    };
  }, [application, onCurrentStatusChange]);

  React.useEffect(() => {
    const errors = apiErrors.filter((e) => e.type === EditApplicationClientStatus.FAILURE);
    const success = apiSuccess.filter((e) => e.type === EditApplicationClientStatus.SUCCESS);

    if (cardState === "submitting" && success.length) {
      setCardState("success");
    } else if (cardState === "submitting" && errors.length) {
      setCardState("error");
    } else if (cardState === "success") {
      CardStateTimeoutRef.current = setTimeout(() => {
        onCardStateSuccess();
      }, 800);
    }
    return () => {
      if (CardStateTimeoutRef.current) {
        clearTimeout(CardStateTimeoutRef.current);
      }
    };
  }, [cardState, apiErrors, apiSuccess, onCardStateSuccess]);

  React.useEffect(() => {
    const pending = apiRequests.filter(
      (e) => e.type === EditApplicationClientStatus.REQUEST && e.applicationIri === application.id,
    );
    if (pending.length && cardState === "initial") setCardState("submitting");
  }, [apiRequests, application.id, cardState]);

  React.useEffect(() => {
    if (application.applicationStatusEvents?.edges) {
      const events = application.applicationStatusEvents.edges;
      if (Array.isArray(events)) {
        const event = events
          .map((ev) => {
            if (ev && ev.node && ev.node.status === "shortlisted") {
              return ev.node;
            }
            return undefined;
          })
          .filter((ev) => {
            return typeof ev !== "undefined";
          });

        if (event[0]) {
          const time = event[0].createdAt;
          const fromToday = formatDistance(new Date(time), new Date(), {
            locale: fr,
          });
          const dateFormatted = format(new Date(time), "dd/MM/yyyy");
          setSentTime(fromToday);
          setFormattedSendDate(dateFormatted);
        }
      }
    }
  }, [application]);

  return (
    <>
      <div className={cx(styles.card, { [styles.withMaxWidth]: !disableMaxWidth })}>
        <div
          className={cx(styles.cardContent, {
            [styles.deactivate]: deactivated,
          })}
        >
          <div
            className={cx(styles.headerCardContainer, {
              [styles.hasTc]: !!application.talentCard && application.clientStatus !== APPLICATION_STATUS_MARKETPLACE,
            })}
            onClick={() => {
              if (!!application.talentCard && application.clientStatus !== APPLICATION_STATUS_MARKETPLACE) {
                showTalentCard();
              }
            }}
          >
            <h3
              className={cx(styles.offerName, {
                [styles.isTalentName]: !showOfferName,
              })}
            >
              {showOfferName ? (
                <>{application.offer.title}</>
              ) : (
                <>
                  {firstname} {lastname}
                </>
              )}
            </h3>
            <div className={cx(styles.statesContainer, styles[cardState])}>
              <div className={cx(styles.state, styles.loadingState)}>
                <FiLoader />
              </div>
              <div className={cx(styles.state, styles.successState)}>
                <FiCheck />
              </div>
              <div className={cx(styles.state, styles.errorState)}>
                <FiAlertOctagon />
              </div>
            </div>
          </div>
          <div
            className={cx(styles.talentProfileContainer, {
              [styles.hasTc]: !!application.talentCard && application.clientStatus !== APPLICATION_STATUS_MARKETPLACE,
            })}
            onClick={() => {
              if (!!application.talentCard && application.clientStatus !== APPLICATION_STATUS_MARKETPLACE) {
                showTalentCard();
                setHasUnreadMessage(false);
              }
            }}
          >
            <div className={globalStyles.imgProfileContainer}>
              <div className={cx(globalStyles.imgContent, styles.imgContent)}>
                <img alt={`${firstname} ${lastname}`} {...getUserImage(application.talent.image)} />
              </div>
            </div>
            <div>
              {showOfferName ? (
                <h4
                  className={cx(styles.talentName, {
                    [styles.hasTc]: !!application.talentCard,
                  })}
                >
                  {firstname} {lastname}
                </h4>
              ) : null}
              <div className={styles.linkedInTitle}>
                {currentJob && currentJCompany ? `${currentJob} @ ${currentJCompany}` : headline}
              </div>
              {!showOfferName
              && !!application.talentCard
              && application.clientStatus !== APPLICATION_STATUS_MARKETPLACE ? (
                <div className={styles.seeTalentCardCtaContainer}>
                  <div className={styles.seeTalentCardCta}>
                    Voir la talent Card {!!messageCount ? <span>({messageCount})</span> : null}
                  </div>
                  {!!hasUnreadMessage ? <div className={styles.unreadMessageDot}></div> : null}
                </div>
              ) : application.talent.linkedInProfile?.url ? (
                <div className={styles.seeTalentCardCta}>
                  <a href={application.talent.linkedInProfile.url}>Voir le profil linkedin</a>
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.footerContainer}>
            <div
              className={cx(
                styles.statuSwitchContainer,
                styles.dropdownTrigger,
                globalStyles.dropdownTrigger,
                globalStyles.triggerOnClick,
                { [globalStyles.open]: openStatusDropdown },
                { [styles.open]: openStatusDropdown },
              )}
            >
              {showStatusSwitch ? (
                <>
                  <span className={styles.switchCta} onClick={onClickTriggerStatusDropdown}>
                    <IoIosArrowDown />{" "}
                    <span>{application.clientStatus ? t({ id: transApplicationStatus[selectedStatus] }) : null}</span>
                  </span>
                  <div className={cx(styles.dropdownContainer, globalStyles.dropdownContainer)}>
                    <ul className={cx(styles.dropdownList, globalStyles.dropdownList)}>{statusOptions}</ul>
                  </div>
                </>
              ) : null}
            </div>
            <div className={styles.sendDateContainer}>
              {sentTime
                ? t({
                    id: "CardApplication.wording.sentTime",
                    values: { sentTime },
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
