import * as React from "react";

interface Props {
  to: string | string[];
  subject?: string;
  body?: string;
  bcc?: string | string[];
  cc?: string | string[];
}

const url = "https://mail.google.com/mail/?extsrc=mailto&url=mailto";

export const GmailMailtoFunc = (props: Props): string => {
  const queryParams =
    ":?"
    + Object.keys(props)
      .map((key: string) => key + "=" + props[key as keyof Props])
      .join("&");
  return url + encodeURIComponent(queryParams);
};

export const GmailMailto = (props: Props): JSX.Element => {
  return (
    <a href={GmailMailtoFunc(props)} target="_blank" rel="external nofollow noopener noreferrer">
      {props.to}
    </a>
  );
};
