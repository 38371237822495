import { AnyAction } from "redux";
import { LOCATION_CHANGE } from "redux-first-history";
import { CleanApiState } from "../../../actionTypes/authentication";

import { ApiError, ApiRequest, ApiSuccess } from "../../../interfaces/api/http";

import { getFailType, getRequestType, getSuccessType } from "./helpers";

export function apiErrors(state: ApiError[] = [], action: AnyAction): ApiError[] {
  if (action.type === CleanApiState.FAILURE) {
    if ((Array.isArray(action.filter) && action.filter.length) || !!action.filter) {
      return state.filter((e: ApiError) => {
        if (Array.isArray(action.filter)) {
          return !action.filter.includes(e.type);
        }
        return e.type !== action.filter;
      });
    }
    return [];
  }
  if (action.type === getFailType(action) && action.payload) {
    return [
      ...state,
      {
        ...(action as ApiError),
      },
    ];
  }
  if (action.type === getRequestType(action) && action.form) {
    return state.filter((e: ApiError) => e.form !== action.form);
  }
  if (LOCATION_CHANGE === action.type) {
    return [];
  }
  return state;
}

export function apiSuccess(state: ApiSuccess[] = [], action: AnyAction): ApiSuccess[] {
  if (action.type === CleanApiState.SUCCESS) {
    if ((Array.isArray(action.filter) && action.filter.length) || !!action.filter) {
      return state.filter((e: ApiSuccess) => {
        if (Array.isArray(action.filter)) {
          return !action.filter.includes(e.type);
        }
        return e.type !== action.filter;
      });
    } else {
      return [];
    }
  } else if (action.type === getSuccessType(action)) {
    return [
      ...state,
      {
        ...(action as ApiSuccess),
      },
    ];
  } else if (action.type === getRequestType(action) && action.form) {
    return state.filter((e: ApiSuccess) => e.form !== action.form);
  } else if ([LOCATION_CHANGE].includes(action.type)) {
    return [];
  }
  return state;
}

export function apiPendingRequests(state: ApiRequest[] = [], action: AnyAction): ApiRequest[] {
  if (action.type === CleanApiState.REQUEST) {
    if ((Array.isArray(action.filter) && action.filter.length) || !!action.filter) {
      return state.filter((e: ApiRequest) => {
        if (Array.isArray(action.filter)) {
          return !action.filter.includes(e.type);
        }
        return e.type !== action.filter;
      });
    } else {
      return [];
    }
  } else if (action.type === getRequestType(action)) {
    return [
      ...state,
      {
        ...(action as ApiRequest),
      },
    ];
  } else if (action.type === getSuccessType(action) || action.type === getFailType(action)) {
    return [...state.filter((req) => req.type !== getRequestType(action))];
  }
  return state;
}
