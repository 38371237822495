import { t } from "@lingui/macro";

interface Const {
  [key: string]: string;
}

export const transAvailability: Const = {
  notice_asap: t`Constant.availability.notice_asap`,
  notice_1_month: t`Constant.availability.notice_1_month`,
  notice_3_months: t`Constant.availability.notice_3_months`,
  "notice_>3_months": t`Constant.availability.notice_>3_months`,
};
