export const routes = {
  auth: {
    login: "/login",
    loginCheck: "/login-check",
    signIn: "/sign-in",
    forgotPassword: "/login/forgot-password",
  },
  app: {
    home: "/",
    offers: "/offers",
    offer: "/offer/:slug",
    talents: "/talents",
    marketplace: "/marketplace",
  },
};
