import { ApolloProvider } from "@apollo/client";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { fr } from "make-plural/plurals";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { App } from "./components/App";
import * as serviceWorker from "./registerServiceWorker";
import { client } from "./services/api/graphql/client";
import { store, history } from "./store";
import { messages } from "./translations/fr/messages";
import "./instantSearch-satellite.scss";
i18n.loadLocaleData("fr", { plurals: fr });
i18n.load("fr", messages);
i18n.activate("fr");

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <I18nProvider i18n={i18n}>
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    </I18nProvider>
  </ApolloProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
