import { CoreUserProps } from "./coreUser";
import { ResponsibilityProps } from "./responsability";

export const OFFER_STATUS_ACTIVE = "activated";
export const OFFER_STATUS_INACTIVE = "desactivated";
export const OFFER_STATUS_STANDBY = "standby_elinoi";

type Status = typeof OFFER_STATUS_ACTIVE | typeof OFFER_STATUS_INACTIVE | typeof OFFER_STATUS_STANDBY;

export interface OfferProps {
  "@id": string;
  coreResponsibility: ResponsibilityProps; // Responsability
  owner: CoreUserProps; // CoreUser
  slug: string;
  status: Status;
  isEnabled: boolean;
  title: string;
}
export interface TalentProps {
  "@id": string;
  coreResponsibility: ResponsibilityProps; // Responsability
  owner: CoreUserProps; // CoreUser
  slug: string;
  status: Status;
  isEnabled: boolean;
  title: string;
}
