import { gql, useLazyQuery } from "@apollo/client";
import { t } from "@lingui/macro";
import cx from "classnames";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { Field, Formik, FormikValues, Form, FormikHelpers } from "formik";
import * as React from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { PostApplicationMessage } from "../../../actionTypes/application";
import {
  ApplicationStatusKeys,
  APPLICATION_STATUS,
  APPLICATION_STATUS_PRE_SEND,
  EVENT,
} from "../../../helpers/constant";
import { eventDispatcher } from "../../../helpers/eventDispatcher";
import { GmailMailtoFunc } from "../../../helpers/gmailto";
import { getUserImage } from "../../../helpers/images";
import { transApplicationStatus } from "../../../helpers/trans";
import Yup from "../../../helpers/Yup";
import { StateProps } from "../../../interfaces/state";
import { COL_TITLE } from "../../../pages/app/OfferPage/setupDndData";
import {
  ApplicationClientStatusEventFragmentProps,
  ApplicationInfosTalentCardFragmentProps,
  ApplicationMessageFragmentProps,
  GetApplicationClientEvents,
  GetApplicationClientEventsVariables,
  InfosTalentCardFragmentProps,
  OfferInfosTalentCardFragmentProps,
  TalentInfosTalentCardFragmentProps,
} from "../../../services/api/graphql/types";
import {
  onClientReadMessages,
  onEditApplicationClientStatusAction,
  onPostApplicationMessage,
  onPostTalentCardClientEventAction,
} from "../../../services/api/rest/actions";
import { cleanApiStateAction } from "../../../services/api/rest/authentication/actions";
import globalStyles from "../../../styles/global.module.scss";
import { transAvailability } from "../../../translations/constants/availability";
import { LinkCta } from "../../Cta";
import { SubmitCta, SubmitCtaState } from "../../Form/SubmitCta";
import { Textarea } from "../../Form/Textarea";
import { MarkdownRenderer } from "../../MarkdownRenderer";
import errorStyles from "../error.module.scss";

import styles from "./index.module.scss";

export interface Props {
  data: DataProps;
}

interface DataProps {
  talent?: TalentInfosTalentCardFragmentProps;
  talentCard?: InfosTalentCardFragmentProps;
  offer?: OfferInfosTalentCardFragmentProps;
  application?: ApplicationInfosTalentCardFragmentProps;
  formattedSendDate?: string;
  currentAppStatus?: string;
}

interface KeyNodeProps {
  type: "clientStatusEvent" | "clientMessage";
  node: ApplicationClientStatusEventFragmentProps | ApplicationMessageFragmentProps;
}

export const TalentCardFragment = gql`
  fragment OfferInfosTalentCardFragmentProps on Offer {
    id
    name
  }
  fragment TalentInfosTalentCardFragmentProps on Talent {
    id
    email
    firstname
    image
    lastname
    talentProfile {
      lastQualification {
        id
        priorNotice
        expectedRemuneration
        storytelling
      }
    }
    linkedInProfile {
      url
    }
    phone
  }
  fragment ApplicationInfosTalentCardFragmentProps on Application {
    id
    clientStatus
    applicationClientStatusEvents(last: 10) {
      edges {
        node {
          id
          status
          createdAt
          clientContact {
            id
            firstname
          }
        }
      }
    }
  }
  fragment InfosTalentCardFragmentProps on TalentCard {
    id
    availability
    bridges
    description
    document
    educations
    positions
    salary
  }
`;

export const APPLICATION_CLIENT_EVENTS_QUERY = gql`
  query GetApplicationClientEvents($appId: ID!) {
    application(id: $appId) {
      applicationClientStatusEvents(last: 10) {
        edges {
          node {
            ...ApplicationClientStatusEventFragmentProps
          }
        }
      }
      applicationMessages(clientEnabled: true) {
        edges {
          node {
            ...ApplicationMessageFragmentProps
          }
        }
      }
    }
  }
  fragment ApplicationClientStatusEventFragmentProps on ApplicationClientStatusEvent {
    id
    status
    createdAt
    clientContact {
      id
      firstname
    }
  }
  fragment ApplicationMessageFragmentProps on ApplicationMessage {
    id
    message
    createdAt
    clientContact {
      id
      firstname
    }
    coreUser {
      firstname
    }
    clientRead
    clientEnabled
  }
`;

export const TalentCardModal: React.FC<Props> = (props: Props) => {
  const { data = {} } = props;
  const dispatch = useDispatch();
  const formStateTimeoutRef = React.useRef<NodeJS.Timer | null>(null);
  const resetFormRef = React.useRef<any>(null);
  const currentUser = useSelector((state: StateProps) => state.currentUser);
  const apiErrors = useSelector((state: StateProps) => state.apiErrors);
  const apiSuccess = useSelector((state: StateProps) => state.apiSuccess);
  const [formState, setFormState] = React.useState<SubmitCtaState>("initial");
  const [talent, setTalent] = React.useState<TalentInfosTalentCardFragmentProps | undefined>(undefined);
  const [application, setApplication] = React.useState<ApplicationInfosTalentCardFragmentProps | undefined>(undefined);
  const [talentCard, setTalentCard] = React.useState<InfosTalentCardFragmentProps | undefined>(undefined);
  const [offer, setOffer] = React.useState<OfferInfosTalentCardFragmentProps | undefined>(undefined);
  const [formattedSendDate, setFormattedSendDate] = React.useState<string | undefined>(undefined);
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);
  const [unreadMessages, setUnreadMessages] = React.useState<any[]>([]);

  const [openStatusDropdown, setOpenStatusDropdown] = React.useState(false);
  const [statusOptions, setStatusOptions] = React.useState<JSX.Element[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = React.useState<boolean>(false);
  const [applicationMessages, setApplicationMessages] = React.useState<[string, KeyNodeProps[]][]>([]);
  const [selectedStatus, setSelectedStatus] = React.useState<string>(data.currentAppStatus || "");
  const validationSchema = Yup.object().shape({
    message: Yup.string().required(),
  });

  const [loadAppClientEvents, { data: lazyData }] = useLazyQuery<
    GetApplicationClientEvents,
    GetApplicationClientEventsVariables
  >(APPLICATION_CLIENT_EVENTS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      appId: application ? application.id : "",
    },
  });

  const onSubmit = (
    values: FormikValues,
    formikHelpers: FormikHelpers<{
      message: string;
    }>,
  ) => {
    resetFormRef.current = formikHelpers.resetForm;
    dispatch(cleanApiStateAction("FAILURE", [PostApplicationMessage.FAILURE]));
    dispatch(cleanApiStateAction("SUCCESS", [PostApplicationMessage.SUCCESS]));
    dispatch(
      onPostApplicationMessage({
        clientContactIri: currentUser?.["@id"] || "",
        applicationIri: application?.id || "",
        message: values.message,
        clientEnabled: true,
      }),
    );
    setFormState("submitting");
  };

  const onSubmitSuccess = React.useCallback(() => {
    if (typeof resetFormRef.current === "function") {
      resetFormRef.current({ message: "" });
    }
  }, [resetFormRef.current]);
  const onClickTriggerStatusDropdown = () => {
    setOpenStatusDropdown(!openStatusDropdown);
  };
  const onClickDropDownCta = React.useRef((cstat: string) => {
    setOpenStatusDropdown(false);
    setSelectedStatus(cstat);
    if (data.application) {
      eventDispatcher.trigger(EVENT.CurrentStatusChange, {
        appId: data.application.id,
        newStatus: cstat,
      });
      dispatch(
        onEditApplicationClientStatusAction({
          applicationIri: data.application.id,
          clientStatus: cstat,
        }),
      );
    }
  }).current;

  const renderSaralyBar = (slry: { fixedSalary: number; fullPackage: number }) => {
    const fixed = slry.fixedSalary;
    const full = slry.fullPackage;
    const variable = full - fixed;
    const perc = Math.round((fixed * 100) / full);
    const fixedPercent = perc > 80 ? 80 : perc;

    return !!!fixed || !!!full ? (
      <div className={styles.noticeContainer}>Non précisé</div>
    ) : (
      <div className={styles.barContainer}>
        <div className={styles.fixedPart} style={{ flexBasis: `${fixedPercent}%` }}>
          {fixed}k
        </div>
        <div className={styles.variablePart} style={{ flexBasis: `${100 - fixedPercent}%` }}>
          {variable}k
        </div>
      </div>
    );
  };

  const renderMessages = (cMsg: KeyNodeProps[]) => {
    const newsItems: React.ReactNode[] = [];
    cMsg.sort((a, b) => {
      const dA = new Date(a.node.createdAt).getTime();
      const dB = new Date(b.node.createdAt).getTime();
      return dA - dB;
    });
    cMsg.forEach(({ node, type }) => {
      const hour = format(new Date(node.createdAt), "HH:mm", {
        locale: fr,
      });
      switch (type) {
        case "clientStatusEvent":
          const eventItem = node as ApplicationClientStatusEventFragmentProps;
          const colTitle = t({ id: COL_TITLE[eventItem.status] });
          const eventItemvalues = {
            colTitle,
            firstname: talent?.firstname,
            doer: String(eventItem.clientContact && eventItem.clientContact.firstname),
          };
          let eventItemTranslation = t({
            id: "TalentCardModal.sidebar.wording.newsFeed.event.item.null",
            values: eventItemvalues,
          });
          if (eventItem.clientContact) {
            eventItemTranslation =
              currentUser && currentUser["@id"] === (eventItem.clientContact && eventItem.clientContact.id)
                ? t({ id: "TalentCardModal.sidebar.wording.newsFeed.event.item.you", values: eventItemvalues })
                : t({ id: "TalentCardModal.sidebar.wording.newsFeed.event.item", values: eventItemvalues });
          }
          newsItems.push(
            <li key={`${eventItem.id}-${eventItem.status}`} className={styles.newsItem}>
              <div>
                <MarkdownRenderer className={styles.tcText} content={eventItemTranslation} />
              </div>
              <div className={styles.newsHourContainer}>{hour}</div>
            </li>,
          );
          break;
        case "clientMessage":
          const messageItem = node as ApplicationMessageFragmentProps;
          const messageItemValues = {
            doer: messageItem.clientContact
              ? String(messageItem.clientContact.firstname)
              : messageItem.coreUser
              ? String(messageItem.coreUser.firstname)
              : "",
          };

          const messageItemTranslation = messageItem.clientContact
            ? currentUser && currentUser["@id"] === messageItem.clientContact.id
              ? t({ id: "TalentCardModal.sidebar.wording.newsFeed.message.client.item.you", values: messageItemValues })
              : t({ id: "TalentCardModal.sidebar.wording.newsFeed.message.client.item", values: messageItemValues })
            : messageItem.coreUser
            ? t({ id: "TalentCardModal.sidebar.wording.newsFeed.message.coreuser.item", values: messageItemValues })
            : t`TalentCardModal.sidebar.wording.newsFeed.message.item.null`;

          newsItems.push(
            <li key={`${messageItem.id}-${messageItem.createdAt}`} className={styles.newsItem}>
              <div>
                <div>
                  <MarkdownRenderer className={styles.tcText} content={messageItemTranslation} />
                </div>
                <div>
                  <MarkdownRenderer content={messageItem.message} />
                </div>
              </div>
              <div className={styles.newsHourContainer}>{hour}</div>
            </li>,
          );
          break;
      }
    });
    return newsItems;
  };

  React.useEffect(() => {
    if (data) {
      setOffer(data.offer);
      setTalent(data.talent);
      setTalentCard(data.talentCard);
      setApplication(data.application);
      setFormattedSendDate(data.formattedSendDate);
      if (data.application?.id) {
        loadAppClientEvents({
          variables: {
            appId: data.application.id,
          },
        });
      }
    }
  }, [data, loadAppClientEvents]);

  React.useEffect(() => {
    if (lazyData) {
      const keyNodes: {
        [date: string]: KeyNodeProps[];
      } = {};
      const unreadNodes: any[] = [];
      const evEdges = lazyData.application?.applicationClientStatusEvents?.edges || [];
      const msgEdges = lazyData.application?.applicationMessages?.edges || [];
      evEdges.forEach((ev) => {
        if (ev !== null && ev.node !== null && ev.node.status !== APPLICATION_STATUS_PRE_SEND) {
          const nodeDate = new Date(ev.node.createdAt);
          const keyDate = nodeDate.toDateString();
          const keyNode: KeyNodeProps = { type: "clientStatusEvent", node: ev.node };
          if (Array.isArray(keyNodes[keyDate])) {
            keyNodes[keyDate].push(keyNode);
          } else {
            keyNodes[keyDate] = [keyNode];
          }
        }
      });
      msgEdges.forEach((ev) => {
        if (ev !== null && ev.node !== null) {
          const nodeDate = new Date(ev.node.createdAt);
          const keyDate = nodeDate.toDateString();
          const keyNode: KeyNodeProps = {
            type: "clientMessage",
            node: ev.node,
          };
          if (
            ev
            && ev.node
            && ev.node.clientContact?.id !== currentUser?.["@id"]
            && !ev.node.clientRead.includes(currentUser?.["@id"])
          ) {
            unreadNodes.push(ev.node);
          }
          if (Array.isArray(keyNodes[keyDate])) {
            keyNodes[keyDate].push(keyNode);
          } else {
            keyNodes[keyDate] = [keyNode];
          }
        }
      });

      const sorteKeyNodes = Object.entries(keyNodes).sort(
        (a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime(),
      );
      setUnreadMessages(unreadNodes);
      setApplicationMessages(sorteKeyNodes);
    }
  }, [lazyData, currentUser]);

  React.useEffect(() => {
    const errors = apiErrors.filter((e) => [PostApplicationMessage.FAILURE].includes(e.type));
    const successPost = apiSuccess.filter((e) => e.type === PostApplicationMessage.SUCCESS);
    if (formState === "submitting" && errors.length) {
      setFormState("error");
    } else if (formState === "submitting" && successPost.length) {
      setFormState("success");
    } else if (formState === "error") {
      formStateTimeoutRef.current = setTimeout(() => {
        setFormState("initial");
      }, 3000);
    } else if (formState === "success") {
      onSubmitSuccess();
      if (application) {
        loadAppClientEvents({
          variables: {
            appId: application.id,
          },
        });
      }
      formStateTimeoutRef.current = setTimeout(() => {
        setFormState("initial");
      }, 1000);
    }

    return () => {
      if (formStateTimeoutRef.current) {
        clearTimeout(formStateTimeoutRef.current);
      }
    };
  }, [application, formState, apiErrors, apiSuccess, onSubmitSuccess]);

  React.useEffect(() => {
    const arr: JSX.Element[] = [];
    Object.keys(APPLICATION_STATUS).forEach((s, i) => {
      const clientStatus = APPLICATION_STATUS[s as ApplicationStatusKeys];
      arr.push(
        <li key={`dp-option-${clientStatus}-${i}`}>
          <div
            onClick={() => {
              onClickDropDownCta(clientStatus);
            }}
            className={cx(styles.dropdownCta, globalStyles.dropdownCta, {
              [styles.isCurrent]: selectedStatus === clientStatus,
            })}
          >
            <span className={styles.radioBtn}></span>
            {t({ id: transApplicationStatus[clientStatus] })}
          </div>
        </li>,
      );
    });
    setStatusOptions(arr);
  }, [selectedStatus, onClickDropDownCta]);

  React.useEffect(() => {
    if (talentCard && currentUser && isInitialLoad) {
      setIsInitialLoad(false);
      dispatch(
        onPostTalentCardClientEventAction({
          talentCardIri: talentCard.id,
          clientContactIri: currentUser["@id"],
        }),
      );
    }
  }, [dispatch, talentCard, currentUser, isInitialLoad]);

  React.useEffect(() => {
    if (unreadMessages.length && currentUser?.["@id"]) {
      dispatch(onClientReadMessages({ messages: unreadMessages, clientContact: currentUser?.["@id"] }));
    }
  }, [dispatch, unreadMessages]);

  if (!!!talent || !!!talentCard || !!!offer || !!!application) {
    return (
      <div className={errorStyles.errorModal}>
        <div className={errorStyles.errorContainer}>
          <div className={errorStyles.errorTitleContainer}>
            <h2 className={errorStyles.errorTitle}>{t`General.wording.error`}</h2>
          </div>
          {!!!talent ? (
            <p key={"error-notalent"} className={globalStyles.errorText}>
              {t`TalentCardModal.error.noTalent`}
            </p>
          ) : null}
          {!!!talentCard ? (
            <p key={"error-notalentCard"} className={globalStyles.errorText}>
              {t`TalentCardModal.error.noTalentCard`}
            </p>
          ) : null}
          {!!!offer ? (
            <p key={"error-nooffer"} className={globalStyles.errorText}>
              {t`TalentCardModal.error.noOffer`}
            </p>
          ) : null}
          {!!!application ? (
            <p key={"error-noapplication"} className={globalStyles.errorText}>
              {t`TalentCardModal.error.noApplication`}
            </p>
          ) : null}
          <div className={errorStyles.cancelBtnContainer}>
            <LinkCta
              label={t`General.wording.customer.support`}
              href={GmailMailtoFunc({
                to: process.env.REACT_APP_EMAIL_SAV_TECH || "",
              })}
              target="_blank"
              rel="external nofollow noopener noreferrer"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.modal}>
      <div className={styles.talentCardContainer}>
        <div
          className={cx(styles.toggleSideBar, styles.openSideBar)}
          onClick={() => {
            setIsSidebarOpen(true);
          }}
        >
          {t`TalentCardModal.sidebar.toggle.open`}
        </div>
        <div className={styles.tcHeader}>
          <div className={styles.downloadOfferContainer}>
            <div className={styles.downloadTcContainer}>
              {talent && talent.linkedInProfile && talent.linkedInProfile.url ? (
                <LinkCta
                  href={talent.linkedInProfile.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.downloadTcCta}
                  label={"Voir le linkedIn"}
                  download={`${talent.firstname} ${talent.lastname} -- ${offer.name}`}
                />
              ) : null}
            </div>
            <h4 className={styles.offerTitle}>{offer.name}</h4>
          </div>
          <div className={styles.talentProfileContainer}>
            <div className={cx(styles.imgContainer, globalStyles.imgProfileContainer)}>
              <div className={globalStyles.imgContent}>
                <img alt={`${talent.firstname} ${talent.lastname} profil`} {...getUserImage(talent.image)} />
              </div>
            </div>
            <div className={styles.talentInfosContainer}>
              <div className={styles.nameLinkedInContainer}>
                <div className={styles.nameContainer}>
                  <h2 className={styles.tcTitle}>
                    {talent.firstname} {talent.lastname}
                  </h2>
                </div>
                <div className={styles.linkedInLinkContainer}>
                  {talent.linkedInProfile?.url ? (
                    <a href={talent.linkedInProfile.url} target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </a>
                  ) : null}
                </div>
              </div>
              <div>
                <a
                  href={GmailMailtoFunc({
                    to: talent.email,
                  })}
                  target="_blank"
                  rel="external nofollow noopener noreferrer"
                  className={styles.talentInfo}
                >
                  {talent.email}
                </a>
              </div>
              <div>
                <a
                  href={`tel:${talent.phone}`}
                  target="_blank"
                  rel="external nofollow noopener noreferrer"
                  className={styles.talentInfo}
                >
                  {talent.phone}
                </a>
              </div>
            </div>
            <div className={styles.salaryAvailabilityContainer}>
              <div>
                <h3 className={styles.tcTitle}>{t`TalentCardModal.salary.title`}</h3>
                <div>{renderSaralyBar(talentCard.salary)}</div>
              </div>
              <div>
                {talentCard.availability ? (
                  <>
                    <h3 className={styles.tcTitle}>{t`TalentCardModal.availability.title`}</h3>
                    <div className={styles.noticeContainer}>
                      {t({ id: transAvailability[talentCard.availability] })}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className={cx(styles.tcContent, globalStyles.hideScrollBar)}>
          <div className={styles.tcInnerContent}>
            {talentCard.description ? (
              <div key="tc-description-part" className={styles.tcPart}>
                <h3 className={styles.tcTitle}>{t`TalentCardModal.description.title`}</h3>
                <MarkdownRenderer className={styles.tcText} content={talentCard.description} />
              </div>
            ) : null}
            {talentCard.bridges ? (
              <div key="tc-bridges-part" className={styles.tcPart}>
                <h3 className={styles.tcTitle}>{t`TalentCardModal.bridges.title`}</h3>
                <MarkdownRenderer className={styles.tcText} content={talentCard.bridges} />
              </div>
            ) : null}
            {talentCard.positions ? (
              <div key="tc-positions-part" className={styles.tcPart}>
                <h3 className={styles.tcTitle}>{t`TalentCardModal.positions.title`}</h3>
                <MarkdownRenderer className={styles.tcText} content={talentCard.positions} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div
        className={cx(styles.sideBarContainer, {
          [styles.isOpen]: isSidebarOpen,
        })}
      >
        <div className={styles.sideBarContent}>
          <div>
            <div
              className={styles.toggleSideBar}
              onClick={() => {
                setIsSidebarOpen(false);
              }}
            >
              {t`TalentCardModal.sidebar.toggle.close`}
            </div>
            <div className={styles.iconOptionContainer}>
              <div className={cx(styles.halfCircleContainer, styles.iconContainer)}>
                <span className={styles.halfCircle}></span>
              </div>
              <div className={styles.sideBarOptionName}>{t`TalentCardModal.sidebar.wording.status`}</div>
              <div
                className={cx(
                  styles.dropdownTrigger,
                  globalStyles.dropdownTrigger,
                  globalStyles.triggerOnClick,
                  { [globalStyles.open]: openStatusDropdown },
                  { [styles.open]: openStatusDropdown },
                )}
              >
                <span className={styles.switchCta} onClick={onClickTriggerStatusDropdown}>
                  <IoIosArrowDown />{" "}
                  <span className={styles.currentStatusDropdown}>
                    {application.clientStatus ? t({ id: transApplicationStatus[selectedStatus] }) : null}
                  </span>
                </span>
                <div className={cx(styles.dropdownContainer, globalStyles.dropdownContainer)}>
                  <ul className={cx(styles.dropdownList, globalStyles.dropdownList)}>{statusOptions}</ul>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.newsFeedContainer}>
            <div className={styles.newsFeed}>
              <div className={styles.newsFeedName}>{t`TalentCardModal.sidebar.wording.newsFeed.event`}</div>
              <div className={styles.newsFeedListContainer}>
                <ul className={styles.newsFeedList}>
                  {!!formattedSendDate ? (
                    <li key={`newsfeed-sendDate-item-${formattedSendDate}`} className={styles.sentNewsItem}>
                      <MarkdownRenderer
                        className={styles.tcText}
                        content={t({
                          id: "TalentCardModal.sidebar.wording.newsFeed.event.item.sent",
                          values: { formattedSendDate },
                        })}
                      />
                    </li>
                  ) : null}
                  {!!applicationMessages
                    ? applicationMessages.map((am, i) => {
                        const date = format(new Date(am[0]), "EEEE dd MMMM y", {
                          locale: fr,
                        });
                        return (
                          <li key={`newsFeed-container-${am[0]}-${i}`}>
                            <div className={styles.newsFeedDay}>{date}</div>
                            <div>
                              <ul>{renderMessages(am[1])}</ul>
                            </div>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
              <div>
                <Formik onSubmit={onSubmit} initialValues={{ message: "" }} validationSchema={validationSchema}>
                  {() => (
                    <Form>
                      <div className={styles.messageFormContent}>
                        <div>
                          <Field
                            component={Textarea}
                            required={true}
                            name="message"
                            showErrorMessage={false}
                            className={styles.messageTextarea}
                            rows={4}
                            placeholder="Écrivez une note..."
                          />
                        </div>
                        <div className={cx(styles.messageButtonsContainer)}>
                          <div>
                            <SubmitCta state={formState} labels={{ submitting: "Envoyer", success: "Envoyé" }} />
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
