import { t } from "@lingui/macro";
import * as Yup from "yup";

Yup.setLocale({
  array: {
    min: ({ min }): string => t({ id: "yup.array.min", values: { min } }),
  },
  mixed: {
    required: (): string => t`yup.mixed.required`,
  },
  number: {
    max: ({ max }): string => t({ id: "yup.number.max", values: { max } }),
    min: ({ min }): string => t({ id: "yup.number.min", values: { min } }),
  },
  string: {
    email: ({ value }): string => t({ id: "yup.string.email", values: { value } }),
    url: ({ value }): string => t({ id: "yup.string.url", values: { value } }),
  },
});

export default Yup;
