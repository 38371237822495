import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Form, Formik, FormikValues } from "formik";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";
import { ApiErrorsMessages } from "../../../components/ApiErrorsMessages";

import { Input } from "../../../components/Form/Input";
import { LabelsProps, SubmitCta, SubmitCtaState } from "../../../components/Form/SubmitCta";
import { Logo } from "../../../components/Logos";
import { getParams } from "../../../helpers/dom";
import Yup from "../../../helpers/Yup";
import { StateProps } from "../../../interfaces/state";
import { routes } from "../../../routes";
import {
  cleanApiStateAction,
  onChangePasswordAction,
  onForgotPasswordAction,
} from "../../../services/api/rest/authentication/actions";

import formStyles from "../../../styles/shared/form.module.scss";
import loginStyles from "../../../styles/shared/login.module.scss";

export const ForgotPasswordPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const errorStateTimeoutRef = React.useRef<NodeJS.Timer | null>(null);
  const errors = useSelector((state: StateProps) => state.apiErrors);
  const success = useSelector((state: StateProps) => state.apiSuccess);
  const auth = useSelector((state: StateProps) => state.auth);
  const paramsObj = getParams(location, ["email", "ft"]);
  const submitLabels: LabelsProps = {
    initial: paramsObj.ft ? "Changer mon mot de passe" : t`Auth.ForgotPasswordPage.submit.text`,
    submitting: "Demande en cours...",
    success: paramsObj.ft
      ? "Votre mot de passe a bien été modifié"
      : "Un email avec les instructions vous a été envoyé !",
  };

  const [formState, setFormState] = React.useState<SubmitCtaState>("initial");
  const onFormSubmit = (values: FormikValues) => {
    setFormState("submitting");
    dispatch(cleanApiStateAction("FAILURE"));
    dispatch(cleanApiStateAction("SUCCESS"));
    if (paramsObj.ft) {
      dispatch(
        onChangePasswordAction({
          password: values.password,
          forgot_token: paramsObj.ft,
        }),
      );
    } else {
      dispatch(onForgotPasswordAction(values.email));
    }
  };

  React.useEffect(() => {
    if (formState === "submitting" && errors.length) {
      setFormState("error");
    } else if (formState === "submitting" && success.length) {
      setFormState("success");
    } else if (formState === "error") {
      errorStateTimeoutRef.current = setTimeout(() => {
        setFormState("initial");
      }, 3000);
    }

    return () => {
      if (errorStateTimeoutRef.current) {
        clearTimeout(errorStateTimeoutRef.current);
      }
    };
  }, [formState, errors, success]);

  if (auth.isAuthed) {
    return <Navigate to={routes.app.home} replace />;
  }

  return (
    <>
      <div className={loginStyles.loginWrapper}>
        <div className={loginStyles.loginContainer}>
          <Logo className={loginStyles.logoContainer} />
          <div className={loginStyles.formContainer}>
            {errors.length ? (
              <div key={"login-errors-container"}>
                <ApiErrorsMessages errors={errors} />
              </div>
            ) : null}
            <div className={loginStyles.formTitleContainer}>
              <h2 className={loginStyles.formTitle}>{t`Auth.ForgotPasswordPage.title`}</h2>
            </div>
            <div>
              {paramsObj.ft ? (
                <ChangePasswordForm onSubmit={onFormSubmit} formState={formState} submitLabels={submitLabels} />
              ) : (
                <ForgotPasswordForm onSubmit={onFormSubmit} formState={formState} submitLabels={submitLabels} />
              )}
            </div>
          </div>
          <div className={loginStyles.subFormLinkContainer}>
            <Link to={routes.auth.login} className={loginStyles.subFormLink}>
              {t`Auth.subLink.login`}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

interface FormProps {
  onSubmit: (values: FormikValues) => void;
  initEmail?: string;
  formState: SubmitCtaState;
  submitLabels: LabelsProps;
}

const ForgotPasswordForm: React.FC<FormProps> = (props: FormProps) => {
  const { onSubmit, initEmail = "", formState = "initial", submitLabels } = props;
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        email: initEmail,
      }}
      validationSchema={validationSchema}
    >
      {() => (
        <Form>
          <>
            <div className={formStyles.formRow}>
              <Field component={Input} type="email" name="email" label="Votre email" required={true} />
            </div>
            <div className={cx(formStyles.formRow, formStyles.submitButtonContainer)}>
              <SubmitCta state={formState} labels={submitLabels} />
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};
const ChangePasswordForm: React.FC<FormProps> = (props: FormProps) => {
  const { onSubmit, formState, submitLabels } = props;
  const validationSchema = Yup.object().shape({
    password: Yup.string().required(),
    passwordConfirmation: Yup.string().oneOf([Yup.ref("password"), ""], t`yup.mixed.passwordConfirmation`),
  });
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        password: "",
        passwordConfirmation: "",
      }}
      validationSchema={validationSchema}
    >
      {() => (
        <Form>
          <>
            <div className={formStyles.formRow}>
              <Field
                component={Input}
                type="password"
                name="password"
                label="Votre nouveau mot de passe"
                required={true}
              />
            </div>
            <div className={formStyles.formRow}>
              <Field
                component={Input}
                type="password"
                name="passwordConfirmation"
                label="Confirmez votre mot de passe"
                required={true}
              />
            </div>
            <div className={cx(formStyles.formRow, formStyles.submitButtonContainer)}>
              <SubmitCta state={formState} labels={submitLabels} />
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};
