import { ActionType } from "../interfaces/api/actionType";

export const EditClientContact: ActionType = {
  FAILURE: "EDIT_CLIENT_CONTACT_FAILURE",
  REQUEST: "EDIT_CLIENT_CONTACT_REQUEST",
  SUCCESS: "EDIT_CLIENT_CONTACT_SUCCESS",
};

export const GetCurrentUser: ActionType = {
  FAILURE: "GET_CURRENT_USER_FAILURE",
  REQUEST: "GET_CURRENT_USER_REQUEST",
  SUCCESS: "GET_CURRENT_USER_SUCCESS",
};
