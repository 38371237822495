import { AxiosResponse } from "axios";
import client from "../client";
import { LoginActionProps, LoginElinoiActionProps } from "./actions";

export const login = (req: LoginActionProps | LoginElinoiActionProps): Promise<AxiosResponse> =>
  client.post("/api/v2/client/auth/login", req, { withCredentials: true });

export const refresh = (refreshToken: string): Promise<AxiosResponse> =>
  client.post("/api/v2/client/auth/refresh", { refresh_token: refreshToken }, { withCredentials: true });

export const logout = (): Promise<AxiosResponse> => client.post("/api/v2/client/auth/logout");

export function getCurrentUser(): Promise<AxiosResponse> {
  return client.get("/api/v2/client_contacts/current");
}

export function forgotPassword(email: string): Promise<AxiosResponse> {
  return client.post("/api/v2/client/forgotPassword", { email });
}
export function changePassword(values: { forgot_token: string; password: string }): Promise<AxiosResponse> {
  return client.post("/api/v2/client/changePassword", values);
}
