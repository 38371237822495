import { t } from "@lingui/macro";
import { GoogleLogin } from "@react-oauth/google";
import cx from "classnames";
import { Field, Form, Formik } from "formik";
import { parse } from "querystring-es3";
import * as React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";

import { ApiErrorsMessages } from "../../../components/ApiErrorsMessages";
import { Input } from "../../../components/Form/Input";
import { SubmitCtaState, SubmitCta } from "../../../components/Form/SubmitCta";
import { Logo } from "../../../components/Logos";
import Yup from "../../../helpers/Yup";
import { StateProps } from "../../../interfaces/state";
import { routes } from "../../../routes";
import {
  onGoogleLoginAction,
  onGoogleFailureAction,
  onElinoiLoginAction,
  cleanApiStateAction,
} from "../../../services/api/rest/authentication/actions";
import formStyles from "../../../styles/shared/form.module.scss";
import loginStyles from "../../../styles/shared/login.module.scss";

export const LoginPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [formState, setFormState] = React.useState<SubmitCtaState>("initial");
  const errorStateTimeoutRef = React.useRef<NodeJS.Timer | null>(null);
  const onGoogleLogin = (response: any) => {
    dispatch(cleanApiStateAction("FAILURE"));
    dispatch(onGoogleLoginAction(response, parse(location.search.substring(1))));
  };
  const onGoogleFailure = () => dispatch(onGoogleFailureAction());

  const errors = useSelector((state: StateProps) => state.apiErrors);
  const auth = useSelector((state: StateProps) => state.auth);

  const onElinoiLogin = (data: { email: string; password: string }) => {
    dispatch(cleanApiStateAction("FAILURE"));
    dispatch(
      onElinoiLoginAction(data.email, data.password, {
        ...parse(location.search.substring(1)),
      }),
    );
    setFormState("submitting");
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  React.useEffect(() => {
    if (formState === "submitting" && errors.length) {
      setFormState("error");
    } else if (formState === "error") {
      errorStateTimeoutRef.current = setTimeout(() => {
        setFormState("initial");
      }, 3000);
    }

    return () => {
      if (errorStateTimeoutRef.current) {
        clearTimeout(errorStateTimeoutRef.current);
      }
    };
  }, [formState, errors]);

  if (auth.isAuthed) {
    return <Navigate to={routes.app.home} replace />;
  }
  return (
    <>
      <div className={loginStyles.loginWrapper}>
        <div className={loginStyles.loginContainer}>
          <Logo className={loginStyles.logoContainer} />
          <div className={loginStyles.formContainer}>
            <div className={loginStyles.formTitleContainer}>
              <h2 className={loginStyles.formTitle}>{t`Auth.LoginPage.title`}</h2>
            </div>
            <div>
              {errors.length ? (
                <div key={"login-errors-container"}>
                  <ApiErrorsMessages errors={errors} />
                </div>
              ) : null}
              <div className={loginStyles.rsLoginContainer}>
                <GoogleLogin onSuccess={onGoogleLogin} onError={onGoogleFailure} useOneTap />
              </div>
            </div>
            <div className={loginStyles.loginMethodeSeparator}>{t`Auth.MethodeSeparator`}</div>
            <div>
              <Formik
                onSubmit={onElinoiLogin}
                initialValues={{ email: "", password: "" }}
                validationSchema={validationSchema}
              >
                {() => (
                  <Form>
                    <div className={formStyles.formRow}>
                      <Field component={Input} type="email" name="email" label="Votre email" required={true} />
                    </div>
                    <div className={formStyles.formRow}>
                      <Field
                        component={Input}
                        type="password"
                        name="password"
                        id="password"
                        label="Votre mot de passe"
                        required={true}
                        autoComplete="current-password"
                      />
                    </div>
                    <div className={cx(formStyles.formRow, formStyles.submitButtonContainer)}>
                      <SubmitCta state={formState} />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className={loginStyles.subFormLinkContainer}>
            <Link to={routes.auth.forgotPassword} className={loginStyles.subFormLink}>
              {t`Auth.sublink.forgotPassword`}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
