import { gql } from "@apollo/client";
import { t } from "@lingui/macro";
import cx from "classnames";
import { formatDistance } from "date-fns";
import { fr } from "date-fns/locale";
import marked from "marked";
import { MiniSignalBinding } from "mini-signals";
import * as React from "react";
import { BsCheckLg } from "react-icons/bs";
import { generatePath, Link } from "react-router-dom";
import { APPLICATION_STATUS, EVENT } from "../../helpers/constant";
import { eventDispatcher } from "../../helpers/eventDispatcher";
import { GmailMailto } from "../../helpers/gmailto";
import { getUserImage } from "../../helpers/images";
import { OFFER_STATUS_ACTIVE, OFFER_STATUS_STANDBY } from "../../interfaces/resources/offer";
import { routes } from "../../routes";
import { CardOfferFragmentProps } from "../../services/api/graphql/types";

import global from "../../styles/global.module.scss";
import { ELINOI_SLUG } from "../Header";
import styles from "./index.module.scss";

interface Props {
  offer: CardOfferFragmentProps;
  showOfferName?: boolean;
  showStatusSwitch?: boolean;
  disableMaxWidth?: boolean;
}
// REMOVE
//  company {
//     slug
//   }
// WHEN MARKETPLACE IS OPEN
export const CardOfferFragment = gql`
  fragment CardOfferFragmentProps on Offer {
    id
    title
    slug
    status
    clientRequest
    isMarketplace
    company {
      slug
    }
    coreResponsibility {
      slug
      value
    }
    newTalentsLength: applications(clientStatus: "${APPLICATION_STATUS.NEW}") {
      totalCount
    }
    processTalentsLength: applications(clientStatus_list: ["${APPLICATION_STATUS.INTERVIEWS}", "${APPLICATION_STATUS.CONTACTED}", "${APPLICATION_STATUS.NEED_TO_REFUSE}"]) {
      totalCount
    }
    offerStatusEvents(last: 1) {
      edges {
        node {
          status
          createdAt
        }
      }
    }
    owners {
      edges {
        node {
          id
          firstname
          lastname
          email
          phone
          image
        }
      }
    }
  }
`;

export const CardOffer: React.FC<Props> = (props: Props) => {
  const { disableMaxWidth = false, offer } = props;
  const requestSubmittedRef = React.useRef<MiniSignalBinding>();
  const [cardState, setCardState] = React.useState<boolean>(offer.clientRequest);
  const [isInactive] = React.useState<boolean>(
    !!!(offer.status && [OFFER_STATUS_ACTIVE, OFFER_STATUS_STANDBY].includes(offer.status)),
  );
  const offerPath = generatePath(routes.app.offer, { slug: offer.slug });
  const onClickActionCta = () => {
    eventDispatcher.trigger(EVENT.ModalOpen, {
      type: "OfferModal",
      data: {
        offer,
        formType: isInactive ? "activate" : "desactivate",
      },
    });
  };
  const [statusTime, setstatusTime] = React.useState<string | null>(null);
  const onRequestFormSubmitted = React.useCallback(
    (prms: { offer: CardOfferFragmentProps | undefined }) => {
      if (prms.offer && prms.offer.id === offer.id) {
        setCardState(true);
      }
      return false;
    },
    [offer.id],
  );

  React.useEffect(() => {
    requestSubmittedRef.current = eventDispatcher.on(EVENT.RequestFormSubmitted, onRequestFormSubmitted);

    return () => {
      if (requestSubmittedRef.current) {
        eventDispatcher.off(EVENT.RequestFormSubmitted, requestSubmittedRef.current);
      }
    };
  }, [onRequestFormSubmitted]);

  React.useEffect(() => {
    if (offer.offerStatusEvents?.edges && offer.offerStatusEvents.edges[0]?.node) {
      const time = offer.offerStatusEvents.edges[0].node.createdAt;
      const fromToday = formatDistance(new Date(time), new Date(), {
        locale: fr,
      });
      setstatusTime(fromToday);
    }
  }, [offer]);

  return !!offer ? (
    <>
      <div
        className={cx(styles.card, {
          [styles.withMaxWidth]: !disableMaxWidth,
          [styles.isInactive]: isInactive,
          [styles.tempStatus]: cardState,
        })}
      >
        <div className={styles.cardContent}>
          <div className={styles.leftSide}>
            <div className={styles.headerCardContainer}>
              <Link to={offerPath}>
                <div className={styles.offerNameContainer}>
                  <h3 className={styles.offerName}>{offer.title}</h3>
                </div>
              </Link>
              {/*
                REMOVE
                offer.company.slug === ELINOI_SLUG
                WHEN MARKETPLACE IS OPEN
              */}
              {offer.isMarketplace && offer.company.slug === ELINOI_SLUG ? (
                <div className={styles.marketplaceAccess}>
                  {t`CardOffer.marketplace.access`} <BsCheckLg />
                </div>
              ) : null}
              {cardState ? (
                <span key="request-txt" className={styles.requestProcessingText}>
                  {t`CardOffer.wording.request.state`}
                </span>
              ) : null}
            </div>
            <div className={styles.amProfileContainer}>
              <div className={styles.amHeader}>
                {offer.owners?.edges && offer.owners.edges.length > 0 ? (
                  <>
                    <div className={cx(styles.imgContainer, global.imgProfileContainer)}>
                      <img
                        alt={`${offer.owners?.edges?.[0]?.node?.firstname} ${offer.owners?.edges?.[0]?.node?.lastname}`}
                        {...getUserImage(offer.owners?.edges?.[0]?.node?.image)}
                      />
                    </div>
                    <div>
                      <span className={styles.amText}>{t`CardOffer.wording.owner`}</span>
                    </div>
                  </>
                ) : null}
              </div>
              <div className={styles.amInfos}>
                {offer.owners?.edges && offer.owners.edges.length > 0 ? (
                  <>
                    <div className={styles.halfCircleContainer}>
                      <span className={styles.halfCircle}></span>
                    </div>
                    <div className={styles.amNameContainer}>
                      <h4 className={styles.amName}>
                        {offer.owners?.edges?.[0]?.node?.firstname} {offer.owners?.edges?.[0]?.node?.lastname}
                      </h4>
                    </div>
                    <div>
                      <address className={styles.amAddress}>
                        <span>
                          {offer.owners?.edges?.[0]?.node?.email ? (
                            <GmailMailto to={offer.owners?.edges?.[0]?.node?.email} />
                          ) : null}
                        </span>
                        <span className={styles.separator}></span>
                        <span>
                          <a
                            href={`tel:${offer.owners?.edges?.[0]?.node?.phone}`}
                            target="_blank"
                            rel="external nofollow noopener noreferrer"
                          >
                            {offer.owners?.edges?.[0]?.node?.phone}
                          </a>
                        </span>
                      </address>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.rightSide}>
            <Link to={offerPath}>
              <div className={styles.processInfosContainer}>
                <div
                  className={cx(styles.nbNewProcessWordingBlock, {
                    [styles.hasNewTalent]: offer.newTalentsLength?.totalCount || false,
                  })}
                >
                  <div className={styles.nbNewProcessWording}>
                    <span className={styles.nb}>{offer.newTalentsLength?.totalCount || 0}</span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: marked(t`CardOffer.wording.newTalents`),
                      }}
                    ></span>
                  </div>
                </div>
                <div className={styles.nbNewProcessWordingBlock}>
                  <div className={styles.nbNewProcessWording}>
                    <span className={styles.nb}>{offer.processTalentsLength?.totalCount || 0}</span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: marked(t`CardOffer.wording.processTalents`),
                      }}
                    ></span>
                  </div>
                </div>
              </div>
            </Link>
            <div className={styles.activeDateContainer}>
              {statusTime
                ? isInactive
                  ? t({
                      id: "CardOffer.wording.inactiveTime",
                      values: { statusTime },
                    })
                  : t({
                      id: "CardOffer.wording.activeTime",
                      values: { statusTime },
                    })
                : null}
            </div>
          </div>
        </div>
        <div className={styles.dotsWrapper}>
          <div className={cx(styles.dotsContainer, styles.dropdownTrigger, global.dropdownTrigger)}>
            <div>
              <span className={cx(styles.dot, styles.dot1)}></span>
              <span className={cx(styles.dot, styles.dot2)}></span>
              <span className={cx(styles.dot, styles.dot3)}></span>
            </div>
            <div className={cx(styles.dropdownContainer, global.dropdownContainer)}>
              <ul className={cx(styles.dropdownList, global.dropdownList)}>
                <li>
                  <span className={cx(styles.dropdownCta, global.dropdownCta)} onClick={onClickActionCta}>
                    {isInactive ? t`CardOffer.dropdown.activate` : t`CardOffer.dropdown.desactivate`}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};
