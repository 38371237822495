import { t } from "@lingui/macro";

export const transApplicationStatus: { [key: string]: string } = {
  client_new: t`Constant.application.status.client_new`,
  client_contacted: t`Constant.application.status.client_contacted`,
  client_interviews: t`Constant.application.status.client_interviews`,
  client_hired: t`Constant.application.status.client_hired`,
  client_need_to_refused: t`Constant.application.status.client_need_to_refused`,
  client_refused: t`Constant.application.status.client_refused`,
};
