export function generateIriFromId(resource: string, id: number): string {
  return String("/api/v2/" + resource + "/" + id);
}

export function extractPageFromIri(resource: string, iri: string): string {
  return String(`/${resource}/${extractIdFromIri(iri)}`);
}

export function extractNextPageNumberFromIri(iri: string): number {
  return Number(iri.split("=").slice(-1)[0]);
}

export function extractIdFromIri(iri: string): number {
  return Number(iri.split("/").slice(-1)[0]);
}

export function extractPageNumberFromIri(iri: string): number {
  return Number(iri.split("=").slice(-1)[0]);
}
export const getStringValue = (value: any, field: string): string | undefined =>
  value ? ("string" === typeof value ? value : value[field]) : undefined;
