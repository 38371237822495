import cx from "classnames";
import { FieldProps } from "formik";
import React, { FC, ReactNode, useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import formStyles from "../../../styles/shared/form.module.scss";
import { ErrorMessage } from "../ErrorMessage";

import styles from "./index.module.scss";

interface Props extends FieldProps {
  id?: string;
  label: ReactNode;
  placeholder?: string;
  required?: boolean;
  showErrorMessage?: boolean;
  showVisibilitySwitch?: boolean;
  type?: string;
  autoComplete?: string;
  readOnly?: boolean;
}

export const Input: FC<Props> = (props: Props) => {
  const {
    field,
    id,
    label,
    placeholder,
    required = false,
    showErrorMessage = true,
    showVisibilitySwitch = true,
    type = "text",
    autoComplete = "on",
    readOnly = false,
  } = props;
  const [toText, setToText] = useState(false);
  const onBlurInput = (e: React.FocusEvent) => {
    field.onBlur(e);
  };
  const onChangeInput = (e: React.SyntheticEvent) => {
    field.onChange(e);
  };
  const onClickSwitch = () => {
    setToText(!toText);
  };
  const fieldId = id ? id : field.name;
  return (
    <>
      <label className={formStyles.label} htmlFor={fieldId}>
        {label} {required ? <span className={formStyles.required}>*</span> : null}
      </label>
      <div className={cx(formStyles.inputContainer, styles.inputContainer)}>
        <input
          type={toText ? "text" : type}
          id={fieldId}
          name={field.name}
          value={field.value}
          className={cx(styles.input, {
            [styles.password]: type === "password",
          })}
          placeholder={placeholder}
          required={required}
          onChange={onChangeInput}
          onBlur={onBlurInput}
          readOnly={readOnly}
          autoComplete={autoComplete}
        />
        {type === "password" && showVisibilitySwitch ? (
          <span className={styles.switch} onClick={onClickSwitch}>
            {toText ? <AiFillEye /> : <AiFillEyeInvisible />}
          </span>
        ) : null}
      </div>
      {showErrorMessage && <ErrorMessage name={field.name} />}
    </>
  );
};
