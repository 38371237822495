import { AxiosResponse } from "axios";
import client from "./client";

export function editApplicationClientStatus(values: {
  applicationIri: string;
  clientStatus: string;
}): Promise<AxiosResponse> {
  return client.put(`${values.applicationIri}/clientStatus`, {
    clientStatus: values.clientStatus,
  });
}

export function postClientRequestOfferChange(values: {
  message: string;
  clientContact: string;
  coreUser: string;
  offer: string;
}): Promise<AxiosResponse> {
  return client.post("/api/v2/client_contact_offer_messages", { ...values });
}

export function putOfferClientRequest(values: { clientRequest: boolean; offerIri: string }): Promise<AxiosResponse> {
  return client.put(values.offerIri, { ...values });
}

export function postTalentCardClientEvent(values: {
  talentCardIri: string;
  clientContactIri: string;
}): Promise<AxiosResponse> {
  return client.post("/api/v2/talent_card_client_events", {
    talentCard: values.talentCardIri,
    clientContact: values.clientContactIri,
  });
}

export function postApplicationMessage(values: {
  message: string;
  clientContact: string;
  application: string;
  clientEnabled: boolean;
}): Promise<AxiosResponse> {
  return client.post("/api/v2/application_messages", { ...values });
}

export function putApplicationMessage(values: {
  applicationMessageIri: string;
  clientRead: string[];
}): Promise<AxiosResponse> {
  return client.put(values.applicationMessageIri, { clientRead: values.clientRead });
}

export function postApplication(values: {
  offer: string;
  talent: string;
  status: string;
  clientContact: string;
  clientStatus: string;
}): Promise<AxiosResponse> {
  return client.post("/api/v2/applications", { ...values });
}

export function editOffer(values: { offer: string; discardedTalents: number[] }) {
  if (values.offer) {
    return client.put(`${values.offer}`, { discardedTalents: values.discardedTalents });
  }
  return;
}
