import { gql, useLazyQuery } from "@apollo/client";
import { t } from "@lingui/macro";
import anime from "animejs";
import cx from "classnames";
import * as React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { $cubic1 } from "../../helpers/easing";

import { usePrevious } from "../../helpers/hook";
import { getUserImage } from "../../helpers/images";
import { StateProps } from "../../interfaces/state";
import { routes } from "../../routes";
import { GetUserInfos, GetUserInfosVariables } from "../../services/api/graphql/types";
import { logoutAction, switchOffAction, getCurrentUserAction } from "../../services/api/rest/authentication/actions";

import globalStyles from "../../styles/global.module.scss";
import { LogoSmall } from "../Logos";

import styles from "./index.module.scss";

export const USER_INFO_QUERY = gql`
  query GetUserInfos($userId: ID!) {
    clientContact(id: $userId) {
      id
      ...UserInfosFragmentProps
    }
  }
  fragment UserInfosFragmentProps on ClientContact {
    firstname
    lastname
    image
    contact {
      company {
        slug
      }
    }
  }
`;

// REMOVE WHEN MARKETPLACE IS OPEN
export const ELINOI_SLUG = "elinoi";

export const Header: React.FC = () => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutAction());
  };
  const switchOff = () => dispatch(switchOffAction());

  const currentUser = useSelector((state: StateProps) => state.currentUser);
  const isAuthed = useSelector((state: StateProps) => state.auth.isAuthed);
  const [isMobileOpen, setIsMobileOpen] = React.useState<boolean>(false);
  const [userId, setUserId] = React.useState<string>("");
  const [loadUser, { called, data }] = useLazyQuery<GetUserInfos, GetUserInfosVariables>(USER_INFO_QUERY, {
    variables: { userId },
  });
  const isSwitchOn = useSelector((state: StateProps) => state.isSwitchOn);
  const $headerContent = React.useRef<HTMLDivElement>(null);
  const prevIsSwitchOn = usePrevious(isSwitchOn);
  const showHeader = React.useRef(() => {
    anime({
      targets: $headerContent.current,
      translateY: ["-110%", "0%"],
      duration: 600,
      easing: $cubic1,
    });
  }).current;
  const hideHeader = React.useRef(() => {
    setIsMobileOpen(false);
    anime({
      targets: $headerContent.current,
      translateY: "-110%",
      duration: 600,
      easing: $cubic1,
    });
  }).current;

  const onClickNavCta = () => {
    setIsMobileOpen(false);
  };

  React.useEffect(() => {
    const getCurrentUser = () => dispatch(getCurrentUserAction());

    if (prevIsSwitchOn !== isSwitchOn) {
      getCurrentUser();
    }
  }, [prevIsSwitchOn, isSwitchOn, dispatch]);

  React.useEffect(() => {
    if (!!data && isAuthed) {
      showHeader();
    } else {
      hideHeader();
    }
  }, [data, showHeader, hideHeader, isAuthed]);

  React.useEffect(() => {
    if (!!currentUser && currentUser["@id"]) {
      setUserId(currentUser["@id"]);
    }
  }, [currentUser]);

  React.useEffect(() => {
    if (!!userId && !called) {
      loadUser({
        variables: {
          userId,
        },
      });
    }
  }, [userId, called, loadUser]);

  return (
    <header className={styles.header}>
      <div className={styles.headerContent} ref={$headerContent} style={{ transform: "translateY(-110%)" }}>
        <div className={styles.logoContainer}>
          <div className={styles.logo} aria-label="accueil">
            <Link to={routes.app.home}>
              <LogoSmall />
            </Link>
          </div>
        </div>
        <div className={cx(styles.navContainer, styles.hideOnMobile)}>
          <ul className={styles.nav}>
            <li>
              <NavLink
                to={routes.app.offers}
                className={({ isActive }) => (isActive ? cx(styles.navLink, styles.active) : styles.navLink)}
              >
                {t`AppHeader.nav.offers`}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={routes.app.talents}
                className={({ isActive }) => (isActive ? cx(styles.navLink, styles.active) : styles.navLink)}
              >
                {t`AppHeader.nav.talents`}
              </NavLink>
            </li>
            {/* REMOVE CONDITION WHEN MARKETPLACE IS OPEN */}
            {data && data.clientContact?.contact?.company?.slug === ELINOI_SLUG ? (
              <li>
                <NavLink
                  to={routes.app.marketplace}
                  className={({ isActive }) => (isActive ? cx(styles.navLink, styles.active) : styles.navLink)}
                >
                  {t`AppHeader.nav.marketplace`}
                </NavLink>
              </li>
            ) : null}
          </ul>
        </div>
        <div className={styles.hideOnMobile}>
          <div className={styles.profileContainer}>
            {currentUser ? (
              <>
                <div>
                  <span>
                    {!isSwitchOn
                      ? t({
                          id: "AppHeader.menu.title",
                          values: {
                            firstname: data && data.clientContact?.firstname,
                          },
                        })
                      : t({
                          id: "AppHeader.menu.title.onSwitch",
                          values: {
                            firstname: data && data.clientContact?.firstname,
                          },
                        })}
                  </span>
                </div>
                <div className={cx(styles.picDropdownContainer, globalStyles.dropdownTrigger)}>
                  <div className={cx(globalStyles.imgProfileContainer, styles.picContainer)}>
                    <div className={globalStyles.imgContent}>
                      <img
                        alt={data ? `${data.clientContact?.firstname} ${data.clientContact?.lastname} profil` : ""}
                        {...getUserImage(data ? data.clientContact?.image : null)}
                      />
                    </div>
                  </div>
                  <div className={styles.arrowDownContainer}>
                    <IoIosArrowDown />
                  </div>
                  <div className={globalStyles.dropdownContainer}>
                    <ul className={globalStyles.dropdownList}>
                      <li>
                        {!isSwitchOn ? (
                          <span className={globalStyles.dropdownCta} onClick={logout}>
                            {t`AppHeader.menu.logout`}
                          </span>
                        ) : (
                          <span className={globalStyles.dropdownCta} onClick={switchOff}>
                            {t`AppHeader.menu.switchUserOff`}
                          </span>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className={styles.burgerContainer}>
          <div
            className={cx(styles.burger, { [styles.open]: isMobileOpen })}
            onClick={() => {
              setIsMobileOpen(!isMobileOpen);
            }}
          >
            <span className={cx(styles.line, styles.top)}></span>
            <span className={cx(styles.line, styles.middle)}></span>
            <span className={cx(styles.line, styles.bottom)}></span>
          </div>
        </div>
      </div>

      <div
        className={cx(styles.headerContentMobile, {
          [styles.open]: isMobileOpen,
        })}
      >
        <div className={styles.innerContentMobile}>
          <div className={styles.greetingsContainer}>
            <span>
              {!isSwitchOn
                ? t({
                    id: "AppHeader.menu.title",
                    values: {
                      firstname: data && data.clientContact?.firstname,
                    },
                  })
                : t({
                    id: "AppHeader.menu.title.onSwitch",
                    values: {
                      firstname: data && data.clientContact?.firstname,
                    },
                  })}
            </span>
          </div>
          <div className={styles.navContainer}>
            <ul className={styles.nav}>
              <li>
                <NavLink
                  to={routes.app.offers}
                  className={({ isActive }) => (isActive ? cx(styles.navLink, styles.active) : styles.navLink)}
                  onClick={onClickNavCta}
                >
                  {t`AppHeader.nav.offers`}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={routes.app.talents}
                  className={({ isActive }) => (isActive ? cx(styles.navLink, styles.active) : styles.navLink)}
                  onClick={onClickNavCta}
                >
                  {t`AppHeader.nav.talents`}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className={styles.profileNavContainer}>
            <div className={cx(globalStyles.imgProfileContainer, styles.picContainer)}>
              <div className={globalStyles.imgContent}>
                <img
                  alt={data ? `${data.clientContact?.firstname} ${data.clientContact?.lastname} profil` : ""}
                  {...getUserImage(data ? data.clientContact?.image : null)}
                />
              </div>
            </div>
            <ul>
              <li>
                {!isSwitchOn ? (
                  <span className={globalStyles.dropdownCta} onClick={logout}>
                    {t`AppHeader.menu.logout`}
                  </span>
                ) : (
                  <span className={globalStyles.dropdownCta} onClick={switchOff}>
                    {t`AppHeader.menu.switchUserOff`}
                  </span>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};
