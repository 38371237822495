export const refusedReason = {
  entrepreneurship_xp_required: "entrepreneurship_xp_required",
  grande_ecole_required: "grande_ecole_required",
  management_xp_required: "management_xp_required",
  missing_hardskills: "missing_hardskills",
  no_evolution: "no_evolution",
  salary_too_low: "salary_too_low",
  strategic_profile_required: "strategic_profile_required",
  too_junior: "too_junior",
  too_senior: "too_senior",
  other_no_notification: "other_no_notification",
  other_application_accepted: "other_application_accepted",
  not_searching_job_found: "not_searching_job_found",
};

export type RefusedReason = keyof typeof refusedReason;

export const applicationStatuses = {
  accepted: "accepted",
  applied: "applied",
  awaitingShortlist: "awaiting_shortlist",
  callBooked: "call_booked",
  contacted: "contacted",
  interesting: "interesting",
  interviews: "interviews",
  offer: "offer",
  refusedAuto: "refused_auto",
  refusedElinoi: "refused_elinoi",
  refusedStartup: "refused_startup",
  refusedTalent: "refused_talent",
  shortlistReady: "shortlist_ready",
  shortlisted: "shortlisted",
  toContact: "to_contact",
  toPitch: "to_pitch",
};

export const applicationStatusesPreProcess = [
  applicationStatuses.applied,
  applicationStatuses.callBooked,
  applicationStatuses.contacted,
  applicationStatuses.interesting,
  applicationStatuses.toContact,
  applicationStatuses.toPitch,
  applicationStatuses.awaitingShortlist,
];

export const applicationStatusesDashboardPreProcess = [
  applicationStatuses.applied,
  applicationStatuses.callBooked,
  applicationStatuses.toContact,
  applicationStatuses.toPitch,
  applicationStatuses.awaitingShortlist,
];

export const applicationStatusesInProcess = [
  applicationStatuses.accepted,
  applicationStatuses.shortlistReady,
  applicationStatuses.interviews,
  applicationStatuses.offer,
  applicationStatuses.shortlisted,
];

export const applicationStatusesRefused = [
  applicationStatuses.refusedAuto,
  applicationStatuses.refusedElinoi,
  applicationStatuses.refusedStartup,
  applicationStatuses.refusedTalent,
];

export const applicationLateStage = {
  last: "last_lap",
  beforeLast: "before_last_lap",
};

export const applicationClientStatus = {
  clientPreSend: "client_pre_send",
  clientNew: "client_new",
  clientContacted: "client_contacted",
  clientInterviews: "client_interviews",
  clientHired: "client_hired",
  clientNeedToRefused: "client_need_to_refused",
  clientRefused: "client_refused",
};
