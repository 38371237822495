import { t } from "@lingui/macro";
import * as React from "react";
import Select, { SingleValue } from "react-select";

import { MarketplaceOffersPageFragmentProps } from "../../services/api/graphql/types";

export const defaultStyles: any = {
  control: (base: any) => ({
    ...base,
    "&:hover": {
      backgroundColor: "white",
      border: "1px solid #507bfc",
      borderRadius: "8px",
    },
    backgroundColor: "#f5f7fa",
    border: "1px solid #f5f7fa",
    borderRadius: "8px",
  }),
  menu: (base: any) => ({ ...base, zIndex: 3 }),
  multiValue: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.data.isFixed ? "gray" : "#699bf0",
    border: "1px solid #699bf0",
    borderColor: state.data.isFixed ? "gray" : "#699bf0",
    borderRadius: "8px",
    color: "white",
    fontSize: "14px",
  }),
  multiValueLabel: (base: any, state: any) => ({
    ...base,
    color: "white",
    fontSize: "14px",
    padding: "5px 9px",
    paddingRight: state.data.isFixed ? "9px" : "0px",
  }),
  multiValueRemove: (base: any, state: any) => ({
    ...base,
    "&:hover": {
      backgroundColor: "#699bf0",
      color: "white",
      cursor: "pointer",
    },
    display: state.data.isFixed ? "none" : "flex",
  }),
  singleValue: (base: any) => ({
    ...base,
    color: "#707090",
  }),
};

interface Props {
  offers: MarketplaceOffersPageFragmentProps[];
  loading?: boolean;
  onSelectChange?: (value: MarketplaceOffersPageFragmentProps) => void;
  placeholder?: string;
}

export const OfferSelect: React.FC<Props> = ({ onSelectChange, offers, placeholder, loading = true }: Props) => {
  return (
    <Select
      placeholder={placeholder ?? t`OfferTitleFilter.placeholder`}
      options={offers}
      isSearchable={true}
      isLoading={loading}
      getOptionLabel={(option: MarketplaceOffersPageFragmentProps): string => option.title}
      getOptionValue={(option: MarketplaceOffersPageFragmentProps): string => option.id}
      value={undefined}
      onChange={(value: SingleValue<MarketplaceOffersPageFragmentProps>): void => {
        if (typeof onSelectChange === "function") {
          onSelectChange(value as MarketplaceOffersPageFragmentProps);
        }
      }}
      styles={defaultStyles}
    />
  );
};
