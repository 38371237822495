import { t } from "@lingui/macro";
import * as React from "react";
import { ApiError } from "../../interfaces/api/http";

import { MarkdownRenderer } from "../MarkdownRenderer";
import styles from "./index.module.scss";

interface Props {
  errors: ApiError[];
}

export const ApiErrorsMessages: React.FC<Props> = (props: Props) => {
  const { errors } = props;
  const messages: { [key: string]: string } = {
    noContact: t`api.authentication.error.401.noContact`,
    noClientContact: t`api.authentication.error.401.noClientContact`,
  };
  return (
    <div className={styles.errorContainer}>
      {errors.reduce((prev: JSX.Element[], current: ApiError) => {
        if (current.payload.status === 401 || current.payload.status === 403) {
          return [
            ...prev,
            <MarkdownRenderer
              content={messages[current.payload.message] ?? current.payload.message}
              key={current.payload.message}
            />,
          ];
        }
        return prev;
      }, [])}
    </div>
  );
};
