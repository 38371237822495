import { ActionType } from "../interfaces/api/actionType";

export const ApiAuthenticationLogin: ActionType = {
  FAILURE: "API_AUTHENTICATION_LOGIN_FAILURE",
  REQUEST: "API_AUTHENTICATION_LOGIN_REQUEST",
  SUCCESS: "API_AUTHENTICATION_LOGIN_SUCCESS",
};

export const ApiAuthenticationLogout: ActionType = {
  FAILURE: "API_AUTHENTICATION_LOGOUT_FAILURE",
  REQUEST: "API_AUTHENTICATION_LOGOUT_REQUEST",
  SUCCESS: "API_AUTHENTICATION_LOGOUT_SUCCESS",
};

export const ApiAuthenticationRefresh: ActionType = {
  FAILURE: "API_AUTHENTICATION_REFRESH_FAILURE",
  REQUEST: "API_AUTHENTICATION_REFRESH_REQUEST",
  SUCCESS: "API_AUTHENTICATION_REFRESH_SUCCESS",
};

export const CleanApiState: ActionType = {
  FAILURE: "CLEAN_API_STATE_FAILURE",
  REQUEST: "CLEAN_API_STATE_REQUEST",
  SUCCESS: "CLEAN_API_STATE_SUCCESS",
};

export const GoogleAuthenticationLogin: ActionType = {
  FAILURE: "GOOGLE_AUTHENTICATION_LOGIN_FAILURE",
  REQUEST: "GOOGLE_AUTHENTICATION_LOGIN_REQUEST",
  SUCCESS: "GOOGLE_AUTHENTICATION_LOGIN_SUCCESS",
};

export const GoogleAuthenticationLogout: ActionType = {
  FAILURE: "GOOGLE_AUTHENTICATION_LOGOUT_FAILURE",
  REQUEST: "GOOGLE_AUTHENTICATION_LOGOUT_REQUEST",
  SUCCESS: "GOOGLE_AUTHENTICATION_LOGOUT_SUCCESS",
};

export const LinkedInAuthenticationLogin: ActionType = {
  FAILURE: "LINKEDIN_AUTHENTICATION_LOGIN_FAILURE",
  REQUEST: "LINKEDIN_AUTHENTICATION_LOGIN_REQUEST",
  SUCCESS: "LINKEDIN_AUTHENTICATION_LOGIN_SUCCESS",
};

export const LinkedInAuthenticationLogout: ActionType = {
  FAILURE: "LINKEDIN_AUTHENTICATION_LOGOUT_FAILURE",
  REQUEST: "LINKEDIN_AUTHENTICATION_LOGOUT_REQUEST",
  SUCCESS: "LINKEDIN_AUTHENTICATION_LOGOUT_SUCCESS",
};

export const ElinoiAuthenticationLogin: ActionType = {
  FAILURE: "ELINOI_AUTHENTICATION_LOGIN_FAILURE",
  REQUEST: "ELINOI_AUTHENTICATION_LOGIN_REQUEST",
  SUCCESS: "ELINOI_AUTHENTICATION_LOGIN_SUCCESS",
};

export const ElinoiAuthenticationLogout: ActionType = {
  FAILURE: "ELINOI_AUTHENTICATION_LOGOUT_FAILURE",
  REQUEST: "ELINOI_AUTHENTICATION_LOGOUT_REQUEST",
  SUCCESS: "ELINOI_AUTHENTICATION_LOGOUT_SUCCESS",
};

export const SwitchOn: ActionType = {
  FAILURE: "SWITCH_ON_FAILURE",
  REQUEST: "SWITCH_ON_REQUEST",
  SUCCESS: "SWITCH_ON_SUCCESS",
};

export const SwitchOff = {
  DEFAULT: "SWITCH_OFF_DEFAULT",
};

export const ElinoiForgotPassword: ActionType = {
  FAILURE: "ELINOI_FORGOT_PASSWORD_FAILURE",
  REQUEST: "ELINOI_FORGOT_PASSWORD_REQUEST",
  SUCCESS: "ELINOI_FORGOT_PASSWORD_SUCCESS",
};
export const ElinoiChangePassword: ActionType = {
  FAILURE: "ELINOI_CHANGE_PASSWORD_FAILURE",
  REQUEST: "ELINOI_CHANGE_PASSWORD_REQUEST",
  SUCCESS: "ELINOI_CHANGE_PASSWORD_SUCCESS",
};
