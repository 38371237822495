import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ApiError, ApiRequest, ApiSuccess } from "../interfaces/api/http";

export const usePrevious = <T>(value: T): T | undefined => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const useApiSelector = () => {
  const apiErrors: ApiError[] = useSelector((state: any) => state.apiErrors);
  const apiPendingRequests: ApiRequest[] = useSelector((state: any) => state.apiPendingRequests);
  const apiSuccess: ApiSuccess[] = useSelector((state: any) => state.apiSuccess);

  return { apiErrors, apiPendingRequests, apiSuccess };
};

export const useForceUpdate = (): (() => void) => {
  const [, setState] = useState<Record<string, unknown> | null>(null);
  return () => setState({});
};
