import cx from "classnames";
import React from "react";
import { useInstantSearch } from "react-instantsearch-hooks-web";
import globalStyles from "../../styles/global.module.scss";

export const Refresh = () => {
  const { refresh } = useInstantSearch();
  return (
    <button
      className={cx(globalStyles.cta, globalStyles.aquaCta, globalStyles.inverted)}
      type="button"
      onClick={() => {
        refresh();
      }}
    >
      <svg width="1rem" height="1rem" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <title>Refresh the search results</title>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />
      </svg>
    </button>
  );
};
