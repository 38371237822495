import { AxiosResponse } from "axios";
import { push, RouterRootState } from "connected-react-router";
import { parse } from "querystring-es3";
import { AnyAction } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  ApiAuthenticationLogin,
  ApiAuthenticationLogout,
  ApiAuthenticationRefresh,
  ElinoiAuthenticationLogin,
  ElinoiChangePassword,
  ElinoiForgotPassword,
  GoogleAuthenticationLogin,
  LinkedInAuthenticationLogin,
} from "../../../../actionTypes/authentication";
import { GetCurrentUser } from "../../../../actionTypes/clientContact";
import { ClientContactProps } from "../../../../interfaces/resources/clientContact";
import { routes } from "../../../../routes";
import { camelToSnakeCase } from "../../../../utils";
import { apiFailure, getSuccessType } from "../helpers";
import {
  getCurrentUserSuccessAction,
  LoginActionProps,
  LoginElinoiActionProps,
  LoginLinkedInActionProps,
} from "./actions";
import * as apiClient from "./api";
import { removeTokens } from "./helpers";

export const getRouterState = (state: RouterRootState) => state.router;

export function* login(action: LoginActionProps | LoginElinoiActionProps | LoginLinkedInActionProps): any {
  try {
    const req = Object.keys(action).reduce(
      (obj, el: string) => ("type" !== el ? { ...obj, [camelToSnakeCase(el)]: (action as any)[el] } : obj),
      {},
    ) as LoginActionProps | LoginElinoiActionProps | LoginLinkedInActionProps;
    yield call(apiClient.login, req);

    const routerState: any = yield select(getRouterState);

    yield put({
      locationSearch: routerState.location.search,
      type: ApiAuthenticationLogin.SUCCESS,
    });
  } catch (e: any) {
    if (!e.hasOwnProperty("response") || !e.response) {
      throw new Error(e.message);
    } else {
      yield put(apiFailure(e, action));
    }
  }
}

export function* loginSuccess(action: AnyAction) {
  const searchParams: { next?: string } = parse(action.locationSearch);
  yield put(push(searchParams.next ? searchParams.next : routes.app.home));
}

export function* refresh(action: AnyAction) {
  try {
    yield call(apiClient.refresh, action.refreshToken);
    push(routes.app.home);
    yield put({
      type: ApiAuthenticationRefresh.SUCCESS,
    });
  } catch (e: any) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* logout(action: AnyAction) {
  try {
    yield call(apiClient.logout);
    removeTokens();
    yield put({
      type: getSuccessType(action),
    });
  } catch (e: any) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* forgotPassword(action: AnyAction) {
  try {
    yield call(apiClient.forgotPassword, action.email);
    yield put({
      type: getSuccessType(action),
    });
  } catch (e: any) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* changePassword(action: AnyAction) {
  try {
    yield call(apiClient.changePassword, {
      forgot_token: action.forgot_token,
      password: action.password,
    });
    yield put({
      type: getSuccessType(action),
    });
  } catch (e: any) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* logoutSuccess(): any {
  const routerState: any = yield select(getRouterState);

  if (routerState.location.pathname !== routes.auth.login) {
    yield put(push(routes.auth.login));
  }
}

export function* getCurrentUser(action: AnyAction) {
  try {
    const response: AxiosResponse<ClientContactProps> = yield call(apiClient.getCurrentUser);
    yield put(getCurrentUserSuccessAction(response.data));
  } catch (e: any) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export default function* authenticationSaga() {
  yield takeLatest(GoogleAuthenticationLogin.REQUEST, login);
  yield takeLatest(LinkedInAuthenticationLogin.REQUEST, login);
  yield takeLatest(ElinoiAuthenticationLogin.REQUEST, login);
  yield takeLatest(ApiAuthenticationLogout.REQUEST, logout);
  yield takeLatest(ApiAuthenticationRefresh.REQUEST, refresh);
  yield takeLatest(ApiAuthenticationLogin.SUCCESS, loginSuccess);
  yield takeLatest(ApiAuthenticationLogout.SUCCESS, logoutSuccess);
  yield takeLatest(GetCurrentUser.REQUEST, getCurrentUser);
  yield takeLatest(ElinoiForgotPassword.REQUEST, forgotPassword);
  yield takeLatest(ElinoiChangePassword.REQUEST, changePassword);
}
