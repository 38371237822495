import { AnyAction } from "redux";
import { ApiAuthenticationLogin, ApiAuthenticationLogout } from "../../../../actionTypes/authentication";
import { GetCurrentUser } from "../../../../actionTypes/clientContact";

import { Auth } from "../../../../interfaces/api/authentication";

import { ClientContactProps } from "../../../../interfaces/resources/clientContact";
import { cookies, JWT_TOKEN_KEY } from "./helpers";

const defaultAuthState: Auth = {
  isAuthed: !!cookies.get(JWT_TOKEN_KEY),
};

export function auth(state: Auth = defaultAuthState, action: AnyAction): Auth {
  if (state.currentUser === null && action.type === GetCurrentUser.SUCCESS) {
    return {
      isAuthed: false,
    };
  }
  switch (action.type) {
    case ApiAuthenticationLogin.SUCCESS:
      return {
        isAuthed: true,
      };
    case ApiAuthenticationLogin.FAILURE:
    case ApiAuthenticationLogout.SUCCESS:
      return {
        isAuthed: false,
      };
    case ApiAuthenticationLogout.FAILURE:
    default:
      return state;
  }
}

export function currentUser(state: ClientContactProps | null = null, action: AnyAction): ClientContactProps | null {
  switch (action.type) {
    case GetCurrentUser.SUCCESS:
      return action.clientContact;
    default:
      return state;
  }
}
