import { GoogleOAuthProvider } from "@react-oauth/google";
import * as React from "react";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { Navigate, Route, Routes } from "react-router-dom";

import { EVENT } from "../../helpers/constant";
import { eventDispatcher } from "../../helpers/eventDispatcher";
import { MarketplacePage } from "../../pages/app/MarketplacePage";
import { NotFoundPage } from "../../pages/app/NotFoundPage";
import { OfferPage } from "../../pages/app/OfferPage";
import { OffersPage } from "../../pages/app/OffersPage";
import { TalentsPage } from "../../pages/app/TalentsPage";
import { ForgotPasswordPage } from "../../pages/auth/ForgotPasswordPage";
import { LoginPage } from "../../pages/auth/LoginPage";
import { SignInPage } from "../../pages/auth/SignInPage";
import { routes } from "../../routes";
import { Header } from "../Header";
import { ModalWrapper } from "../ModalWrapper";

import "../../styles/main.scss";
import { RequireAuth } from "../RequireAuth";

export const App = (): JSX.Element => {
  eventDispatcher.set(EVENT.ModalOpen);
  eventDispatcher.set(EVENT.ModalClose);
  eventDispatcher.set(EVENT.RequestFormSubmitted);
  eventDispatcher.set(EVENT.CurrentStatusChange);

  return (
    <>
      <GoogleOAuthProvider clientId="662328801794-7suou7aqg0mej42l64j2fih9i2v1fe6g.apps.googleusercontent.com">
        <Header />
        <Routes>
          <Route path={routes.app.home} element={<Navigate to={routes.app.offers} replace />} />
          <Route
            path={routes.app.offers}
            element={
              <RequireAuth>
                <OffersPage />
              </RequireAuth>
            }
          />
          <Route
            path={routes.app.offer}
            element={
              <RequireAuth>
                <OfferPage />
              </RequireAuth>
            }
          />
          <Route
            path={routes.app.talents}
            element={
              <RequireAuth>
                <TalentsPage />
              </RequireAuth>
            }
          />
          <Route
            path={routes.app.marketplace}
            element={
              <RequireAuth>
                <MarketplacePage />
              </RequireAuth>
            }
          />
          <Route path={routes.auth.loginCheck} element={<LinkedInPopUp />} />
          <Route path={routes.auth.signIn} element={<SignInPage />} />
          <Route path={routes.auth.login} element={<LoginPage />} />
          <Route path={routes.auth.forgotPassword} element={<ForgotPasswordPage />} />
          <Route element={<NotFoundPage />} />
        </Routes>
        <ModalWrapper />
      </GoogleOAuthProvider>
    </>
  );
};
