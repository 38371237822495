export type ApplicationStatusKeys = "NEW" | "CONTACTED" | "INTERVIEWS" | "HIRED" | "NEED_TO_REFUSE" | "REFUSED";
export const APPLICATION_STATUS: { [key in ApplicationStatusKeys]: string } = {
  NEW: "client_new",
  CONTACTED: "client_contacted",
  INTERVIEWS: "client_interviews",
  HIRED: "client_hired",
  NEED_TO_REFUSE: "client_need_to_refused",
  REFUSED: "client_refused",
};

export const APPLICATION_STATUS_PRE_SEND = "client_pre_send";
export const APPLICATION_STATUS_MARKETPLACE = "client_marketplace";

type EventKeys = "ModalOpen" | "ModalClose" | "RequestFormSubmitted" | "CurrentStatusChange";

export const EVENT: { [key in EventKeys]: string } = {
  ModalOpen: "ModalOpen",
  ModalClose: "ModalClose",
  RequestFormSubmitted: "RequestFormSubmitted",
  CurrentStatusChange: "CurrentStatusChange",
};

export const MODAL_TYPE: { [key: string]: string } = {
  OfferModal: "OfferModal",
  TalentCardModal: "TalentCardModal",
};

export const talentSegments = {
  segment1: "segment_1",
  segment2: "segment_2",
  segment3: "segment_3",
  segment4: "segment_4",
};
