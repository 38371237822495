import { AnyAction } from "redux";

export const identifyAction = (action: AnyAction): string => action.type.split("_").slice(0, -1).join("_");

export const getSuccessType = (action: AnyAction): string => `${identifyAction(action)}_SUCCESS`;

export const getFailType = (action: AnyAction): string => `${identifyAction(action)}_FAILURE`;

export const getRequestType = (action: AnyAction): string => `${identifyAction(action)}_REQUEST`;

export const apiFailure = (e: any, action: AnyAction, additionalArgs?: { [key: string]: any }): AnyAction => {
  if (e.response) {
    return {
      ...additionalArgs,
      form: action.form,
      payload: {
        message:
          e.response.data.message
          || e.response.data["hydra:description"]
          || e.response.data[1]
          || e.response.statusText,
        status: e.response.status,
        violations: e.response.data.violations || undefined,
      },
      type: getFailType(action),
    };
  }

  return {
    ...additionalArgs,
    form: action.form,
    payload: {
      message: e,
    },
    type: getFailType(action),
  };
};
