import { t } from "@lingui/macro";
import cx from "classnames";
import { gsap } from "gsap";
import * as React from "react";

import styles from "./index.module.scss";

export interface LabelsProps {
  initial?: React.ReactNode;
  submitting?: React.ReactNode;
  success?: React.ReactNode;
  error?: React.ReactNode;
}

export type SubmitCtaState = "initial" | "submitting" | "success" | "error";

interface Props {
  state: SubmitCtaState;
  labels?: LabelsProps;
  onClick?: () => void;
}

export const SubmitCta: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    state,
    labels,
    onClick = () => {
      return;
    },
  } = props;
  const [isAnimating, setIsAnimating] = React.useState(false);
  const [currentState, setCurrentState] = React.useState<SubmitCtaState>("initial");
  const DEFAULT_LABELS: LabelsProps = {
    initial: t`General.form.submitLabel.send`,
    submitting: t`General.form.submitLabel.sending`,
    success: t`General.form.submitLabel.success`,
    error: t`General.wording.error`,
  };
  const $submiting = {
    btn: React.useRef(null),
    txt: React.useRef(null),
  };
  const $success = {
    btn: React.useRef(null),
    txt: React.useRef(null),
  };
  const $error = {
    btn: React.useRef(null),
    txt: React.useRef(null),
  };
  const $initial = {
    btn: React.useRef(null),
    txt: React.useRef(null),
  };

  const formLabels = {
    ...DEFAULT_LABELS,
    ...labels,
  };

  const hideSubmittingState = () => {
    const tl = gsap.timeline({
      ease: "power3.in",
      onComplete: () => {
        setIsAnimating(false);
      },
    });
    tl.to($submiting.btn.current, { duration: 0.5, x: "102%" }).to(
      $submiting.txt.current,
      { duration: 0.5, x: "-102%" },
      0,
    );
  };
  const hideErrorState = () => {
    const tl = gsap.timeline({
      ease: "power3.in",
      onComplete: () => {
        setIsAnimating(false);
      },
    });
    tl.to($error.btn.current, { duration: 0.5, x: "102%" }).to($error.txt.current, { duration: 0.5, x: "-102%" }, 0);
  };
  const hideSuccessState = () => {
    const tl = gsap.timeline({
      ease: "power3.in",
      onComplete: () => {
        setIsAnimating(false);
      },
    });
    tl.to($success.btn.current, { duration: 0.5, x: "102%" }).to(
      $success.txt.current,
      { duration: 0.5, x: "-102%" },
      0,
    );
  };

  const showSubmittingState = () => {
    const tl = gsap.timeline({
      ease: "power3.in",
      onComplete: () => {
        setIsAnimating(false);
      },
    });
    tl.fromTo($submiting.btn.current, { x: "-102%", zIndex: 10 }, { duration: 0.8, x: "0%" }).fromTo(
      $submiting.txt.current,
      { x: "102%" },
      { duration: 0.8, x: "0%" },
      0,
    );
  };
  const showSuccessState = () => {
    gsap.set($success.txt.current, { x: 0, y: 0 });
    gsap.set($success.btn.current, {
      zIndex: 8,
      x: 0,
      y: 0,
      onComplete: () => {
        hideSubmittingState();
      },
    });
  };
  const showErrorState = () => {
    gsap.set($error.txt.current, { x: 0, y: 0 });
    gsap.set($error.btn.current, {
      zIndex: 8,
      x: 0,
      y: 0,
      onComplete: () => {
        hideSubmittingState();
      },
    });
  };
  const showInitialState = (prevState: SubmitCtaState) => {
    if (prevState === "error") {
      hideErrorState();
    }
    if (prevState === "success") {
      hideSuccessState();
    }
  };

  const switchState = React.useRef((s: SubmitCtaState, prevState: SubmitCtaState) => {
    switch (s) {
      case "submitting":
        showSubmittingState();
        break;
      case "success":
        showSuccessState();
        break;
      case "error":
        showErrorState();
        break;
      default:
        showInitialState(prevState);
        break;
    }
  });

  React.useEffect(() => {
    if (!isAnimating && state !== currentState) {
      switchState.current(state, currentState);
      setIsAnimating(true);
      setCurrentState(state);
    }
  }, [isAnimating, state, currentState]);

  return (
    <>
      <button className={styles.submitButton} type="submit" onClick={onClick} disabled={currentState !== "initial"}>
        <span className={cx(styles.btnState, styles.submittingState)} ref={$submiting.btn}>
          <span className={styles.wordingState} ref={$submiting.txt}>
            {formLabels.submitting}
          </span>
        </span>
        <span className={cx(styles.btnState, styles.successState)} ref={$success.btn}>
          <span className={styles.wordingState} ref={$success.txt}>
            {formLabels.success}
          </span>
        </span>
        <span className={cx(styles.btnState, styles.errorState)} ref={$error.btn}>
          <span className={styles.wordingState} ref={$error.txt}>
            {formLabels.error}
          </span>
        </span>
        <span className={cx(styles.btnState, styles.initialState)} ref={$initial.btn}>
          <span className={styles.wordingState} ref={$initial.txt}>
            {formLabels.initial}
          </span>
        </span>
      </button>
    </>
  );
};
