import anime from "animejs";
import { MiniSignalBinding } from "mini-signals";
import React, { FC, SyntheticEvent, useEffect, useRef, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { EVENT } from "../../helpers/constant";
import { eventDispatcher } from "../../helpers/eventDispatcher";
import {
  CardOfferFragmentProps,
  InfosTalentCardFragmentProps,
  OfferInfosTalentCardFragmentProps,
  TalentInfosTalentCardFragmentProps,
} from "../../services/api/graphql/types";

import styles from "./index.module.scss";
import { OfferModal, OfferModalFormType } from "./OfferModal";
import { TalentCardModal } from "./TalentCardModal";

export interface Props {
  open?: boolean;
}

interface EventOpenOffer {
  type: "OfferModal";
  data: {
    formType: OfferModalFormType;
    offer: CardOfferFragmentProps;
  };
}
interface EventOpenTalentCard {
  type: "TalentCardModal";
  data: {
    offer: OfferInfosTalentCardFragmentProps;
    talent: TalentInfosTalentCardFragmentProps;
    talentCard: InfosTalentCardFragmentProps;
  };
}

export type EventOpenProps = EventOpenOffer | EventOpenTalentCard;

export const ModalWrapper: FC<Props> = (props: Props) => {
  const { open } = props;
  const ModalOpenRef = useRef<MiniSignalBinding>();
  const ModalCloseRef = useRef<MiniSignalBinding>();
  const $modalWrapper = useRef(null);
  const $modalContainer = useRef(null);
  const $modalCloseX = useRef(null);
  const [show, setShow] = useState(open);
  const [hidden, setHidden] = useState(!open);
  const [eventParams, setEventParams] = useState<EventOpenProps | null>(null);

  const showAnimation = (): void => {
    // document.body.style.overflow = "hidden";

    const tl = anime.timeline({
      duration: 300,
      easing: "easeInOutExpo",
    });

    tl.add({
      easing: "linear",
      opacity: 1,
      targets: $modalWrapper.current,
    }).add({
      duration: 800,
      opacity: [0, 1],
      targets: $modalContainer.current,
      translateY: [-10, 0],
    });
  };
  const hideAnimation = (): void => {
    const tl = anime.timeline({
      complete: () => {
        document.body.style.overflow = "";
        setHidden(true);
        setEventParams(null);
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: 0,
      targets: $modalContainer.current,
      translateY: 20,
    }).add(
      {
        opacity: 0,
        targets: $modalWrapper.current,
      },
      0,
    );
  };

  const showModal = (params: EventOpenProps): void => {
    setEventParams(params);
    setHidden(false);
    setShow(true);
  };
  const hideModal = (): void => {
    setShow(false);
  };

  const triggerCloseModal = () => {
    eventDispatcher.trigger(EVENT.ModalClose);
  };

  const onClickWrapper = (event: SyntheticEvent<HTMLDivElement>) => {
    if (
      event.target === $modalWrapper.current
      || event.target === $modalContainer.current
      || event.target === $modalCloseX.current
    ) {
      triggerCloseModal();
    }
  };

  useEffect(() => {
    ModalOpenRef.current = eventDispatcher.on(EVENT.ModalOpen, showModal);
    ModalCloseRef.current = eventDispatcher.on(EVENT.ModalClose, hideModal);

    return (): void => {
      if (ModalOpenRef.current) {
        eventDispatcher.off(EVENT.ModalOpen, ModalOpenRef.current);
      }
      if (ModalCloseRef.current) {
        eventDispatcher.off(EVENT.ModalClose, ModalCloseRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!show) {
      hideAnimation();
      return;
    }
    showAnimation();
  }, [show]);

  return hidden ? null : (
    <div className={styles.modalWrapper} ref={$modalWrapper} style={{ opacity: 0 }} onClick={onClickWrapper}>
      <span className={styles.closeModalX} ref={$modalCloseX}>
        <AiOutlineCloseCircle />
      </span>
      <div ref={$modalContainer} className={styles.modalContainer}>
        {eventParams?.type === "OfferModal" ? (
          <OfferModal data={eventParams.data} />
        ) : eventParams?.type === "TalentCardModal" ? (
          <TalentCardModal data={eventParams.data} />
        ) : null}
      </div>
    </div>
  );
};
