import { gql, useQuery } from "@apollo/client";
import { t } from "@lingui/macro";
import algoliasearch from "algoliasearch/lite";
import cx from "classnames";
import * as React from "react";
import {
  ClearRefinements,
  Configure,
  CurrentRefinements,
  HitsPerPage,
  InstantSearch,
  Pagination,
  RangeInput,
  RefinementList,
  SearchBox,
} from "react-instantsearch-hooks-web";
import { useSelector } from "react-redux";
import { Hits } from "../../../components/Algolia/Hits";
import { Refresh } from "../../../components/Algolia/Refresh";
import { Loader } from "../../../components/Loader";
import { OfferSelect } from "../../../components/OfferSelect";
import { talentSegments } from "../../../helpers/constant";

import { OFFER_STATUS_ACTIVE } from "../../../interfaces/resources/offer";
import { StateProps } from "../../../interfaces/state";
import {
  GetMarketplaceOffers,
  GetMarketplaceOffersVariables,
  MarketplaceOffersPageFragmentProps,
} from "../../../services/api/graphql/types";
import { extractIdFromIri } from "../../../services/api/helpers";
import globalStyles from "../../../styles/global.module.scss";
import { algoliaAttributesTrans } from "../../../translations/constants/algolia";
import { FilterPanel } from "./FilterPanel";
import styles from "./index.module.scss";

export const MARKETPLACE_OFFERS_PAGE_QUERY = gql`
  query GetMarketplaceOffers($userId: ID!, $offerStatus: String, $isMarketplace: Boolean) {
    clientContact(id: $userId) {
      id
      contact {
        company {
          offers(status: $offerStatus, isMarketplace: $isMarketplace) {
            edges {
              node {
                ...MarketplaceOffersPageFragmentProps
              }
            }
          }
        }
      }
    }
  }
  fragment MarketplaceOffersPageFragmentProps on Offer {
    id
    title
    slug
    status
    isMarketplace
    coreResponsibility {
      value
      slug
    }
    discardedTalents {
      edges {
        node {
          id
        }
      }
    }
    printExperienceYears
    printSalaries
    offerLocations {
      edges {
        node {
          location {
            value
          }
        }
      }
    }
  }
`;

export const algoliaAttributes = {
  cities: "talentProfile.desiredPlaces.value",
  // conclusion: "talentProfile.lastQualification.conclusion",
  // context: "talentProfile.lastQualification.context",
  coreResponsibility: "talentProfile.coreResponsibility.value",
  responsibilities: "talentProfile.responsibilities.responsibility.value",
  experience: "talentProfile.experience",
  exptectedFullPackage: "talentProfile.expectedRemuneration.fullPackage",
  // golden: "talentProfile.golden",
  // idealJob: "talentProfile.lastQualification.idealJob",
  // persona: "talentProfile.persona",
  schools: "educations.schoolName",
  searchStatus: "searchStatus",
  status: "status",
  // storytelling: "talentProfile.lastQualification.storytelling",
  // tim: "talentManager.firstname",
  // noTalentManager: "noTalentManager",
  companyName: "linkedinResume.experiences.companyName",
  languages: "linkedinResume.languages.name",
  linkedinSummary: "linkedinResume.summary",
  linkedinExperiencesDescription: "linkedinResume.experiences.description",
  // beenPlaced: "beenPlaced",
};

const retrievedFacets = [
  ...Object.values(algoliaAttributes),
  "applicationsOffersAndDiscardedOffers",
  "experience",
  "firstname",
  "id",
  "image",
  "lastLogin",
  "lastname",
  "linkedInProfile",
  "talentProfile.lastQualification.expectedRemuneration.fullPackage",
  "talentProfile.lastQualification.expectedRemuneration.fixedSalary",
];

export const MarketplacePage: React.FC = () => {
  const currentUser = useSelector((state: StateProps) => state.currentUser);
  const [offers, setOffers] = React.useState<MarketplaceOffersPageFragmentProps[]>([]);
  const [selectedOffer, setSelectedOffer] = React.useState<MarketplaceOffersPageFragmentProps | null>(null);
  const [ruleQuery, setRuleQuery] = React.useState<string>("");
  const [reloading, setReloading] = React.useState(false);
  const [initialUiState, setInitialUiState] = React.useState<{ [key: string]: any }>({});
  const searchClient = React.useRef(
    algoliasearch(`${process.env.REACT_APP_ALGOLIA_APP_ID}`, `${process.env.REACT_APP_ALGOLIA_SECRET_KEY}`),
  ).current;
  const algoliaIndexPrefix = process.env.REACT_APP_ALGOLIA_INDEX_PREFIX;
  const algoliaIndexes = React.useRef({
    two: `${algoliaIndexPrefix}${talentSegments.segment2}`,
  }).current;
  const { loading, data } = useQuery<GetMarketplaceOffers, GetMarketplaceOffersVariables>(
    MARKETPLACE_OFFERS_PAGE_QUERY,
    {
      variables: {
        userId: currentUser ? currentUser["@id"] : "",
        offerStatus: OFFER_STATUS_ACTIVE,
        isMarketplace: true,
      },
    },
  );
  const onOfferSelectChange = (o: MarketplaceOffersPageFragmentProps) => {
    const discardedTalentsIds: string[] = [];
    o?.discardedTalents?.edges?.forEach((edge) => {
      if (edge?.node) {
        discardedTalentsIds.push(edge.node.id);
      }
    });
    setSelectedOffer({ ...o, discardedTalents: discardedTalentsIds as any });
  };

  React.useEffect(() => {
    const allOffers: MarketplaceOffersPageFragmentProps[] = [];
    if (data) {
      const ofrs = data.clientContact?.contact?.company?.offers?.edges || [];
      ofrs.forEach((v) => {
        if (v !== null && v.node !== null) {
          const { node } = v;
          allOffers.push(node);
        }
      });
    }
    setOffers(allOffers);
  }, [data]);

  React.useEffect(() => {
    if (!!!currentUser || !!!selectedOffer) {
      return;
    }
    const selectedfullPackage = selectedOffer.printSalaries?.replace(" - ", ":").replace(/k/gi, "");
    const selectedExperience = selectedOffer.printExperienceYears?.replace("-", ":").replace(/ans/gi, "");
    const indexState = {
      refinementList: {
        [algoliaAttributes.cities]: [selectedOffer.offerLocations?.edges?.map((l) => l?.node?.location.value)],
      },
      range: {
        [algoliaAttributes.exptectedFullPackage]: selectedfullPackage,
        [algoliaAttributes.experience]: selectedExperience,
      },
    };
    const offerUiState = {
      [algoliaIndexes.two]: indexState,
    };
    const filterQuery = `(${algoliaAttributes.coreResponsibility}:"${selectedOffer?.coreResponsibility?.value}" OR ${
      algoliaAttributes.responsibilities
    }:"${selectedOffer?.coreResponsibility?.value}") AND NOT applicationsOffersAndDiscardedOffers:${extractIdFromIri(
      selectedOffer.id,
    )}`;
    setRuleQuery(filterQuery);
    setInitialUiState(offerUiState);
    setReloading(true);
  }, [selectedOffer, currentUser, algoliaIndexes]);

  React.useEffect(() => {
    if (reloading) {
      setReloading(false);
    }
  }, [reloading]);

  if (!!!algoliaIndexPrefix) {
    throw new Error("REACT_APP_ALGOLIA_INDEX_PREFIX is missing");
  }

  return (
    <>
      <div className={globalStyles.pageWrapper}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>{t`MarketplacePage.title`}</h3>
        </div>
        <div className={styles.offerSelectContainer}>
          <OfferSelect onSelectChange={onOfferSelectChange} loading={loading} offers={offers} />
        </div>
        <div>
          {reloading ? (
            <Loader />
          ) : !!selectedOffer ? (
            <InstantSearch searchClient={searchClient} indexName={algoliaIndexes.two} initialUiState={initialUiState}>
              <Configure filters={ruleQuery} attributesToRetrieve={retrievedFacets} />
              <div className={styles.searchWrapper}>
                <div className={styles.filtersContainer}>
                  <div>
                    <FilterPanel
                      header={t({
                        id: (algoliaAttributesTrans as { [key: string]: string })[
                          algoliaAttributes.exptectedFullPackage
                        ],
                      })}
                    >
                      <RangeInput
                        classNames={{ input: styles.rangeInput }}
                        attribute={algoliaAttributes.exptectedFullPackage}
                      />
                    </FilterPanel>
                  </div>
                  <div>
                    <FilterPanel
                      header={t({
                        id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.experience],
                      })}
                    >
                      <RangeInput attribute={algoliaAttributes.experience} classNames={{ input: styles.rangeInput }} />
                    </FilterPanel>
                  </div>

                  <div>
                    <FilterPanel
                      header={t({
                        id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.companyName],
                      })}
                      collapsed={true}
                    >
                      <RefinementList
                        attribute={algoliaAttributes.companyName}
                        searchable={true}
                        searchablePlaceholder="Rechercher une entreprise"
                        showMore={true}
                      />
                    </FilterPanel>
                  </div>

                  <div>
                    <FilterPanel
                      header={t({
                        id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.cities],
                      })}
                      collapsed={true}
                    >
                      <RefinementList
                        attribute={algoliaAttributes.cities}
                        searchable={true}
                        searchablePlaceholder="Rechercher une ville"
                        showMore={true}
                      />
                    </FilterPanel>
                  </div>

                  <div>
                    <FilterPanel
                      header={t({
                        id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.schools],
                      })}
                      collapsed={true}
                    >
                      <RefinementList
                        attribute={algoliaAttributes.schools}
                        searchable={true}
                        searchablePlaceholder="Rechercher une école"
                        showMore={true}
                      />
                    </FilterPanel>
                  </div>

                  <div>
                    <FilterPanel
                      header={t({
                        id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.languages],
                      })}
                      collapsed={true}
                    >
                      <RefinementList
                        attribute={algoliaAttributes.languages}
                        searchable={true}
                        searchablePlaceholder="Rechercher une langue"
                        showMore={true}
                      />
                    </FilterPanel>
                  </div>
                </div>

                <div className={styles.resultsRefineContainer}>
                  <div>
                    <SearchBox placeholder="Recherche par mots-clés" />
                  </div>

                  <div className={styles.resultRefineCtaContainer}>
                    <HitsPerPage
                      title="Résultats"
                      items={[
                        { label: "20 par page", value: 20, default: true },
                        { label: "40 par page", value: 40 },
                      ]}
                    />
                    <div className={styles.refreshCtaContainer}>
                      <Refresh />
                    </div>
                  </div>

                  <div className={styles.currentClearRefineContainer}>
                    <CurrentRefinements
                      transformItems={(items) =>
                        items.map((item) => {
                          const attribute = item.label.startsWith("hierarchicalCategories") ? "Hierarchy" : item.label;
                          const label = t({
                            id: (algoliaAttributesTrans as { [key: string]: string })[item.attribute],
                          });
                          return {
                            ...item,
                            attribute,
                            label: `${label} `,
                          };
                        })
                      }
                    />
                    <ClearRefinements
                      className={styles.clearRefinements}
                      classNames={{
                        button: cx(globalStyles.cta, globalStyles.alertCta),
                      }}
                    />
                  </div>

                  <div>
                    <Hits offer={selectedOffer} />
                  </div>
                  <div className={styles.paginationContainer}>
                    <Pagination />
                  </div>
                </div>
              </div>
            </InstantSearch>
          ) : (
            <div className={styles.tipsWrapper}>
              <div className={cx(styles.tipsContainer, globalStyles.tipsContainer)}>
                <b>Instruction d&apos;utilisation</b> :
                <ol>
                  <li>Sélectionnez une offre pour voir les talents qui correspondent à vos critères.</li>
                  <li>
                    Ajustez les filtres pour dénicher la perle rare.
                    <ol>
                      <li>
                        <b>[Liste des filtres disponibles + explication]</b>
                      </li>
                    </ol>
                  </li>
                  <li>
                    Lorsqu&apos;un profil vous semble intéressant, cliquez sur <b>[&quot;Mise en contact&quot;]</b> pour
                    suivre facilement sa candidature depuis votre dashboard
                  </li>
                  <li>
                    Vous pouvez facilement cacher les profils qui ne correspondent pas a votre recherche en cliquant sur{" "}
                    <b>[&quot;Pas intéressant&quot;]</b>.
                  </li>
                </ol>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
