import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";

import * as authenticationReducers from "./services/api/rest/authentication/reducers";
import authenticationSaga from "./services/api/rest/authentication/saga";
import * as apiReducers from "./services/api/rest/reducer";

import apiSaga from "./services/api/rest/saga";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

export const rootReducer = combineReducers({
  ...apiReducers,
  ...authenticationReducers,
  router: routerReducer,
});

function* rootSaga() {
  yield all([apiSaga(), authenticationSaga()]);
}

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(routerMiddleware, sagaMiddleware)));

export const history = createReduxHistory(store);

sagaMiddleware.run(rootSaga);
