import { Location } from "react-router-dom";

export const setRef = (el: any, array: React.MutableRefObject<any[]>): void => {
  if (el && !array.current.includes(el)) array.current.push(el);
};

export function getParams(location: Location, params: string): string | null;
export function getParams(location: Location, params: string[]): { [key: string]: string | null };
export function getParams(
  location: Location,
  params: string | string[],
): { [key: string]: string | null } | string | null {
  const urlParams = new URLSearchParams(location.search);
  if (Array.isArray(params)) {
    const obj: { [key: string]: string | null } = {};
    for (const p of params) {
      obj[p] = urlParams.get(p);
    }
    return obj;
  }
  return urlParams.get(params);
}

export const truncate = (str: string, max = 10): string => {
  const array = str.trim().split(" ");
  const ellipsis = array.length > max ? "..." : "";

  return array.slice(0, max).join(" ") + ellipsis;
};
