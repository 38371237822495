import { GetOffers_clientContact_contact_company_offers_edges_node } from "../services/api/graphql/types";

interface SortedOffersProps {
  label: string | undefined;
  offers: GetOffers_clientContact_contact_company_offers_edges_node[];
}

export const getOffersSortedByResponsibility = (
  offers: GetOffers_clientContact_contact_company_offers_edges_node[],
): SortedOffersProps[] => {
  if (!offers.length) {
    return [];
  }
  const sorted = offers.reduce((result: SortedOffersProps[], item) => {
    const responsibilityType = item["coreResponsibility"]?.responsibilityType.value;
    if (result.some((e) => e.label === responsibilityType)) {
      result.map((e) => {
        if (e.label === responsibilityType) {
          e.offers.push(item);
        }
        return true;
      });
    } else {
      result.push({
        label: responsibilityType,
        offers: [item],
      });
    }
    return result;
  }, []);
  return sorted;
};
