import * as React from "react";

import logo from "../../images/logos/elinoi-logo-label-aqua.svg";
import styles from "./index.module.scss";

export const LogoSmall: React.FC = () => {
  return (
    <div className={styles.logoSmallContainer}>
      <div className={styles.logoSmall}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 141 65">
          <path d="M141 32.5 108.5 0 76 32.5 108.5 65 141 32.5ZM65 32.5 32.5 0 0 32.5 32.5 65 65 32.5Z" />
        </svg>
      </div>
    </div>
  );
};

export const Logo = ({ className = "" }: { className?: string }): JSX.Element => (
  <div className={className}>
    <img src={logo} alt="elinoï" />
  </div>
);
