import {
  CreateApplication,
  EditApplicationClientStatus,
  EditOffer,
  PostApplicationMessage,
  PostClientRequestOfferChange,
  PutApplicationMessage,
  PutOfferClientRequest,
} from "../../../actionTypes/application";
import { PostTalentCardClientEvent } from "../../../actionTypes/talentCard";

export interface EditApplicationClientStatusActionProps {
  applicationIri: string;
  clientStatus: string;
  type: string;
}

export const onEditApplicationClientStatusAction = (values: {
  applicationIri: string;
  clientStatus: string;
}): EditApplicationClientStatusActionProps => {
  return {
    applicationIri: values.applicationIri,
    clientStatus: values.clientStatus,
    type: EditApplicationClientStatus.REQUEST,
  };
};

interface onCreateApplicationActionProps {
  offerIri: string;
  talentIri: string;
  clientIri: string;
  status: string;
  clientStatus: string;
}

export const onCreateApplicationAction = (data: onCreateApplicationActionProps): any => {
  return {
    ...data,
    type: CreateApplication.REQUEST,
  };
};

export function editOfferAction(values: { offerId: string; discardedTalents: number[] }) {
  return {
    offerId: values.offerId,
    discardedTalents: values.discardedTalents,
    type: EditOffer.REQUEST,
  };
}

interface PostClientRequestOfferChangeProps {
  message: string;
  offerIri: string;
  coreUserIri: string;
  clientContactIri: string;
  type: string;
}
export const onPostClientRequestOfferChange = (values: {
  message: string;
  offerIri: string;
  coreUserIri: string;
  clientContactIri: string;
}): PostClientRequestOfferChangeProps => {
  return {
    message: values.message,
    offerIri: values.offerIri,
    coreUserIri: values.coreUserIri,
    clientContactIri: values.clientContactIri,
    type: PostClientRequestOfferChange.REQUEST,
  };
};

interface PutOfferClientRequestProps {
  offerIri: string;
  clientRequest: boolean;
  type: string;
}

export const onPutOfferClientRequest = (values: {
  offerIri: string;
  clientRequest: boolean;
}): PutOfferClientRequestProps => {
  return {
    offerIri: values.offerIri,
    clientRequest: values.clientRequest,
    type: PutOfferClientRequest.REQUEST,
  };
};

interface PostTalentCardClientEventActionProps {
  talentCardIri: string;
  clientContactIri: string;
  type: string;
}

export const onPostTalentCardClientEventAction = (values: {
  talentCardIri: string;
  clientContactIri: string;
}): PostTalentCardClientEventActionProps => {
  return {
    talentCardIri: values.talentCardIri,
    clientContactIri: values.clientContactIri,
    type: PostTalentCardClientEvent.REQUEST,
  };
};

interface OnPostApplicationMessageProps {
  message: string;
  applicationIri: string;
  clientContactIri: string;
  clientEnabled: boolean;
}
interface PostApplicationMessageProps extends OnPostApplicationMessageProps {
  type: string;
}
export const onPostApplicationMessage = (values: OnPostApplicationMessageProps): PostApplicationMessageProps => {
  return {
    message: values.message,
    applicationIri: values.applicationIri,
    clientContactIri: values.clientContactIri,
    clientEnabled: values.clientEnabled,
    type: PostApplicationMessage.REQUEST,
  };
};

interface onClientReadMessageProps {
  messages: string[];
  clientContact: string;
}

interface ClientReadMessageProps extends onClientReadMessageProps {
  type: string;
}

export const onClientReadMessages = (values: onClientReadMessageProps): ClientReadMessageProps => {
  return {
    messages: values.messages,
    clientContact: values.clientContact,
    type: PutApplicationMessage.REQUEST,
  };
};
