import { gql, useQuery } from "@apollo/client";
import cx from "classnames";
import { MiniSignalBinding } from "mini-signals";
import * as React from "react";
import { DragDropContext, OnDragEndResponder, OnDragStartResponder } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CardApplicationFragment } from "../../../components/CardApplication";

import { GraphqlError } from "../../../components/GraphqlError";
import { Loader } from "../../../components/Loader";
import { APPLICATION_STATUS, APPLICATION_STATUS_MARKETPLACE, EVENT } from "../../../helpers/constant";
import { eventDispatcher } from "../../../helpers/eventDispatcher";
import { useForceUpdate } from "../../../helpers/hook";
import { StateProps } from "../../../interfaces/state";
import {
  CardApplicationFragmentProps,
  GetOffer,
  GetOfferVariables,
  GetPreSend,
  GetPreSendVariables,
  OfferPageFragmentProps,
} from "../../../services/api/graphql/types";
import { onEditApplicationClientStatusAction } from "../../../services/api/rest/actions";
import global from "../../../styles/global.module.scss";
import { Column } from "./Column";
import styles from "./index.module.scss";
import {
  SetupDndDataProps,
  setupDndData,
  COL_NEW,
  COL_REFUSED,
  COL_CONTACTED,
  COL_INTERVIEWS,
  COL_HIRED,
  COL_NEED_TO_REFUSE,
  setupPreSendColumn,
  ColumnDndProps,
} from "./setupDndData";

export const OFFER_PAGE_QUERY = gql`
  query GetOffer($userId: ID!, $offerSlug: String, $clientStatusList: [String]) {
    clientContact(id: $userId) {
      id
      contact {
        company {
          offers(slug: $offerSlug) {
            edges {
              node {
                ...OfferPageFragmentProps
              }
            }
          }
        }
      }
    }
  }
  fragment OfferPageFragmentProps on Offer {
    id
    title
    slug
    isMarketplace
    applications(clientStatus_list: $clientStatusList) {
      edges {
        node {
          ...CardApplicationFragmentProps
        }
      }
    }
  }
  ${CardApplicationFragment}
`;

export const PRE_SEND_QUERY = gql`
  query GetPreSend($userId: ID!, $offerSlug: String, $cursor: String, $clientStatusList: [String]) {
    clientContact(id: $userId) {
      id
      contact {
        company {
          offers(slug: $offerSlug) {
            edges {
              node {
                ...PreSendFragmentProps
              }
            }
          }
        }
      }
    }
  }
  fragment PreSendFragmentProps on Offer {
    id
    title
    slug
    isMarketplace
    applications(first: 12, after: $cursor, clientStatus_list: $clientStatusList) {
      edges {
        node {
          ...CardApplicationFragmentProps
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${CardApplicationFragment}
`;

export const OfferPage: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const forceUpdate = useForceUpdate();
  const cloneSetup = JSON.parse(JSON.stringify(setupDndData));
  const clonePreSendSetup = JSON.parse(JSON.stringify(setupPreSendColumn));
  const CurrentStatusChangeRef = React.useRef<MiniSignalBinding>();
  const isLoadingMore = React.useRef(true);
  const dndDataRef = React.useRef(cloneSetup);
  const preSendDataRef = React.useRef(clonePreSendSetup);
  const [offer, setOffer] = React.useState<OfferPageFragmentProps | null | undefined>(null);
  const [preSendPageInfo, setPreSendPageInfo] = React.useState<
    { hasNextPage: boolean; endCursor: string | null } | null | undefined
  >(null);
  const [dndData, setDndData] = React.useState<SetupDndDataProps>(cloneSetup);
  const [preSendDndData, setPreSendDndData] = React.useState<ColumnDndProps>(clonePreSendSetup);
  const [isDragging, setIsDragging] = React.useState(false);
  const [startColumnId, setStartColumnId] = React.useState<string | null>(null);
  const [applicationsObj, setApplicationsObj] = React.useState<{
    [key: string]: CardApplicationFragmentProps;
  }>({});
  const [preSendApplicationsObj, setpreSendApplicationsObj] = React.useState<{
    [key: string]: CardApplicationFragmentProps;
  }>({});
  const currentUser = useSelector((state: StateProps) => state.currentUser);
  const defaultVariables = {
    userId: currentUser?.["@id"] || "",
    offerSlug: params.slug,
    clientStatusList: Object.values(APPLICATION_STATUS),
  };
  const preSendVariables = {
    ...defaultVariables,
    clientStatusList: [APPLICATION_STATUS_MARKETPLACE],
  };
  const { loading, error, data } = useQuery<GetOffer, GetOfferVariables>(OFFER_PAGE_QUERY, {
    variables: defaultVariables,
  });
  const {
    data: preSendData,
    loading: preSendLoading,
    fetchMore,
  } = useQuery<GetPreSend, GetPreSendVariables>(PRE_SEND_QUERY, {
    variables: preSendVariables,
  });

  const onCurrentStatusChange = React.useRef((refParams: { appId: string; newStatus: string }) => {
    Object.keys(dndDataRef.current.columns).forEach((col) => {
      const column = dndDataRef.current.columns[col];
      const colAppsIds = column.applicationsIds;
      const colStatus = column.applicationClientStatus;
      if (colAppsIds.includes(refParams.appId)) {
        const index = colAppsIds.indexOf(refParams.appId);
        colAppsIds.splice(index, 1);
      }
      if (colStatus === refParams.newStatus) {
        colAppsIds.push(refParams.appId);
      }
    });
    setDndData(dndDataRef.current);
    forceUpdate();
  }).current;

  const onDragStart: OnDragStartResponder = (start) => {
    setIsDragging(true);
    setStartColumnId(start.source.droppableId);
  };

  const onDragEnd: OnDragEndResponder = (result) => {
    const { source, destination, draggableId } = result;
    setStartColumnId(null);
    setIsDragging(false);
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const start = dndDataRef.current.columns[source.droppableId];
    const finish = dndDataRef.current.columns[destination.droppableId];
    start.applicationsIds.splice(source.index, 1);
    finish.applicationsIds.splice(destination.index, 0, draggableId);
    setDndData(dndDataRef.current);
    dispatch(
      onEditApplicationClientStatusAction({
        applicationIri: draggableId,
        clientStatus: finish.applicationClientStatus,
      }),
    );

    const updatedApp = {
      ...applicationsObj[draggableId],
      clientStatus: finish.applicationClientStatus,
    };

    setApplicationsObj({
      ...applicationsObj,
      [draggableId]: { ...updatedApp },
    });
  };

  const loadMorePreSendApp = () => {
    const fetchMoreVariables: GetPreSendVariables = {
      ...preSendVariables,
      cursor: preSendPageInfo?.endCursor,
    };
    fetchMore({
      variables: fetchMoreVariables,
    });
  };

  const onScrollList = (e: React.UIEvent<HTMLDivElement>) => {
    e.persist();
    const wH = window.innerHeight || document.documentElement.clientHeight;
    const node = e.target as HTMLDivElement;
    const children = node.children;
    const count = node.childElementCount;
    const offset = 3;
    const limitNode = children[count - 1 - offset];
    if (!isLoadingMore.current && !!preSendPageInfo?.hasNextPage && limitNode.getBoundingClientRect().top <= wH) {
      isLoadingMore.current = true;
      loadMorePreSendApp();
    }
  };

  React.useEffect(() => {
    const offers = data?.clientContact?.contact?.company?.offers?.edges;
    const appsObj: {
      [key: string]: CardApplicationFragmentProps;
    } = {};
    if (offers && offers.length) {
      const apps = offers[0]?.node?.applications?.edges || [];
      setOffer(offers[0]?.node);
      apps.forEach((ap) => {
        if (ap !== null && ap.node !== null) {
          appsObj[ap.node.id] = ap.node;
          switch (ap.node.clientStatus) {
            case APPLICATION_STATUS.NEW:
              dndDataRef.current.columns[COL_NEW].applicationsIds.push(ap.node.id);
              break;
            case APPLICATION_STATUS.CONTACTED:
              dndDataRef.current.columns[COL_CONTACTED].applicationsIds.push(ap.node.id);
              break;
            case APPLICATION_STATUS.INTERVIEWS:
              dndDataRef.current.columns[COL_INTERVIEWS].applicationsIds.push(ap.node.id);
              break;
            case APPLICATION_STATUS.HIRED:
              dndDataRef.current.columns[COL_HIRED].applicationsIds.push(ap.node.id);
              break;
            case APPLICATION_STATUS.NEED_TO_REFUSE:
              dndDataRef.current.columns[COL_NEED_TO_REFUSE].applicationsIds.push(ap.node.id);
              break;
            case APPLICATION_STATUS.REFUSED:
              dndDataRef.current.columns[COL_REFUSED].applicationsIds.push(ap.node.id);
              break;
          }
        }
      });
      setDndData(dndDataRef.current);
      setApplicationsObj(appsObj);
    }
  }, [data]);

  React.useEffect(() => {
    const appsObj: {
      [key: string]: CardApplicationFragmentProps;
    } = {};
    const preSendOffers = preSendData?.clientContact?.contact?.company?.offers?.edges;
    if (preSendOffers && preSendOffers.length) {
      const apps = preSendOffers[0]?.node?.applications?.edges || [];
      setPreSendPageInfo(preSendOffers[0]?.node?.applications?.pageInfo);
      apps.forEach((ap) => {
        if (ap !== null && ap.node !== null) {
          appsObj[ap.node.id] = ap.node;
          if (preSendDataRef.current.applicationsIds.indexOf(ap.node.id) < 0) {
            preSendDataRef.current.applicationsIds.push(ap.node.id);
          }
        }
      });
      setPreSendDndData(preSendDataRef.current);
      setpreSendApplicationsObj(appsObj);
      isLoadingMore.current = false;
    }
  }, [preSendData]);

  React.useEffect(() => {
    CurrentStatusChangeRef.current = eventDispatcher.on(
      EVENT.CurrentStatusChange,
      (refParams: { appId: string; newStatus: string }) => {
        onCurrentStatusChange(refParams);
      },
    );

    return (): void => {
      if (CurrentStatusChangeRef.current) {
        eventDispatcher.off(EVENT.CurrentStatusChange, CurrentStatusChangeRef.current);
      }
    };
  }, [onCurrentStatusChange]);

  if (error) {
    return (
      <>
        <div className={global.pageWrapper}>
          <GraphqlError error={error} />
        </div>
      </>
    );
  }
  if (loading || !!!offer) {
    return (
      <>
        <div className={cx(global.pageWrapper, global.loaderPageWrapper)}>
          <Loader />
        </div>
      </>
    );
  }
  return (
    <>
      <div className={cx(styles.offerPageWrapper, global.pageWrapper)}>
        <div className={styles.offerPageTitleWrapper}>
          <div className={styles.offerPageTitleContainer}>{offer && offer.title}</div>
        </div>
        <div className={styles.columnContainer}>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            {offer && offer.isMarketplace ? (
              <Column
                column={preSendDndData}
                key={preSendDndData.id}
                applications={preSendApplicationsObj}
                applicationsIds={preSendDndData.applicationsIds}
                id={preSendDndData.id}
                loading={preSendLoading}
                isDragging={isDragging}
                onScrollList={onScrollList}
              />
            ) : null}
            {dndData.columnOrder.map((colId) => {
              const col = dndData.columns[colId];
              const isDropDisabled = colId === startColumnId;
              return (
                <Column
                  column={col}
                  key={colId}
                  applications={applicationsObj}
                  applicationsIds={col.applicationsIds}
                  id={colId}
                  loading={loading}
                  isDropDisabled={isDropDisabled}
                  isDragging={isDragging}
                />
              );
            })}
          </DragDropContext>
        </div>
      </div>
    </>
  );
};
