import anime, { AnimeTimelineInstance } from "animejs";
import cx from "classnames";
import * as React from "react";

import { $cubic1 } from "../../helpers/easing";
import styles from "./index.module.scss";

interface SharedProps {
  label: React.ReactNode;
  wordingClassname?: string;
  btnClassname?: string;
  btnHoverClassname?: string;
  wordingHoverClassname?: string;
}

interface CtaProps extends SharedProps {
  onClick?: () => void;
}
type ButtonCtaProps = React.ButtonHTMLAttributes<unknown> & SharedProps;
type LinkCtaProps = React.AnchorHTMLAttributes<unknown> & SharedProps;

export const ButtonCta: React.FC<ButtonCtaProps> = (props: ButtonCtaProps): JSX.Element => {
  const {
    label,
    onClick = () => {
      return;
    },
    type = "button",
    className = "",
    wordingClassname = "",
    btnClassname = "",
    btnHoverClassname = "",
    wordingHoverClassname = "",
    ...rest
  } = props;
  return (
    <>
      <button className={cx(styles.buttonCta, className)} type={type} onClick={onClick} {...rest}>
        <Cta
          label={label}
          wordingClassname={wordingClassname}
          btnClassname={btnClassname}
          btnHoverClassname={btnHoverClassname}
          wordingHoverClassname={wordingHoverClassname}
        />
      </button>
    </>
  );
};
export const LinkCta: React.FC<LinkCtaProps> = (props: LinkCtaProps): JSX.Element => {
  const {
    label,
    onClick = () => {
      return;
    },
    className = "",
    wordingClassname = "",
    btnClassname = "",
    btnHoverClassname = "",
    wordingHoverClassname = "",
    ...rest
  } = props;
  return (
    <>
      <a className={cx(styles.buttonCta, className)} onClick={onClick} {...rest}>
        <Cta
          label={label}
          wordingClassname={wordingClassname}
          btnClassname={btnClassname}
          btnHoverClassname={btnHoverClassname}
          wordingHoverClassname={wordingHoverClassname}
        />
      </a>
    </>
  );
};

export const Cta: React.FC<CtaProps> = (props: CtaProps): JSX.Element => {
  const {
    label,
    onClick = () => {
      return;
    },
    wordingClassname = "",
    btnClassname = "",
    btnHoverClassname = "",
    wordingHoverClassname = "",
  } = props;
  const [isAnimating, setIsAnimating] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);
  const hoverState = React.useRef(isHovering);
  const isMounted = React.useRef(false);
  const tl = {
    show: React.useRef<AnimeTimelineInstance>(),
    hide: React.useRef<AnimeTimelineInstance>(),
  };
  const $initRef = {
    btn: React.useRef(null),
    txt: React.useRef(null),
  };
  const $hoverRef = {
    btn: React.useRef(null),
    txt: React.useRef(null),
  };
  const onMouseEnter = () => {
    setIsHovering(true);
  };
  const onMouseLeave = () => {
    setIsHovering(false);
  };

  const showHoverState = () => {
    tl.show.current = anime.timeline({
      complete: () => {
        setIsAnimating(false);
      },
      duration: 800,
      easing: $cubic1,
    });

    tl.show.current.add({
      translateX: ["0%", "100%"],
      targets: $initRef.btn.current,
    });
    tl.show.current.add(
      {
        translateX: ["0%", "-100%"],
        targets: $initRef.txt.current,
      },
      0,
    );
    tl.show.current.add(
      {
        translateX: ["-100%", "0%"],
        targets: $hoverRef.btn.current,
      },
      0,
    );
    tl.show.current.add(
      {
        translateX: ["100%", "0%"],
        targets: $hoverRef.txt.current,
      },
      0,
    );
  };
  const HideHoverState = () => {
    tl.hide.current = anime.timeline({
      complete: () => {
        setIsAnimating(false);
      },
      duration: 800,
      easing: "easeInOutExpo",
    });

    tl.hide.current.add({
      translateX: ["-102%", "0%"],
      targets: $initRef.btn.current,
    });
    tl.hide.current.add(
      {
        translateX: ["102%", "0%"],
        targets: $initRef.txt.current,
      },
      0,
    );
    tl.hide.current.add(
      {
        translateX: ["0%", "102%"],
        targets: $hoverRef.btn.current,
      },
      0,
    );
    tl.hide.current.add(
      {
        translateX: ["0%", "-102%"],
        targets: $hoverRef.txt.current,
      },
      0,
    );
  };

  const switchState = React.useRef((hovering: boolean) => {
    if (hovering) {
      showHoverState();
    } else {
      HideHoverState();
    }
  });

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      if (tl.hide.current) {
        tl.hide.current.pause();
      }
      if (tl.show.current) {
        tl.show.current.pause();
      }
    };
  }, [tl.hide, tl.show]);

  React.useEffect(() => {
    if (!isMounted.current) {
      return;
    }
    if (!isAnimating && hoverState.current !== isHovering) {
      setIsAnimating(true);
      hoverState.current = isHovering;
      switchState.current(isHovering);
    }
  }, [isHovering, isAnimating, switchState]);
  return (
    <>
      <span className={styles.cta} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <span className={cx(styles.btnState, styles.initialState, btnClassname)} ref={$initRef.btn}>
          <span className={cx(styles.wordingState, wordingClassname)} ref={$initRef.txt}>
            {label}
          </span>
        </span>
        <span className={cx(styles.btnState, styles.hoverState, btnClassname, btnHoverClassname)} ref={$hoverRef.btn}>
          <span className={cx(styles.wordingState, wordingClassname, wordingHoverClassname)} ref={$hoverRef.txt}>
            {label}
          </span>
        </span>
      </span>
    </>
  );
};
