import { t } from "@lingui/macro";
import { GoogleLogin } from "@react-oauth/google";
import cx from "classnames";
import { Field, Form, Formik, FormikValues } from "formik";
import { parse } from "querystring-es3";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";

import { ApiErrorsMessages } from "../../../components/ApiErrorsMessages";
import { Input } from "../../../components/Form/Input";
import { SubmitCta, SubmitCtaState } from "../../../components/Form/SubmitCta";
import { Logo } from "../../../components/Logos";
import { getParams } from "../../../helpers/dom";
import Yup from "../../../helpers/Yup";
import { StateProps } from "../../../interfaces/state";
import { routes } from "../../../routes";
import {
  onGoogleLoginAction,
  onGoogleFailureAction,
  onElinoiLoginAction,
  cleanApiStateAction,
} from "../../../services/api/rest/authentication/actions";

import globalStyles from "../../../styles/global.module.scss";

import formStyles from "../../../styles/shared/form.module.scss";
import loginStyles from "../../../styles/shared/login.module.scss";

export const SignInPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [formState, setFormState] = React.useState<SubmitCtaState>("initial");
  const onGoogleLogin = (response: any) => {
    dispatch(cleanApiStateAction("FAILURE"));
    dispatch(onGoogleLoginAction(response, parse(location.search.substring(1))));
  };
  const onGoogleFailure = () => dispatch(onGoogleFailureAction());
  const paramsObj = getParams(location, ["email", "regToken"]);
  const errors = useSelector((state: StateProps) => state.apiErrors);
  const auth = useSelector((state: StateProps) => state.auth);
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    passwordConfirmation: Yup.string().oneOf([Yup.ref("password"), ""], t`yup.mixed.passwordConfirmation`),
  });

  const onFormSubmit = (values: FormikValues) => {
    dispatch(
      onElinoiLoginAction(values.email, values.password, {
        regToken: paramsObj.regToken || undefined,
      }),
    );
    setFormState("submitting");
  };

  React.useEffect(() => {
    if (formState === "submitting" && errors.length) {
      setFormState("error");
    }
  }, [formState, errors]);

  if (auth.isAuthed) {
    return <Navigate to={routes.app.home} replace />;
  }
  return (
    <>
      <div className={loginStyles.loginWrapper}>
        <div className={loginStyles.loginContainer}>
          <Logo className={loginStyles.logoContainer} />
          <div className={loginStyles.formContainer}>
            {paramsObj.regToken ? (
              <>
                <div className={loginStyles.formTitleContainer}>
                  <h2 className={loginStyles.formTitle}>{t`Auth.SigninPage.title`}</h2>
                </div>
                <div>
                  {errors.length ? (
                    <div key={"login-errors-container"}>
                      <ApiErrorsMessages errors={errors} />
                    </div>
                  ) : null}
                  <div className={loginStyles.rsLoginContainer}>
                    <GoogleLogin onSuccess={onGoogleLogin} onError={onGoogleFailure} useOneTap />
                  </div>
                </div>
                <div className={loginStyles.loginMethodeSeparator}>{t`Auth.MethodeSeparator`}</div>
                <div className="formLoginContainer">
                  <Formik
                    onSubmit={onFormSubmit}
                    initialValues={{
                      email: paramsObj.email || "",
                      password: "",
                    }}
                    validationSchema={validationSchema}
                  >
                    {() => (
                      <Form>
                        <div className={formStyles.formRow}>
                          <Field component={Input} type="email" name="email" label="Votre email" required={true} />
                        </div>
                        <div className={formStyles.formRow}>
                          <Field
                            component={Input}
                            type="password"
                            name="password"
                            label="Créer un mot de passe"
                            required={true}
                          />
                        </div>
                        <div className={formStyles.formRow}>
                          <Field
                            component={Input}
                            type="password"
                            name="passwordConfirmation"
                            label="Confirmer le mot de passe"
                            required={true}
                          />
                        </div>
                        <div className={cx(formStyles.formRow, formStyles.submitButtonContainer)}>
                          <SubmitCta state={formState} />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </>
            ) : (
              <>
                <div className={loginStyles.formTitleContainer}>
                  <h2 className={loginStyles.formTitle}>{t`General.wording.error`}</h2>
                </div>
                <div>
                  <p className={globalStyles.errorText}>{t`Auth.invalid.url`}</p>
                  <p className={globalStyles.errorText}>{t`Auth.SigninPage.invalid.check.email`}</p>
                  <p className={globalStyles.errorText}>{t`General.wording.error.contact.support`}</p>
                </div>
              </>
            )}
          </div>
          <div className={loginStyles.subFormLinkContainer}>
            <Link to={routes.auth.login} className={loginStyles.subFormLink}>
              {t`Auth.subLink.login`}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
