import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Form, Formik, FormikValues } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PostClientRequestOfferChange, PutOfferClientRequest } from "../../../actionTypes/application";
import { EVENT } from "../../../helpers/constant";

import { eventDispatcher } from "../../../helpers/eventDispatcher";
import { GmailMailtoFunc } from "../../../helpers/gmailto";
import Yup from "../../../helpers/Yup";
import { StateProps } from "../../../interfaces/state";
import { CardOfferFragmentProps } from "../../../services/api/graphql/types";
import { onPostClientRequestOfferChange, onPutOfferClientRequest } from "../../../services/api/rest/actions";
import { cleanApiStateAction } from "../../../services/api/rest/authentication/actions";
import globalStyles from "../../../styles/global.module.scss";
import formStyles from "../../../styles/shared/form.module.scss";
import loginStyles from "../../../styles/shared/login.module.scss";
import { ButtonCta, LinkCta } from "../../Cta";
import { SubmitCta, SubmitCtaState } from "../../Form/SubmitCta";
import { Textarea } from "../../Form/Textarea";

import styles from "./index.module.scss";

export interface Props {
  data: DataProps;
}

export type OfferModalFormType = "activate" | "desactivate";
interface DataProps {
  formType?: OfferModalFormType;
  offer?: CardOfferFragmentProps;
}

export const OfferModal: FC<Props> = (props: Props) => {
  const { data = {} } = props;
  const dispatch = useDispatch();
  const formStateTimeoutRef = React.useRef<NodeJS.Timer | null>(null);
  const clientContact = useSelector((state: StateProps) => state.currentUser);
  const apiErrors = useSelector((state: StateProps) => state.apiErrors);
  const apiSuccess = useSelector((state: StateProps) => state.apiSuccess);
  const [formState, setFormState] = useState<SubmitCtaState>("initial");
  const [offer, setOffer] = useState<CardOfferFragmentProps | undefined>(undefined);
  const [formType, setFormType] = useState<OfferModalFormType | undefined>(undefined);
  const validationSchema = Yup.object().shape({
    message: Yup.string().required(),
  });

  const onSubmitSuccess = React.useRef(() => {
    eventDispatcher.trigger(EVENT.RequestFormSubmitted, { offer: data.offer });
    eventDispatcher.trigger(EVENT.ModalClose);
  }).current;

  const onSubmit = (values: FormikValues) => {
    dispatch(cleanApiStateAction("FAILURE", [PostClientRequestOfferChange.FAILURE, PutOfferClientRequest.FAILURE]));
    dispatch(cleanApiStateAction("SUCCESS", [PostClientRequestOfferChange.SUCCESS, PutOfferClientRequest.SUCCESS]));
    dispatch(
      onPostClientRequestOfferChange({
        clientContactIri: clientContact?.["@id"] || "",
        coreUserIri: offer?.owners?.edges?.[0]?.node?.id || "",
        offerIri: offer?.id || "",
        message: values.message,
      }),
    );
    dispatch(
      onPutOfferClientRequest({
        offerIri: offer?.id || "",
        clientRequest: true,
      }),
    );
    setFormState("submitting");
  };

  const onClickCancelBtn = () => {
    eventDispatcher.trigger(EVENT.ModalClose);
  };

  useEffect(() => {
    if (data) {
      setOffer(data.offer);
      setFormType(data.formType !== undefined ? data.formType : "activate");
    }
  }, [data]);

  React.useEffect(() => {
    const errors = apiErrors.filter((e) =>
      [PostClientRequestOfferChange.FAILURE, PutOfferClientRequest.FAILURE].includes(e.type),
    );
    const successPost = apiSuccess.filter((e) => e.type === PostClientRequestOfferChange.SUCCESS);
    const successPut = apiSuccess.filter((e) => e.type === PutOfferClientRequest.SUCCESS);
    if (formState === "submitting" && errors.length) {
      setFormState("error");
    } else if (formState === "submitting" && successPost.length && successPut.length) {
      setFormState("success");
    } else if (formState === "error") {
      formStateTimeoutRef.current = setTimeout(() => {
        setFormState("initial");
      }, 3000);
    } else if (formState === "success") {
      formStateTimeoutRef.current = setTimeout(() => {
        onSubmitSuccess();
      }, 2000);
    }

    return () => {
      if (formStateTimeoutRef.current) {
        clearTimeout(formStateTimeoutRef.current);
      }
    };
  }, [formState, apiErrors, apiSuccess, onSubmitSuccess]);

  if (!!!offer) {
    return (
      <div className={styles.modal}>
        <div className={loginStyles.formContainer}>
          <div className={loginStyles.formTitleContainer}>
            <h2 className={loginStyles.formTitle}>{t`General.wording.error`}</h2>
          </div>
          <p className={globalStyles.errorText}>{t`OfferModal.error.noOffer`}</p>
          <div className={styles.cancelBtnContainer}>
            <LinkCta
              label={t`General.wording.customer.support`}
              href={GmailMailtoFunc({
                to: process.env.REACT_APP_EMAIL_SAV_TECH || "",
              })}
              target="_blank"
              rel="external nofollow noopener noreferrer"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.modal}>
      <div className={loginStyles.formContainer}>
        <div className={loginStyles.formTitleContainer}>
          <h2 className={loginStyles.formTitle}>{offer.title}</h2>
          <h2 className={loginStyles.formTitle}>
            {formType === "activate" ? t`OfferModal.activationForm.title` : t`OfferModal.desactivationForm.title`}
          </h2>
        </div>
        <Formik onSubmit={onSubmit} initialValues={{ message: "" }} validationSchema={validationSchema}>
          {() => (
            <Form>
              <div className={formStyles.formRow}>
                <Field
                  component={Textarea}
                  label={
                    formType === "activate"
                      ? t`OfferModal.activationForm.textarea.label`
                      : t`OfferModal.desactivationForm.textarea.label`
                  }
                  required={true}
                  name="message"
                />
              </div>
              <div className={cx(styles.buttonsContainer, formStyles.formRow)}>
                <div>
                  <ButtonCta
                    className={styles.cancelBtnCta}
                    label={t`General.wording.cancel`}
                    onClick={onClickCancelBtn}
                  />
                </div>
                <div>
                  <SubmitCta state={formState} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
