import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import {
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  CreateApplication,
  EditApplicationClientStatus,
  EditOffer,
  PostApplicationMessage,
  PostClientRequestOfferChange,
  PutApplicationMessage,
  PutOfferClientRequest,
} from "../../../actionTypes/application";

import { ApiAuthenticationRefresh } from "../../../actionTypes/authentication";
import { PostTalentCardClientEvent } from "../../../actionTypes/talentCard";
import { routes } from "../../../routes";
import * as apiClient from "./api";
import { logoutAction, refreshAction } from "./authentication/actions";
import { cookies, REFRESH_TOKEN_KEY, removeTokens } from "./authentication/helpers";
import { getRouterState } from "./authentication/saga";
import { apiFailure, getFailType, getSuccessType } from "./helpers";

const ignoreActionTypes = [ApiAuthenticationRefresh.REQUEST];

type ResponseTypeProps = Generator<CallEffect<AxiosResponse> | PutEffect<AnyAction>, void, unknown>;

export function monitorableAction(action: AnyAction): boolean {
  return action.type.includes("REQUEST") && ignoreActionTypes.every((fragment) => !action.type.includes(fragment));
}

export function* monitor(monitoredAction: AnyAction): any {
  const { fail } = yield race({
    fail: take(getFailType(monitoredAction)),
    success: take(getSuccessType(monitoredAction)),
  });
  if (fail && fail.payload) {
    switch (fail.payload.status) {
      case 401:
        yield put(refreshAction(cookies.get(REFRESH_TOKEN_KEY) || ""));

        const { success } = yield race({
          fail: take(ApiAuthenticationRefresh.FAILURE),
          success: take(ApiAuthenticationRefresh.SUCCESS),
        });

        if (success) {
          yield put(monitoredAction);
        } else {
          const routerState: any = yield select(getRouterState);
          if (Object.values(routes.app).includes(routerState.location.pathname)) {
            yield put(logoutAction());
            removeTokens();
          }
        }
        break;
      case 403:
        const routerState: any = yield select(getRouterState);
        if (Object.values(routes.app).includes(routerState.location.pathname)) {
          yield put(logoutAction());
          removeTokens();
        }
        break;
      default:
        break;
    }
  }
}

export function* editApplicationClientStatus(action: AnyAction): ResponseTypeProps {
  try {
    yield call(apiClient.editApplicationClientStatus, {
      applicationIri: action.applicationIri,
      clientStatus: action.clientStatus,
    });
    yield put({
      type: getSuccessType(action),
    });
  } catch (e: any) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* postClientRequestOfferChange(action: AnyAction): ResponseTypeProps {
  try {
    yield call(apiClient.postClientRequestOfferChange, {
      message: action.message,
      clientContact: action.clientContactIri,
      coreUser: action.coreUserIri,
      offer: action.offerIri,
    });
    yield put({
      type: getSuccessType(action),
    });
  } catch (e: any) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* putOfferClientRequest(action: AnyAction): ResponseTypeProps {
  try {
    yield call(apiClient.putOfferClientRequest, {
      offerIri: action.offerIri,
      clientRequest: action.clientRequest,
    });
    yield put({
      type: getSuccessType(action),
    });
  } catch (e: any) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* postTalentCardClientEvent(action: AnyAction): ResponseTypeProps {
  try {
    yield call(apiClient.postTalentCardClientEvent, {
      talentCardIri: action.talentCardIri,
      clientContactIri: action.clientContactIri,
    });
    yield put({
      type: getSuccessType(action),
    });
  } catch (e: any) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* postApplicationMessage(action: AnyAction): ResponseTypeProps {
  try {
    yield call(apiClient.postApplicationMessage, {
      message: action.message,
      clientContact: action.clientContactIri,
      application: action.applicationIri,
      clientEnabled: action.clientEnabled,
    });
    yield put({
      type: getSuccessType(action),
    });
  } catch (e: any) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* putApplicationMessage(action: AnyAction): ResponseTypeProps {
  try {
    for (const mess of action.messages) {
      yield call(apiClient.putApplicationMessage, {
        applicationMessageIri: mess.id,
        clientRead: [...mess.clientRead, action.clientContact],
      });
    }
    yield put({
      type: getSuccessType(action),
    });
  } catch (e: any) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* postApplication(action: AnyAction): ResponseTypeProps {
  try {
    yield call(apiClient.postApplication, {
      offer: action.offerIri,
      talent: action.talentIri,
      status: action.status,
      clientStatus: action.clientStatus,
      clientContact: action.clientIri,
    });
    yield put({
      type: getSuccessType(action),
    });
  } catch (e: any) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* editOffer(action: AnyAction): any {
  try {
    yield call(apiClient.editOffer, { offer: action.offerId, discardedTalents: action.discardedTalents });
    yield put({
      type: getSuccessType(action),
    });
  } catch (e: any) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export default function* apiSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(monitorableAction, monitor);
  yield takeLatest(EditApplicationClientStatus.REQUEST, editApplicationClientStatus);
  yield takeLatest(CreateApplication.REQUEST, postApplication);
  yield takeLatest(EditOffer.REQUEST, editOffer);
  yield takeLatest(PostClientRequestOfferChange.REQUEST, postClientRequestOfferChange);
  yield takeLatest(PutOfferClientRequest.REQUEST, putOfferClientRequest);
  yield takeLatest(PostTalentCardClientEvent.REQUEST, postTalentCardClientEvent);
  yield takeLatest(PostApplicationMessage.REQUEST, postApplicationMessage);
  yield takeLatest(PutApplicationMessage.REQUEST, putApplicationMessage);
}
