import { t } from "@lingui/macro";
import {
  APPLICATION_STATUS,
  APPLICATION_STATUS_MARKETPLACE,
  APPLICATION_STATUS_PRE_SEND,
} from "../../../helpers/constant";
import { CardApplicationFragmentProps } from "../../../services/api/graphql/types";

export interface ColumnDndProps {
  id: string;
  title: string;
  applicationClientStatus: string;
  applications: {
    [key: string]: CardApplicationFragmentProps;
  };
  applicationsIds: string[];
  isDragDisabled?: boolean;
}
export interface SetupDndDataProps {
  columns: {
    [key: string]: ColumnDndProps;
  };
  columnOrder: string[];
}

export const COL_TITLE = {
  [APPLICATION_STATUS_PRE_SEND]: t`OfferPage.column.preSend`,
  [APPLICATION_STATUS_MARKETPLACE]: t`OfferPage.column.marketplace`,
  [APPLICATION_STATUS.NEW]: t`OfferPage.column.new`,
  [APPLICATION_STATUS.CONTACTED]: t`OfferPage.column.contacted`,
  [APPLICATION_STATUS.INTERVIEWS]: t`OfferPage.column.interviews`,
  [APPLICATION_STATUS.HIRED]: t`OfferPage.column.hired`,
  [APPLICATION_STATUS.NEED_TO_REFUSE]: t`OfferPage.column.need_to_refuse`,
  [APPLICATION_STATUS.REFUSED]: t`OfferPage.column.refused`,
};

export const COL_PRE_SEND = "col-pre-send";
export const COL_MARKETPLACE = "col-marletplace";
export const COL_NEW = "col-new-talents";
export const COL_CONTACTED = "col-contacted-talents";
export const COL_INTERVIEWS = "col-interview-talents";
export const COL_HIRED = "col-hired-talents";
export const COL_NEED_TO_REFUSE = "col-need-to-refuse-talents";
export const COL_REFUSED = "col-refused-talents";

export const setupPreSendColumn: ColumnDndProps = {
  applicationsIds: [],
  applications: {},
  id: COL_MARKETPLACE,
  title: COL_TITLE[APPLICATION_STATUS_MARKETPLACE],
  applicationClientStatus: APPLICATION_STATUS_MARKETPLACE,
};

export const setupDndData: SetupDndDataProps = {
  columns: {
    [COL_NEW]: {
      applicationsIds: [],
      applications: {},
      id: COL_NEW,
      title: COL_TITLE[APPLICATION_STATUS.NEW],
      applicationClientStatus: APPLICATION_STATUS.NEW,
    },
    [COL_CONTACTED]: {
      applicationsIds: [],
      applications: {},
      id: COL_CONTACTED,
      title: COL_TITLE[APPLICATION_STATUS.CONTACTED],
      applicationClientStatus: APPLICATION_STATUS.CONTACTED,
    },
    [COL_INTERVIEWS]: {
      applicationsIds: [],
      applications: {},
      id: COL_INTERVIEWS,
      title: COL_TITLE[APPLICATION_STATUS.INTERVIEWS],
      applicationClientStatus: APPLICATION_STATUS.INTERVIEWS,
    },
    [COL_HIRED]: {
      applicationsIds: [],
      applications: {},
      id: COL_HIRED,
      title: COL_TITLE[APPLICATION_STATUS.HIRED],
      applicationClientStatus: APPLICATION_STATUS.HIRED,
    },
    [COL_NEED_TO_REFUSE]: {
      applicationsIds: [],
      applications: {},
      id: COL_NEED_TO_REFUSE,
      title: COL_TITLE[APPLICATION_STATUS.NEED_TO_REFUSE],
      applicationClientStatus: APPLICATION_STATUS.NEED_TO_REFUSE,
    },
    [COL_REFUSED]: {
      applicationsIds: [],
      applications: {},
      id: COL_REFUSED,
      title: COL_TITLE[APPLICATION_STATUS.REFUSED],
      applicationClientStatus: APPLICATION_STATUS.REFUSED,
    },
  },
  columnOrder: [COL_NEW, COL_CONTACTED, COL_INTERVIEWS, COL_HIRED, COL_NEED_TO_REFUSE, COL_REFUSED],
};
