import { gql, useLazyQuery } from "@apollo/client";
import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { CardApplication, CardApplicationFragment } from "../../../components/CardApplication";
import { GraphqlError } from "../../../components/GraphqlError";
import { Loader } from "../../../components/Loader";
import { MarkdownRenderer } from "../../../components/MarkdownRenderer";
import { APPLICATION_STATUS } from "../../../helpers/constant";
import { StateProps } from "../../../interfaces/state";
import {
  GetApplications,
  GetApplicationsVariables,
  CardApplicationFragmentProps,
} from "../../../services/api/graphql/types";

import global from "../../../styles/global.module.scss";
import styles from "./index.module.scss";

export const TALENTS_PAGE_QUERY = gql`
  query GetApplications($userId: ID!) {
    clientContact(id: $userId) {
      id
      contact {
        company {
          offers {
            edges {
              node {
                applications(clientStatus_list: ["${APPLICATION_STATUS.NEW}", "${APPLICATION_STATUS.INTERVIEWS}", "${APPLICATION_STATUS.CONTACTED}", "${APPLICATION_STATUS.NEED_TO_REFUSE}", "${APPLICATION_STATUS.REFUSED}"]) {
                  edges {
                    node {
                      ...CardApplicationFragmentProps
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CardApplicationFragment}
`;

export const TalentsPage: React.FC = () => {
  const [searchText, setSearchText] = React.useState<string | null>(null);
  const [allApplications, setAllApplications] = React.useState<CardApplicationFragmentProps[]>([]);
  const [applications, setApplications] = React.useState<CardApplicationFragmentProps[]>([]);
  const currentUser = useSelector((state: StateProps) => state.currentUser);
  const [loadApplications, { called, data, error, loading }] = useLazyQuery<GetApplications, GetApplicationsVariables>(
    TALENTS_PAGE_QUERY,
  );

  React.useEffect(() => {
    if (!!currentUser && !called) {
      loadApplications({
        variables: {
          userId: currentUser["@id"],
        },
      });
    }
  }, [currentUser, called, loadApplications]);

  React.useEffect(() => {
    if (!!data) {
      const arr: CardApplicationFragmentProps[] = [];
      const offers = data.clientContact?.contact?.company?.offers?.edges || [];
      offers.forEach((v) => {
        const appsEdges = v?.node?.applications?.edges || [];
        appsEdges.forEach((ap) => {
          if (ap && ap.node && Object.values(APPLICATION_STATUS).includes(ap.node.clientStatus || "")) {
            arr.push(ap.node);
          }
        });
      });
      setAllApplications(arr);
    }
  }, [data, searchText]);

  React.useEffect(() => {
    const filterApps: CardApplicationFragmentProps[] = [];

    allApplications.forEach((ap) => {
      const { firstname, lastname } = ap.talent;
      const searchFields = `${firstname} ${lastname}`
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const cleanSearchText = !!searchText
        ? searchText
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        : null;

      if (!!!cleanSearchText) {
        filterApps.push(ap);
      } else if (searchFields.includes(cleanSearchText)) {
        filterApps.push(ap);
      }
    });

    setApplications(filterApps);
  }, [searchText, allApplications]);

  const renderApplications = () => {
    const nodes: React.ReactNode[] = [];
    if (applications.length) {
      applications.map((ap) => {
        return nodes.push(
          <li key={ap.id}>
            <div className={styles.cardContainer}>
              <CardApplication application={ap} />
            </div>
          </li>,
        );
      });
    }

    return nodes;
  };

  const onChangeSearchInput = (v: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTxt = v.target.value || null;
    setSearchText(newSearchTxt);
  };

  if (error) {
    return (
      <>
        <div className={global.pageWrapper}>
          <GraphqlError error={error} />
        </div>
      </>
    );
  }

  if (loading || !called) {
    return (
      <>
        <div className={cx(global.pageWrapper, global.loaderPageWrapper)}>
          <Loader />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={global.pageWrapper}>
        <div className={styles.searchContainer}>
          <h3>{t`TalentsPage.search.title`}</h3>
          <input type="text" onChange={onChangeSearchInput} />
        </div>
        <ul className={styles.cardsList}>
          <li className={styles.banner}>
            <div className={cx(styles.cardBannerContainer, loading || !called ? styles.loading : null)}>
              <div className={styles.cardBanner}>
                <div className={styles.cardBannerContent}>
                  <div className={styles.cardBannerHeader}>
                    <span>{t`TalentsPage.sponsorCard.title`}</span>
                  </div>
                  <MarkdownRenderer className={styles.cardBannerInfo} content={t`TalentsPage.sponsorCard.content`} />
                  <div className={styles.cardBannerFooter}>
                    <span className={styles.newBadge}>{t`TalentsPage.sponsorCard.banner`}</span>
                  </div>
                </div>
              </div>
            </div>
          </li>
          {renderApplications()}
        </ul>
      </div>
    </>
  );
};
