import { ApolloError } from "@apollo/client";
import * as React from "react";

import styles from "./index.module.scss";

interface Props {
  error: ApolloError;
}

export const GraphqlError: React.FC<Props> = (props: Props) => {
  const { error } = props;
  return <div className={styles.errorContainer}> {error.message}</div>;
};
