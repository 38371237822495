import Cookies from "universal-cookie";

export const JWT_TOKEN_KEY = "cjwt";
export const JWT_TOKEN_KEY_TEMP = "cjwt_temp";
export const REFRESH_TOKEN_KEY = "crefresh";

export const cookies = new Cookies();

export const setTokens = (tokens: { refresh_token: string; token: string }, type = "login"): void => {
  if (type === "login") {
    cookies.set(JWT_TOKEN_KEY, tokens.token);
    cookies.set(REFRESH_TOKEN_KEY, tokens.refresh_token);
  } else {
    cookies.set(JWT_TOKEN_KEY_TEMP, cookies.get(JWT_TOKEN_KEY) || "");
    cookies.set(JWT_TOKEN_KEY, tokens.token);
  }
};

export const removeTokens = (type = "login"): void => {
  if (type === "login") {
    cookies.remove(JWT_TOKEN_KEY, {
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    cookies.remove(REFRESH_TOKEN_KEY, {
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  } else {
    cookies.set(JWT_TOKEN_KEY, cookies.get(JWT_TOKEN_KEY_TEMP) || "");
    cookies.remove(JWT_TOKEN_KEY_TEMP, {
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  }
};

export const getAuthorizationHeader = (): null | { Authorization: string } => {
  const token = cookies.get(JWT_TOKEN_KEY);
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return null;
};
